import { enTranslator as intl } from 'intl.js'

import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import OpenQuestion from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/OpenQuestion'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { GET_QUESTION_MESSAGE } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/MESSAGE'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'

export default {
	type: VISUAL_FLOW_MODULE_TYPES.A_OEQ,
	style: 'open-question',
	title: () => 'oeq.title',
	description: () => 'oeq.description',
	component: OpenQuestion,
	generator: (modules, additionalProperties) => ({
		messages: [
			GET_QUESTION_MESSAGE({ ...additionalProperties, idTextTranslation: 'question_default' }),
		],
		idRelatedMessage: null,
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'oeq.default_dataset_name' }),
			VISUAL_FLOW_MODULE_TYPES.A_OEQ,
		),
		enableGibberishFilter: true,
		enableProfanityFilter: true,
		enableAutoTranslate: false,
		preseededStatements: [],
		ideate: {
			show: true,
			minIdeateUpperLimit: null,
			minIdeateLowerLimit: null,
			ideaPoolLimitShare: null,
			ideaPoolLimitNumber: null,
			idPriorMessage: null,
			disableSentenceSplitter: false,
		},
		eliminate: {
			show: true,
			idPriorMessage: null,
		},
		elaborate: {
			show: true,
			idPriorMessage: null,
			minEvaluations: 8,
			maxEvaluations: 12,
		},
		translations: {
			ideate_placeholder: getLanguagesLabelsObject(additionalProperties.languages, ''),
			elaborate_intro: getLanguagesLabelsObject(additionalProperties.languages, ''),
			elaborate_agree_label: getLanguagesLabelsObject(additionalProperties.languages, ''),
			elaborate_disagree_label: getLanguagesLabelsObject(additionalProperties.languages, ''),
			elaborate_indifferent_label: getLanguagesLabelsObject(additionalProperties.languages, ''),
			elaborate_unclear_statement_label: getLanguagesLabelsObject(
				additionalProperties.languages,
				'',
			),
		},
	}),
}
