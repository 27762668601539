import _ from 'lodash'

import { filterData, sortData, filterStringColumn } from 'helpers/sortAndFilter'
import { getAttributeLabelKey } from 'helpers/reports/getAttributeLabelKey'
import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getDatasetRows } from 'helpers/reportBuilder/getDatasetRows'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { getOptionLabel } from 'helpers/reports/getOptionLabel'
import { enTranslator as intl } from 'intl.js'

import { tools } from 'constants/tools'

import {
	REPORT_TYPES,
	GROUP_BY_OPTIONS,
	LEGEND_COLUMN_TYPES,
	REPORT_BLOB_TYPES,
	MATRIX_ATTRIBUTE_LABEL_KEY,
} from 'constants/reports'

import {
	getSegmentTotalSupportPercentageKey,
	getSegmentTotalSupportCompletesKey,
	getMatrixLegendColumns,
	getMatrixLegendSubColumnAccessor,
	GROUP_LEGEND_BY_OPTIONS,
	getSegmentWeightedAverageKey,
} from 'store/reportBuilder'

const getOptionTotalKey = idSegment => `${idSegment}_optionTotal`

const getQuestionTotalKey = idSegment => `${idSegment}-respondentsCount`

export const calculateVisibleRows = (
	reportType,
	idStudyObject,
	datasets,
	settings,
	legendState,
) => {
	if (reportType !== REPORT_TYPES.MATRIX) {
		return []
	}

	const { groupBy, hiddenRows } = settings

	if (groupBy === GROUP_BY_OPTIONS.COLUMNS) {
		return getDatasetRows(idStudyObject, datasets).filter(
			row => hiddenRows.includes(row.id) === false,
		)
	}

	const rowsFilter = legendState.filtered.find(
		filterCriterium => filterCriterium.id === LEGEND_COLUMN_TYPES.MATRIX_QUESTION,
	)
	const filteredRows =
		rowsFilter === undefined
			? getDatasetRows(idStudyObject, datasets)
			: getDatasetRows(idStudyObject, datasets).filter(row =>
					filterStringColumn(rowsFilter, { [LEGEND_COLUMN_TYPES.MATRIX_QUESTION]: row.label }),
			  )

	return filteredRows.filter(row => hiddenRows.includes(row.id) === false)
}

export const getDatasetOptions = (idDataset, datasets) =>
	_.get(
		datasets.find(dataset => dataset.value === idDataset),
		'module.options',
		[],
	)

const getSegmentedQuestion = (idQuestion, segmentData) => segmentData.questions[idQuestion]

const getSegmentOption = (idOption, idQuestion, totalSegmentData) =>
	_.get(totalSegmentData, `questions[${idQuestion}].options`, []).find(
		option => option.idOption === idOption,
	)

const getAnswerPercentage = (fraction, whole) => {
	const divisionResult = whole > 0 ? fraction / whole : 0
	const percentage = divisionResult * 100

	return _.round(percentage, 2)
}

const getTotalRespondentsAnsweredQuestion = (options, question, segmentData) =>
	options.reduce(
		(previousValue, option) =>
			previousValue + getSegmentOption(option.id, question.id, segmentData).respondentsCount,
		0,
	)

const getWeightedAverage = (options, question, segmentData) => {
	const totalRespondentsAnsweredQuestion = getTotalRespondentsAnsweredQuestion(
		options,
		question,
		segmentData,
	)

	if (totalRespondentsAnsweredQuestion === 0) {
		return 0
	}

	return options.reduce(
		(previousValue, option) =>
			previousValue +
			(option.code * getSegmentOption(option.id, question.id, segmentData).respondentsCount) /
				totalRespondentsAnsweredQuestion,
		0,
	)
}

const mungeIdeas = (data, idsSegments, legendState, isGroupedByRows) => {
	let groupedData = data

	if (isGroupedByRows === false) {
		const segmentValuesKeys = idsSegments
			.map(idSegment => [
				getSegmentTotalSupportPercentageKey(idSegment),
				getSegmentTotalSupportCompletesKey(idSegment),
			])
			.flat()

		const allOptions = _.uniqBy(
			data
				.map(question => question.options)
				.flat()
				.map(option => _.omit(option, segmentValuesKeys)),
			option => option.id,
		)

		groupedData = allOptions.map(option => {
			const newOptionObj = {
				...option,
				text: option.label,
			}

			idsSegments.forEach(idSegment => {
				const optionTotal = data.reduce(
					(total, question) =>
						total +
						question.options.find(opt => opt.id === option.id)[
							getSegmentTotalSupportCompletesKey(idSegment)
						],
					0,
				)
				newOptionObj[getOptionTotalKey(idSegment)] = optionTotal
			})

			newOptionObj.options = data.map(question => {
				const newQuestion = {
					..._.omit(question, ['options']),
				}

				const oldOption = question.options.find(opt => opt.id === option.id)

				idsSegments.forEach(idSegment => {
					const completes = Number(oldOption[getSegmentTotalSupportCompletesKey(idSegment)])
					const percentage = Number(
						getAnswerPercentage(completes, newOptionObj[getOptionTotalKey(idSegment)]),
					)

					newQuestion[getSegmentTotalSupportPercentageKey(idSegment)] = percentage
					newQuestion[getSegmentTotalSupportCompletesKey(idSegment)] = completes
				})

				return newQuestion
			})

			return newOptionObj
		})
	}

	// Prepare data for Sort and Filter
	groupedData = groupedData.map(question => {
		const newQuestion = {
			...question,
			matrixQuestion: question.text,
		}

		const options = question.options.map(option => {
			const optionValues = []

			const answerLabel = isGroupedByRows === true ? question.text : option.text
			const code = isGroupedByRows === true ? option.code : question.code
			const letter = isGroupedByRows === true ? option.letter : question.letter
			const rowLabel = isGroupedByRows === true ? option.text : question.text

			const idAiDescriptionTranslation =
				isGroupedByRows === true
					? 'report.ai_description.matrix.group_by_row'
					: 'report.ai_description.matrix.group_by_column'

			const aiDescription = {
				label: intl.formatMessage(
					{ id: idAiDescriptionTranslation },
					{ optionLabel: rowLabel, questionLabel: answerLabel },
				),
			}

			idsSegments.forEach(idSegment => {
				const optionPercent = option[getSegmentTotalSupportPercentageKey(idSegment)]
				newQuestion[getMatrixLegendSubColumnAccessor(option.id, idSegment, true)] = optionPercent

				const optionCompletes = option[getSegmentTotalSupportCompletesKey(idSegment)]
				newQuestion[getMatrixLegendSubColumnAccessor(option.id, idSegment, false)] = optionCompletes

				const questionCompletes =
					isGroupedByRows === true
						? question[getQuestionTotalKey(idSegment)]
						: option[getQuestionTotalKey(idSegment)]

				optionValues.push(questionCompletes, optionPercent, optionCompletes)

				aiDescription[idSegment] = optionPercent
			})

			return {
				...option,
				aiDescription,
				toExport: () => [answerLabel, letter, rowLabel, ...optionValues, code],
			}
		})

		return { ...newQuestion, options, toExport: () => options.map(option => option.toExport()) }
	})

	return sortData(filterData(groupedData, legendState.filtered), legendState.sorted)
}

export const calculateLegendIdeas = (
	idStudy,
	reportType,
	datasetOptions,
	slideSettings,
	legendState,
	blobData,
	chartColorSettings,
	language,
) => {
	if (reportType !== REPORT_TYPES.MATRIX || slideSettings === undefined) {
		return []
	}

	const { idStudyObject, idsSegments, hiddenRows, groupBy, customColors } = slideSettings

	const dataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.MATRIX_STATISTICS, idSegment)
			],
	)
	const totalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.MATRIX_STATISTICS,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	if (hasBlobData([...dataArray, totalSegmentData], 'questions') === false) {
		return []
	}

	const defaultColors = chartColorSettings.closeEnded.colors

	const dataset = datasetOptions.find(selector => selector.value === idStudyObject)
	const questions = _.get(dataset, 'module.questions', [])
	const options = _.get(dataset, 'module.options', [])

	const attributeLabelKey = getAttributeLabelKey(slideSettings.attributeLabel)

	const newQuestions = questions
		.map((question, questionIndex) => {
			const customQuestionFill = _.get(customColors, question.id, null)

			const attributeLabel =
				attributeLabelKey === MATRIX_ATTRIBUTE_LABEL_KEY.TEXT
					? question[attributeLabelKey][language]
					: question[attributeLabelKey]

			const newQuestionObj = {
				// TODO: Not sure why we need both text and name here and I'm too lazy to investigate now.
				text: attributeLabel,
				name: attributeLabel,
				id: question.id,
				fill: customQuestionFill ?? defaultColors[questionIndex % defaultColors.length],
				hasCustomColor: customQuestionFill !== null,
			}

			idsSegments.forEach(idSegment => {
				const segmentData = dataArray.find(data => data.idSegment === idSegment)
				const segmentQuestionData = getSegmentedQuestion(question.id, segmentData)

				newQuestionObj[getQuestionTotalKey(idSegment)] = segmentQuestionData.respondentsCount
			})

			const questionOptions = options.map((option, optionIndex) => {
				const totalSegmentOption = getSegmentOption(option.id, question.id, totalSegmentData)

				const defaultOptionFill = defaultColors[optionIndex % defaultColors.length]
				const customOptionFill = _.get(customColors, option.id, null)

				const newOptionObj = {
					id: option.id,
					color: defaultOptionFill,
					letter: totalSegmentOption.letter,
					label: getOptionLabel(option, slideSettings, language),
					text: option.label[language],
					code: option.code,
					name: option.label[language],
					themeName: null,
					fill: customOptionFill ?? defaultOptionFill,
					hasCustomColor: customOptionFill !== null,
					shown: true,
					unit: '%',
				}

				idsSegments.forEach(idSegment => {
					const segmentData = dataArray.find(data => data.idSegment === idSegment)
					const segmentOption = getSegmentOption(option.id, question.id, segmentData)

					const percentage = Number(
						getAnswerPercentage(
							segmentOption.respondentsCount,
							segmentData.questions[question.id].respondentsCount,
						),
					)
					const completes = Number(segmentOption.respondentsCount)
					const weightedAverage = getWeightedAverage(options, question, segmentData)

					newOptionObj[getSegmentTotalSupportPercentageKey(idSegment)] = percentage
					newOptionObj[getSegmentTotalSupportCompletesKey(idSegment)] = completes
					newOptionObj[getSegmentWeightedAverageKey(idSegment)] =
						weightedAverage > 0 ? _.round(weightedAverage, 2) : 0
				})

				return newOptionObj
			})

			return { ...newQuestionObj, options: questionOptions }
		})
		.filter(question => hiddenRows.includes(question.id) === false)

	return mungeIdeas(newQuestions, idsSegments, legendState, groupBy === GROUP_BY_OPTIONS.ROWS)
}

export const calculateSlideLegendColumns = (
	legendState,
	slideSegments,
	idsSegments,
	questions,
	options,
	transform,
	groupBy,
	groupLegendBy,
	language,
) => {
	if (_.isFunction(transform) === false) {
		throw new Error('calculateSlideLegendColumns: `transform` argument is not a function!')
	}

	const allColumns = getMatrixLegendColumns(
		slideSegments,
		idsSegments,
		questions,
		options,
		groupBy === GROUP_BY_OPTIONS.ROWS,
		groupLegendBy === GROUP_LEGEND_BY_OPTIONS.SEGMENT,
		language,
	)

	return transform(allColumns, legendState)
}
