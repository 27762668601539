import ACTION_BUTTON from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/ACTION_BUTTON'
import Checkpoint from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/If'
import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { PREPARATION_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/PREPARATION_TYPES'

import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { getEmptyFlowCondition } from 'helpers/conditionBuilder/getEmptyFlowCondition'

export const CHECKPOINT_HISTORY_OPTIONS = {
	KEEP: null,
	UNBLUR: 'UNBLUR',
	BLUR: 'BLUR',
	CLEAR: 'CLEAR',
}

export const getDescription = definition => {
	if (definition.isTrack) {
		return 'track.description'
	} else if (definition.isBlock) {
		return 'block.description'
	} else if (definition.isTrackRandomizer) {
		return 'track_randomizer.description'
	} else if (definition.isRandomizer) {
		return 'randomizer.description'
	} else if (definition.enableQuota) {
		return 'quota.description'
	} else if (definition.loopSettings?.isActive) {
		return 'loop.description'
	} else if (definition.enableConditions) {
		return 'condition.description'
	} else if (definition.actionButton.enabled) {
		return 'actionButton.description'
	} else {
		return 'historyFilter.description'
	}
}

export const getTitle = definition => {
	if (!definition) {
		return 'checkpoint'
	} else if (definition.isTrack) {
		return 'track.title'
	} else if (definition.isBlock) {
		return 'block.title'
	} else if (definition.isTrackRandomizer) {
		return 'track_randomizer.title'
	} else if (definition.isRandomizer) {
		return 'randomizer.title'
	} else if (definition.enableQuota) {
		return 'quota.title'
	} else if (definition.loopSettings?.isActive) {
		return 'loop.title'
	} else if (definition.enableConditions) {
		return 'condition.title'
	} else if (definition.actionButton.enabled) {
		return 'actionButton.title'
	} else {
		return 'historyFilter.title'
	}
}

export const getHeaderTitle = definition => {
	const title = getTitle(definition)
	return title === 'History filter' ? title : title + ' checkpoint'
}

const getEnableConditions = additionalProperties => {
	const { preparationType } = additionalProperties
	return (
		preparationType === PREPARATION_TYPES.CONDITION ||
		preparationType === PREPARATION_TYPES.QUOTA ||
		preparationType === PREPARATION_TYPES.BLOCK ||
		preparationType === PREPARATION_TYPES.RANDOMIZER ||
		preparationType === PREPARATION_TYPES.TRACK_RANDOMIZER ||
		preparationType === PREPARATION_TYPES.LOOP
	)
}

export const getConditions = (modules, additionalProperties, upperChoiceModules) => {
	if (getEnableConditions(additionalProperties) === false) {
		return []
	}

	if (
		additionalProperties.preparationType === PREPARATION_TYPES.BLOCK ||
		additionalProperties.preparationType === PREPARATION_TYPES.RANDOMIZER ||
		additionalProperties.preparationType === PREPARATION_TYPES.TRACK_RANDOMIZER ||
		additionalProperties.preparationType === PREPARATION_TYPES.LOOP
	) {
		return [getEmptyFlowCondition(false)]
	}

	const choiceModule = upperChoiceModules[upperChoiceModules.length - 1]

	return [
		{
			...getEmptyFlowCondition(additionalProperties.preparationType === PREPARATION_TYPES.QUOTA),
			studyObject: {
				id: choiceModule !== undefined ? choiceModule.definition.id : null,
			},
			left:
				choiceModule !== undefined
					? [choiceModule.definition.options.find(o => o.isHidden !== true).id]
					: [],
		},
	]
}

const getIsBlock = additionalProperties => {
	return additionalProperties.preparationType === PREPARATION_TYPES.BLOCK
}

const getEnableQuota = additionalProperties => {
	return additionalProperties.preparationType === PREPARATION_TYPES.QUOTA
}

const getHistory = additionalProperties => {
	return additionalProperties.preparationType === PREPARATION_TYPES.HISTORY
		? CHECKPOINT_HISTORY_OPTIONS.BLUR
		: CHECKPOINT_HISTORY_OPTIONS.KEEP
}

const getActionButtonEnabled = additionalProperties => {
	return additionalProperties.preparationType === PREPARATION_TYPES.PROCEED
}

const getActionButtonLabel = additionalProperties => {
	return additionalProperties.actionButtonLabel
}

const getShortname = (modules, additionalProperties) => {
	const { shortName } = additionalProperties

	if (additionalProperties.preparationType === PREPARATION_TYPES.BLOCK) {
		return getDatasetShortName(
			modules,
			shortName || 'Block',
			VISUAL_FLOW_MODULE_TYPES.UI_COMMAND,
			PREPARATION_TYPES.BLOCK,
		)
	}

	if (additionalProperties.preparationType === PREPARATION_TYPES.RANDOMIZER) {
		return getDatasetShortName(
			modules,
			shortName || 'Module randomizer',
			VISUAL_FLOW_MODULE_TYPES.UI_COMMAND,
			PREPARATION_TYPES.RANDOMIZER,
		)
	}

	if (additionalProperties.preparationType === PREPARATION_TYPES.TRACK_RANDOMIZER) {
		return getDatasetShortName(
			modules,
			shortName || 'Track randomizer',
			VISUAL_FLOW_MODULE_TYPES.UI_COMMAND,
			PREPARATION_TYPES.TRACK_RANDOMIZER,
		)
	}

	if (additionalProperties.preparationType === PREPARATION_TYPES.QUOTA) {
		return getDatasetShortName(
			modules,
			shortName || 'Quota',
			VISUAL_FLOW_MODULE_TYPES.UI_COMMAND,
			PREPARATION_TYPES.QUOTA,
		)
	}

	if (additionalProperties.preparationType === PREPARATION_TYPES.CONDITION) {
		return getDatasetShortName(
			modules,
			shortName || 'Condition',
			VISUAL_FLOW_MODULE_TYPES.UI_COMMAND,
			PREPARATION_TYPES.CONDITION,
		)
	}

	if (additionalProperties.preparationType === PREPARATION_TYPES.LOOP) {
		return getDatasetShortName(
			modules,
			shortName || 'Loop',
			VISUAL_FLOW_MODULE_TYPES.UI_COMMAND,
			PREPARATION_TYPES.LOOP,
		)
	}

	return ''
}

const getIsRandomizer = additionalProperties =>
	additionalProperties.preparationType === PREPARATION_TYPES.RANDOMIZER ||
	additionalProperties.preparationType === PREPARATION_TYPES.TRACK_RANDOMIZER

const getIsTrackRandomizer = additionalProperties =>
	additionalProperties.preparationType === PREPARATION_TYPES.TRACK_RANDOMIZER

export default {
	type: VISUAL_FLOW_MODULE_TYPES.UI_COMMAND,
	style: 'if',
	title: getTitle,
	headerTitle: getHeaderTitle,
	description: getDescription,
	component: Checkpoint,
	generator: (modules, additionalProperties) => ({
		actionButton: ACTION_BUTTON({
			enabled: getActionButtonEnabled(additionalProperties),
			label: getActionButtonLabel(additionalProperties),
			languages: additionalProperties.languages,
		}),
		layout: null, // 'CINEMA_START', 'CINEMA_END'
		history: getHistory(additionalProperties),
		enableConditions: getEnableConditions(additionalProperties),
		enableQuota: getEnableQuota(additionalProperties),
		isBlock: getIsBlock(additionalProperties),
		isBlockExpanded: true,
		randomize: getIsRandomizer(additionalProperties),
		shortName: getShortname(modules, additionalProperties),
		isRandomizer: getIsRandomizer(additionalProperties),
		isTrackRandomizer: getIsTrackRandomizer(additionalProperties),
		isTrack: additionalProperties.isTrack === true,
		randomizerCount: 1,
		randomizerShowAll: true,
		// filter modules in randomizer
		moduleFilter: null,
		quota: {
			isPercentage: true,
			number: 20,
		},
		conditions: getConditions(modules, additionalProperties, []),
		loopSettings: {
			isActive: additionalProperties.preparationType === PREPARATION_TYPES.LOOP,
			idInputList: '',
			maxIterations: null,
		},
		randomizerAllowSkip: true,
	}),
}
