import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'

const hasInvalidTimeout = module => module.definition.timeout < 500

const hasTooLongText = (module, languages) =>
	languages.some(language => module.definition.text[language.language].length > 1000)

export const hasInvalidMediaAlt = (media, studyTags, languages) => {
	if (media === null) {
		return false
	}

	if (studyTags.isAccessibilityEnabled !== true) {
		return false
	}

	const hasEmptAlt = languages.some(
		language =>
			media.url[language.language] !== null &&
			media.url[language.language] !== '' &&
			media.alt[language.language] === '',
	)

	return hasEmptAlt
}

const hasMissingMedia = (module, languages) => {
	const hasMessageWithMedia = languages.some(
		({ language }) => module.definition.media.url[language] !== '',
	)
	const hasMessageWithouthMedia = languages.some(
		({ language }) => module.definition.media.url[language] === '',
	)

	return hasMessageWithMedia === hasMessageWithouthMedia
}

const validateMessage = (module, studyTags, languages) => {
	const validationResult = []

	if (hasInvalidTimeout(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.message_timeoutMinTimeout,
		})
	}

	if (hasTooLongText(module, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.message_text_too_long,
		})
	}

	if (hasInvalidMediaAlt(module.definition.media, studyTags, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.message_media_missing_alt,
		})
	}

	if (hasMissingMedia(module, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.message_missing_media,
		})
	}

	return validationResult
}

export default validateMessage
