export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Procedere',
	allocation_option: 'Opzione di allocazione',
	choice_option: 'Opzione di scelta',
	submit: 'Invia',
	freetext_date_placeholder: 'Inserisci una data',
	freetext_date_inputHint: 'es. gg/mm/anno',
	freetext_phone_placeholder: 'Inserisci un numero di telefono',
	freetext_prhone_inputHint: 'es. 000-123-4567',
	freetext_email_placeholder: 'Inserisci la tua email',
	freetext_email_inputHint: 'es. mia@mail.com',
	freetext_zip_placeholder: 'Inserisci il tuo CAP',
	freetext_zip_inputHint: 'es. 12345',
	freetext_answer_placeholder: 'Inserisci la tua risposta',
	freetext_integer_inputHint: 'es. 123',
	freetext_number_inputHint: 'es. 1,5',
	freetext_amount_placeholder: "Inserisci l'importo",
	freetext_amount_inputHint: 'es. 1000,00',
	matrix_question: 'Domanda a matrice',
	matrix_loop_question:
		"Assicurati di includere [[item]] nel testo della domanda per presentare una domanda unica per ogni elemento dell'elenco",
	maxdiff_option: 'Elemento',
	maxdiff_best_question: 'Secondo te, quali di questi sono gli attributi **più** importanti?',
	maxdiff_worst_question: 'Secondo te, quali di questi sono gli attributi **meno** importanti?',
	new_message: 'Nuovo messaggio',
	ranking_option: 'Opzione di ranking',
	question_default: 'Scrivi la tua domanda',
	none_of_these: 'Nessuno di questi',
	study_languages_language_choice_message: 'Qual è la tua lingua preferita?',
	ar: 'Arabo',
	zh: 'Cinese',
	da: 'Danese',
	nl: 'Olandese',
	en: 'Inglese',
	fr: 'Francese',
	de: 'Tedesco',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonesiano',
	it: 'Italiano',
	ja: 'Giapponese',
	ko: 'Coreano',
	pl: 'Polacco',
	pt: 'Portoghese',
	ro: 'Rumeno',
	ru: 'Russo',
	sk: 'Slovacco',
	es: 'Spagnolo',
	sv: 'Svedese',
	tl: 'Tagalog',
	th: 'Thailandese',
	tr: 'Turco',
	yo: 'Yoruba',
}
