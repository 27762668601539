export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Fortsätt',
	allocation_option: 'Allokeringsalternativ',
	choice_option: 'Valalternativ',
	submit: 'Skicka in',
	freetext_date_placeholder: 'Ange ett datum',
	freetext_date_inputHint: 't.ex. dd/mm/år',
	freetext_phone_placeholder: 'Ange ett telefonnummer',
	freetext_prhone_inputHint: 't.ex. 000-123-4567',
	freetext_email_placeholder: 'Ange din e-post',
	freetext_email_inputHint: 't.ex. min@mail.com',
	freetext_zip_placeholder: 'Ange ditt postnummer',
	freetext_zip_inputHint: 't.ex. 12345',
	freetext_answer_placeholder: 'Ange ditt svar',
	freetext_integer_inputHint: 't.ex. 123',
	freetext_number_inputHint: 't.ex. 1,5',
	freetext_amount_placeholder: 'Ange belopp',
	freetext_amount_inputHint: 't.ex. 1000,00',
	matrix_question: 'Matrisfråga',
	matrix_loop_question:
		'Se till att inkludera [[item]] i frågetexten för att presentera en unik fråga för varje listobjekt',
	maxdiff_option: 'Objekt',
	maxdiff_best_question: 'Vilka av dessa anser du är de **viktigaste** egenskaperna?',
	maxdiff_worst_question: 'Vilka av dessa anser du är de **minst** viktiga egenskaperna?',
	new_message: 'Nytt meddelande',
	ranking_option: 'Rankingalternativ',
	question_default: 'Skriv in din fråga',
	none_of_these: 'Ingen av dessa',
	study_languages_language_choice_message: 'Vilket är ditt föredragna språk?',
	ar: 'Arabiska',
	zh: 'Kinesiska',
	da: 'Danska',
	nl: 'Holländska',
	en: 'Engelska',
	fr: 'Franska',
	de: 'Tyska',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonesiska',
	it: 'Italienska',
	ja: 'Japanska',
	ko: 'Koreanska',
	pl: 'Polska',
	pt: 'Portugisiska',
	ro: 'Rumänska',
	ru: 'Ryska',
	sk: 'Slovakiska',
	es: 'Spanska',
	sv: 'Svenska',
	tl: 'Tagalog',
	th: 'Thailändska',
	tr: 'Turkiska',
	yo: 'Yoruba',
}
