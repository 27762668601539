export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: '続行',
	allocation_option: '配分オプション',
	choice_option: '選択オプション',
	submit: '送信',
	freetext_date_placeholder: '日付を入力してください',
	freetext_date_inputHint: '例: mm/dd/yyyy',
	freetext_phone_placeholder: '電話番号を入力してください',
	freetext_prhone_inputHint: '例: 000-123-4567',
	freetext_email_placeholder: 'メールアドレスを入力してください',
	freetext_email_inputHint: '例: my@mail.com',
	freetext_zip_placeholder: 'ZIPコードを入力してください',
	freetext_zip_inputHint: '例: 12345',
	freetext_answer_placeholder: '回答を入力してください',
	freetext_integer_inputHint: '例: 123',
	freetext_number_inputHint: '例: 1.5',
	freetext_amount_placeholder: '金額を入力してください',
	freetext_amount_inputHint: '例: 1000.00',
	matrix_question: 'マトリックス質問',
	matrix_loop_question:
		'各リスト項目に対してユニークな質問を提示するために、質問文に[[item]]を含めてください',
	maxdiff_option: '項目',
	maxdiff_best_question: 'あなたの意見では、これらの中で最も重要な属性はどれですか？',
	maxdiff_worst_question: 'あなたの意見では、これらの中で最も重要でない属性はどれですか？',
	new_message: '新しいメッセージ',
	ranking_option: 'ランキングオプション',
	question_default: '質問を入力してください',
	none_of_these: 'これらの中にはありません',
	study_languages_language_choice_message: 'あなたの好みの言語は何ですか？',
	ar: 'アラビア語',
	zh: '中国語',
	da: 'デンマーク語',
	nl: 'オランダ語',
	en: '英語',
	fr: 'フランス語',
	de: 'ドイツ語',
	ha: 'ハウサ語',
	hi: 'ヒンディー語',
	ig: 'イボ語',
	id: 'インドネシア語',
	it: 'イタリア語',
	ja: '日本語',
	ko: '韓国語',
	pl: 'ポーランド語',
	pt: 'ポルトガル語',
	ro: 'ルーマニア語',
	ru: 'ロシア語',
	sk: 'スロバキア語',
	es: 'スペイン語',
	sv: 'スウェーデン語',
	tl: 'タガログ語',
	th: 'タイ語',
	tr: 'トルコ語',
	yo: 'ヨルバ語',
}
