import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import { VISUAL_FLOW_MODULE_TYPES, CHOICE_SUBTYPE } from 'constants/studyDesign'

import Message from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Message'

import MEDIA_OBJECT from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MEDIA_OBJECT'
import { MESSAGE_DESCRIPTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/constants/MESSAGE'
import { getFreetextSettings } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getFreetextSettings'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'

const getIdRelatedAnswer = definition => _.get(definition, 'idRelatedAnswer', null)

const getTranslations = (definition, modules = null, options) => {
	const isPreviewOnly = _.get(options, 'isPreviewOnly', false)

	if (isPreviewOnly === true) {
		return { title: 'message.title.preview', description: 'message.description.preview' }
	}

	const messageTranslations = { title: 'message.title', description: 'message.description' }

	if (modules === null) {
		return messageTranslations
	}

	const idRelatedAnswer = getIdRelatedAnswer(definition)
	const relatedAnswerModule = modules[idRelatedAnswer]

	if (idRelatedAnswer === null || relatedAnswerModule === undefined) {
		return messageTranslations
	}

	const { type } = relatedAnswerModule

	if (type === VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT) {
		return {
			title: getFreetextSettings(relatedAnswerModule.definition.freetextType).title,
			description: MESSAGE_DESCRIPTION[type],
		}
	}

	if (type === VISUAL_FLOW_MODULE_TYPES.A_OEQ) {
		return { title: 'oeq.title', description: MESSAGE_DESCRIPTION[type] }
	}

	if (type === VISUAL_FLOW_MODULE_TYPES.ALLOCATION) {
		return { title: 'allocation.title', description: MESSAGE_DESCRIPTION[type] }
	}

	if (type === VISUAL_FLOW_MODULE_TYPES.RANKING) {
		return { title: 'ranking.title', description: MESSAGE_DESCRIPTION[type] }
	}

	if (type === VISUAL_FLOW_MODULE_TYPES.A_CHOICE) {
		if (relatedAnswerModule.definition.isLanguageChoice === true) {
			return {
				title: 'study_languages_language_choice',
				description: 'study_languages_language_choice_description',
			}
		}

		return {
			title:
				relatedAnswerModule.definition.subtype === CHOICE_SUBTYPE.IMAGE
					? 'imageChoice.title'
					: 'choice.title',
			description: MESSAGE_DESCRIPTION[type][relatedAnswerModule.definition.subtype],
		}
	}

	if (type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE) {
		return {
			title:
				relatedAnswerModule.definition.subtype === CHOICE_SUBTYPE.IMAGE
					? 'matrixImageChoice.title'
					: 'matrixChoice.title',
			description: MESSAGE_DESCRIPTION[type][relatedAnswerModule.definition.subtype],
		}
	}

	return messageTranslations
}

const getTextTranslation = additionalProperties =>
	_.get(additionalProperties, 'idTextTranslation', 'new_message')

const getTitle = (definition, modules, options) => {
	const { title } = getTranslations(definition, modules, options)

	return title
}

const getDescription = (definition, modules, options) => {
	const { description } = getTranslations(definition, modules, options)

	return description
}

const generator = (modules, additionalProperties) => ({
	idRelatedAnswer: getIdRelatedAnswer(additionalProperties),
	text: getLanguagesLabelsObject(
		additionalProperties.languages,
		getTextTranslation(additionalProperties),
	),
	media: MEDIA_OBJECT(additionalProperties),
	timeout: 1000,
})

export const GET_QUESTION_MESSAGE = additionalProperties => ({
	type: VISUAL_FLOW_MODULE_TYPES.A_MESSAGE,
	definition: {
		id: uuidv4(),
		...generator({}, additionalProperties),
	},
})

export default {
	type: VISUAL_FLOW_MODULE_TYPES.A_MESSAGE,
	style: 'message',
	title: getTitle,
	description: getDescription,
	component: Message,
	generator,
}
