import _ from 'lodash'
import validateMessage from './validateMessage'

const validateDatasetMessages = (module, studyTags, languages) => {
	const message = _.get(module, 'definition.messages[0]')

	const errors = message !== undefined ? validateMessage(message, studyTags, languages) : []

	return errors.map(error => ({
		...error,
		id: module.definition.id,
	}))
}

export default validateDatasetMessages
