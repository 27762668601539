import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import { Field } from 'formik'

import { getConditionSelectionTypeSelectOptions } from 'helpers/conditionBuilder/getConditionSelectionTypeSelectOptions'
import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

import ChoiceAnswerMultiselect from 'components/_formik/_complex/ChoiceAnswerMultiselect'
import Checkbox from 'components/_formik/_base/Checkbox'
import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'

const TrapQuestionSettings = props => {
	const { disabled, setFieldValue, setValues, values } = props
	const intl = useIntl()

	const handleCheckboxChange = value => {
		if (value === false) {
			setValues({
				...values,
				trapQuestionSettings: {
					...values.trapQuestionSettings,
					isTrapQuestion: false,
					correctAnswers: [],
				},
			})
		} else {
			setValues({
				...values,
				trapQuestionSettings: {
					...values.trapQuestionSettings,
					isTrapQuestion: true,
					correctAnswers: [values.options[0].id],
				},
			})
		}
	}

	const handleMultiSelectChange = value => {
		setFieldValue('trapQuestionSettings.correctAnswers', value)
	}

	return (
		<div data-id="trap-question">
			<Field
				component={Checkbox}
				isDarkTheme
				componentProps={{
					label: intl.formatMessage({ id: 'choice.detail.trap.mark_as_trap' }),
				}}
				disabled={disabled}
				name={'trapQuestionSettings.isTrapQuestion'}
				onChange={handleCheckboxChange}
			/>
			{values.trapQuestionSettings.isTrapQuestion === true && (
				<div data-id="trap-question-settings">
					<Label
						label={intl.formatMessage({ id: 'choice.detail.trap.selection' })}
						className="title-secondary"
					/>
					<Field
						component={Select}
						disabled={props.disabled}
						name="trapQuestionSettings.selection"
						options={getConditionSelectionTypeSelectOptions(intl)}
					/>
					<Label
						label={intl.formatMessage({ id: 'choice.detail.trap.correct_answers' })}
						className="title-secondary"
					/>
					<ChoiceAnswerMultiselect
						activeLanguage={props.activeLanguage}
						disabled={disabled}
						name={'trapQuestionSettings.correctAnswers'}
						onChange={handleMultiSelectChange}
						selectionType={values.trapQuestionSettings.selection}
						studyObject={{ type: MODULE_DEFINITIONS.A_CHOICE.type, definition: values }}
						value={values.trapQuestionSettings.correctAnswers}
					/>
				</div>
			)}
		</div>
	)
}

TrapQuestionSettings.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setValues: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
}

export default TrapQuestionSettings
