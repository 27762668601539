import PropTypes from 'prop-types'
import React, { useState, Fragment } from 'react'
import _ from 'lodash'
import { Field, Formik, Form } from 'formik'
import { useIntl } from 'react-intl'

import { VISUAL_FLOW_MODULE_TYPES, CHOICE_SUBTYPE } from 'constants/studyDesign'
import { studyLanguagesShape } from 'constants/languages/studyLanguagesShape'

import MEDIA_OBJECT from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MEDIA_OBJECT'
import { getNormalOptionsCount } from 'helpers/visualFlowModules/getNormalOptionsCount'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import Checkbox from 'components/_formik/_base/Checkbox'
import ChoiceOptions from 'components/_formik/_custom/_studyDesign/ChoiceOptions'
import BaseInput from 'components/_scaffolding/_input/Input'
import Select from 'components/_scaffolding/_input/Select'

import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'

import DividerLine from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/DividerLine'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'

import classes from './MaxDiff.module.scss'

const createExpandedState = (options, isExpanded) => {
	const state = {}
	options.forEach(option => {
		state[option.id] = isExpanded
	})

	return state
}

const getAreAllCollapsed = state => JSON.stringify(state).includes('true') === false

// question formats are different than choice subtype
// because we have 2 formats for IMAGE subtype
const MAXDIFF_QUESTION_FORMATS = {
	CHECKBOX: 'CHECKBOX',
	IMAGE: 'IMAGE',
	IMAGE_NO_LABELS: 'IMAGE_NO_LABELS',
}

const MAXDIFF_QUESTION_FORMATS_TO_SUBTYPE = {
	[MAXDIFF_QUESTION_FORMATS.CHECKBOX]: CHOICE_SUBTYPE.CHECKBOX,
	[MAXDIFF_QUESTION_FORMATS.IMAGE]: CHOICE_SUBTYPE.IMAGE,
	[MAXDIFF_QUESTION_FORMATS.IMAGE_NO_LABELS]: CHOICE_SUBTYPE.IMAGE,
}

const MaxDiff = props => {
	const intl = useIntl()

	const [expandedState, setExpandedState] = useState(
		createExpandedState(props.initialValues.options, false),
	)

	const toggleOption = idOption => {
		setExpandedState(current => ({
			...current,
			[idOption]: current[idOption] !== true,
		}))
	}

	const expandOption = idOption => {
		setExpandedState(current => ({
			...current,
			[idOption]: true,
		}))
	}

	const toggleAll = () => {
		setExpandedState(current => {
			const newValue = getAreAllCollapsed(current)

			return createExpandedState(props.initialValues.options, newValue)
		})
	}

	const additionalActions = [
		{
			isDisabled: false,
			label:
				getAreAllCollapsed(expandedState) === false
					? intl.formatMessage({ id: 'choice.detail.collapse_all' })
					: intl.formatMessage({ id: 'choice.detail.expand_all' }),
			tooltipText: '',
			value: toggleAll,
		},
	]

	const subtypeOptions = [
		{
			value: MAXDIFF_QUESTION_FORMATS.CHECKBOX,
			label: intl.formatMessage({ id: 'choice.question.format.checkbox' }),
		},
		{
			value: MAXDIFF_QUESTION_FORMATS.IMAGE,
			label: intl.formatMessage({ id: 'maxDiff.image.title' }),
		},
		{
			value: MAXDIFF_QUESTION_FORMATS.IMAGE_NO_LABELS,
			label: intl.formatMessage({ id: 'maxDiff.image.no_label' }),
		},
	]

	const getHandleQuestionFormatChange = (setValues, values) => selectedOption => {
		const newSubtype = MAXDIFF_QUESTION_FORMATS_TO_SUBTYPE[selectedOption.value]

		if ([CHOICE_SUBTYPE.CHECKBOX, CHOICE_SUBTYPE.IMAGE].includes(newSubtype) === false) {
			throw new Error(`Unsupporter MAXDIFF subtype: ${newSubtype}`)
		}

		const newOptions = values.options.map(option => ({
			...option,
			showLabel: selectedOption.value === MAXDIFF_QUESTION_FORMATS.IMAGE,
			media:
				newSubtype === CHOICE_SUBTYPE.IMAGE && option.media === null
					? MEDIA_OBJECT({ isNoneOfThese: option.isNoneOfThese, languages: props.languages })
					: option.media,
		}))

		setValues({
			...values,
			options: newOptions,
			subtype: newSubtype,
		})
	}

	const getQuestionFormatValue = values => {
		if (values.subtype === CHOICE_SUBTYPE.IMAGE) {
			const questionFormat = values.options.every(option => option.showLabel === true)
				? MAXDIFF_QUESTION_FORMATS.IMAGE
				: MAXDIFF_QUESTION_FORMATS.IMAGE_NO_LABELS

			return subtypeOptions.find(option => option.value === questionFormat)
		}

		if (values.subtype === CHOICE_SUBTYPE.CHECKBOX) {
			return subtypeOptions.find(option => option.value === MAXDIFF_QUESTION_FORMATS.CHECKBOX)
		}

		throw new Error(`Unknown MAXDIFF subtype: ${values.subtype}`)
	}

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ setFieldValue, setValues, values }) => (
				<Fragment>
					<Header
						activeLanguage={props.activeLanguage}
						additionalActions={additionalActions}
						closeModuleDetail={props.closeModuleDetail}
						copyModule={props.copyModule}
						copyModuleButtonParams={props.copyModuleButtonParams}
						disabled={props.disabled}
						generalDefinition={props.generalDefinition}
						isFlowChanged={props.isFlowChanged}
						isRelatedModuleInvalid={props.isRelatedModuleInvalid}
						languages={props.languages}
						languagesValidationResult={props.languagesValidationResult}
						moduleDefinition={values}
						openCopyToLibraryForm={props.openCopyToLibraryForm}
						setActiveLanguage={props.setActiveLanguage}
						showCopyToLibrary
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={() => (
								<Form>
									<DatasetShortName
										disabled={props.disabled}
										errorInvalidSimpleName={props.errorInvalidSimpleName}
										errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
										values={values}
									/>
									<Label label={intl.formatMessage({ id: 'choice.question.format' })} />
									<Select
										id={'question-format-select'}
										isSearchable={false}
										isDisabled={props.disabled}
										onChange={getHandleQuestionFormatChange(setValues, values)}
										options={subtypeOptions}
										value={getQuestionFormatValue(values)}
									/>
									<DividerLine type={DividerLine.TYPES.QUESTION} />
									<Label
										label={intl.formatMessage({
											id: 'items',
										})}
									/>
									<ChoiceOptions
										activeLanguage={props.activeLanguage}
										disabled={props.disabled}
										expandOption={expandOption}
										getNormalOptionsCount={getNormalOptionsCount}
										isUnsaved={props.isUnsaved}
										isInternalEmployee={props.isInternalEmployee}
										languages={props.languages}
										listState={expandedState}
										moduleType={VISUAL_FLOW_MODULE_TYPES.MAXDIFF}
										openMediaManager={props.openMediaManager}
										regenerateCodeValues={_.noop}
										setFieldValue={setFieldValue}
										setValues={setValues}
										showNoneOfTheseOption={false}
										toggleOption={toggleOption}
										values={values}
									/>
									<DividerLine type={DividerLine.TYPES.QUESTION} />
									<Field
										component={Checkbox}
										componentProps={{
											label: intl.formatMessage({ id: 'maxDiff.detail.blur' }),
										}}
										disabled={props.disabled}
										name={'blurBetweenRounds'}
									/>
									<div className={classes.settings}>
										<div>
											<Label
												label={intl.formatMessage({
													id: 'maxDiff.detail.number_of_items_per_round',
												})}
											/>
											<Field
												component={Input}
												disabled={props.disabled}
												name="numberOfItemsPerRound"
												placeholder={intl.formatMessage({
													id: 'maxDiff.detail.number_of_items_per_round',
												})}
												type="number"
												min={2}
												max={values.options.length}
											/>
										</div>
										<div>
											<Label
												label={intl.formatMessage({ id: 'maxDiff.detail.number_of_rounds' })}
											/>
											<BaseInput
												value={Math.floor(values.options.length / values.numberOfItemsPerRound)}
												className={classes['number-of-rounds']}
												name="numberOfRounds"
												onChange={_.noop}
											/>
										</div>
									</div>
									{Number(values.numberOfItemsPerRound) < 2 && (
										<div className="title-error">
											{intl.formatMessage({ id: 'maxDiff.detail.too_few_items_per_round' })}
										</div>
									)}
									{Number(values.numberOfItemsPerRound) > values.options.length && (
										<div className="title-error">
											{intl.formatMessage({ id: 'maxDiff.detail.too_few_options' })}
										</div>
									)}
								</Form>
							)}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

MaxDiff.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	closeModuleDetail: PropTypes.func.isRequired,
	copyModule: PropTypes.func.isRequired,
	copyModuleButtonParams: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isRelatedModuleInvalid: PropTypes.bool.isRequired,
	isUnsaved: PropTypes.bool,
	isInternalEmployee: PropTypes.bool.isRequired,
	languages: studyLanguagesShape.isRequired,
	languagesValidationResult: PropTypes.object.isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	openMediaManager: PropTypes.func.isRequired,
	saveModule: PropTypes.func.isRequired,
	setActiveLanguage: PropTypes.func.isRequired,
}

export default MaxDiff
