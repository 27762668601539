import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Formik, Form, Field, FieldArray } from 'formik'
import { injectIntl } from 'react-intl'
import _ from 'lodash'

import { getIsStudyEditable } from 'helpers/studyList/getIsStudyEditable'
import { studyLanguagesShape } from 'constants/languages/studyLanguagesShape'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import Checkbox from 'components/_formik/_base/Checkbox'
import FormattedInput from 'components/_formik/_complex/FormattedInput'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import IconButton from 'components/_scaffolding/Buttons/IconButton'
import FieldArrayAdd from 'components/_formik/_base/FieldArrayAdd'

import AdvancedFeatureLabel from 'components/_scaffolding/AdvancedFeatureLabel'
import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import ModuleAdvancedOptionsToggle from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/ModuleAdvancedOptionsToggle'

import classes from './OpenQuestion.module.scss'

const isTranslationDefault = translation => translation === ''

class OpenQuestion extends Component {
	static propTypes = {
		activeLanguage: PropTypes.string.isRequired,
		closeModuleDetail: PropTypes.func.isRequired,
		copyModule: PropTypes.func.isRequired,
		copyModuleButtonParams: PropTypes.object.isRequired,
		disabled: PropTypes.bool.isRequired,
		errorInvalidSimpleName: PropTypes.bool.isRequired,
		errorNonUniqueSimpleName: PropTypes.bool.isRequired,
		generalDefinition: PropTypes.object.isRequired,
		initialValues: PropTypes.object.isRequired,
		intl: PropTypes.object.isRequired,
		isFlowChanged: PropTypes.bool.isRequired,
		isRelatedModuleInvalid: PropTypes.bool.isRequired,
		languages: studyLanguagesShape.isRequired,
		languagesValidationResult: PropTypes.object.isRequired,
		openCopyToLibraryForm: PropTypes.func.isRequired,
		saveModule: PropTypes.func.isRequired,
		setActiveLanguage: PropTypes.func.isRequired,
		shouldShowAdvancedFeatures: PropTypes.bool.isRequired,
		studyState: PropTypes.string.isRequired,
		studySettings: PropTypes.object.isRequired,
		study: PropTypes.object.isRequired,
	}

	state = { isExpanded: false, isTranslationsSectionExpanded: false }

	componentDidMount() {
		const { initialValues } = this.props
		const { translations } = initialValues

		const hasDefaultSettings =
			initialValues.ideate.disableSentenceSplitter === false &&
			initialValues.eliminate.show === true &&
			initialValues.elaborate.show === true &&
			initialValues.elaborate.minEvaluations === 8 &&
			initialValues.elaborate.maxEvaluations === 12
		const isExpanded = hasDefaultSettings === false

		const isTranslationsSectionExpanded =
			isTranslationDefault(translations.ideate_placeholder) === false ||
			isTranslationDefault(translations.elaborate_intro) === false ||
			isTranslationDefault(translations.elaborate_agree_label) === false ||
			isTranslationDefault(translations.elaborate_disagree_label) === false ||
			isTranslationDefault(translations.elaborate_indifferent_label) === false ||
			isTranslationDefault(translations.elaborate_unclear_statement_label) === false

		if (hasDefaultSettings === false) {
			this.setState({ isExpanded, isTranslationsSectionExpanded })
		}
	}

	toggleIsExpanded = () => {
		this.setState(currentState => ({
			...currentState,
			isExpanded: currentState.isExpanded === false,
		}))
	}

	toggleIsTranslationsSectionExpanded = () => {
		this.setState(currentState => ({
			...currentState,
			isTranslationsSectionExpanded: currentState.isTranslationsSectionExpanded === false,
		}))
	}

	resetTranslations = setFieldValue => () => {
		const newTranslations = {
			ideate_placeholder: getLanguagesLabelsObject(this.props.languages, ''),
			elaborate_intro: getLanguagesLabelsObject(this.props.languages, ''),
			elaborate_agree_label: getLanguagesLabelsObject(this.props.languages, ''),
			elaborate_disagree_label: getLanguagesLabelsObject(this.props.languages, ''),
			elaborate_indifferent_label: getLanguagesLabelsObject(this.props.languages, ''),
			elaborate_unclear_statement_label: getLanguagesLabelsObject(this.props.languages, ''),
		}

		setFieldValue('translations', newTranslations)
	}

	handleOnPaste = (values, setFieldValue) => event => {
		if (!event.clipboardData) {
			return
		}

		const clipboardData = event.clipboardData.getData('text/plain')

		const statementsLabels = clipboardData.split(/\n+|\t+|\r+|\r\n+/g)

		const statements = statementsLabels.map(label => ({ label, isUnsaved: true }))

		if (statements.length > 1) {
			event.preventDefault()
			setFieldValue('preseededStatements', [
				...values.preseededStatements.filter(s => s.label.length > 0),
				...statements.filter(s => s.label.length > 0),
			])
		}
	}

	render() {
		// this.props.languages does not contain disabled languages
		const solverDefaultLanguage = this.props.languages.find(l => l.isSolverDefault)

		if (solverDefaultLanguage === undefined) {
			throw new Error('solverDefaultLanguage is undefined')
		}

		const solverDefaultLanguageTranslation = this.props.intl.formatMessage({
			id: solverDefaultLanguage.language,
		})

		return (
			<Formik initialValues={{ ...this.props.initialValues }} onSubmit={() => {}}>
				{({ values, setFieldValue }) => (
					<Fragment>
						<Header
							activeLanguage={this.props.activeLanguage}
							closeModuleDetail={this.props.closeModuleDetail}
							copyModule={this.props.copyModule}
							copyModuleButtonParams={this.props.copyModuleButtonParams}
							disabled={this.props.disabled}
							generalDefinition={this.props.generalDefinition}
							isFlowChanged={this.props.isFlowChanged}
							isRelatedModuleInvalid={this.props.isRelatedModuleInvalid}
							languages={this.props.languages}
							languagesValidationResult={this.props.languagesValidationResult}
							moduleDefinition={values}
							openCopyToLibraryForm={this.props.openCopyToLibraryForm}
							setActiveLanguage={this.props.setActiveLanguage}
							showCopyToLibrary
						/>
						<FormHolder>
							<AutoSubmit
								values={values}
								onSave={this.props.saveModule}
								formComponent={_isSubmitting => (
									<Form>
										<DatasetShortName
											disabled={this.props.disabled}
											errorInvalidSimpleName={this.props.errorInvalidSimpleName}
											errorNonUniqueSimpleName={this.props.errorNonUniqueSimpleName}
											values={values}
										/>
										<ModuleAdvancedOptionsToggle
											id="oeq-advanced-options-toggle"
											onClick={this.toggleIsExpanded}
											isExpanded={this.state.isExpanded}
										/>
										{this.state.isExpanded === true && (
											<Fragment>
												{this.props.shouldShowAdvancedFeatures === true && (
													<Fragment>
														<Field
															component={Checkbox}
															isDarkTheme
															componentProps={{
																label: this.props.intl.formatMessage({
																	id: 'oeq.phase.ideation.enable_splitter',
																}),
																isAdvancedFeature: true,
															}}
															disabled={this.props.disabled}
															name="ideate.disableSentenceSplitter"
															onChange={value =>
																setFieldValue('ideate.disableSentenceSplitter', value === false)
															}
															value={values.ideate.disableSentenceSplitter === false}
															field={{
																value: values.ideate.disableSentenceSplitter === false,
																// overriden
																onChange: _.noop,
																onBlur: _.noop,
																name: 'ideate.disableSentenceSplitter',
															}}
														/>
													</Fragment>
												)}
												<Field
													component={Checkbox}
													isDarkTheme
													componentProps={{
														label: this.props.intl.formatMessage({ id: 'oeq.phase.elimination' }),
													}}
													disabled={this.props.disabled}
													name="eliminate.show"
												/>
												<Field
													component={Checkbox}
													isDarkTheme
													componentProps={{
														label: this.props.intl.formatMessage({ id: 'oeq.phase.elaboration' }),
													}}
													disabled={this.props.disabled}
													name="elaborate.show"
												/>
												{this.props.shouldShowAdvancedFeatures === true && (
													<Fragment>
														<Label
															label={this.props.intl.formatMessage({
																id: 'oeq.phase.elaboration.minEvaluations',
															})}
															secondaryLabel={<AdvancedFeatureLabel />}
														/>
														<Field
															component={FormattedInput}
															disabled={this.props.disabled}
															format={value => value}
															name="elaborate.minEvaluations"
															normalize={value => Number(value)}
															type="number"
														/>
														{values.elaborate.minEvaluations < 0 && (
															<span className="title-error">
																{this.props.intl.formatMessage({
																	id: 'oeq.phase.elaboration.minEvaluationsError',
																})}
															</span>
														)}
														<Label
															label={this.props.intl.formatMessage({
																id: 'oeq.phase.elaboration.maxEvaluations',
															})}
															secondaryLabel={<AdvancedFeatureLabel />}
														/>
														<Field
															component={FormattedInput}
															disabled={this.props.disabled}
															format={value => value}
															name="elaborate.maxEvaluations"
															normalize={value => Number(value)}
															type="number"
														/>
														{values.elaborate.maxEvaluations < 0 && (
															<span className="title-error">
																{this.props.intl.formatMessage({
																	id: 'oeq.phase.elaboration.maxEvaluationsError',
																})}
															</span>
														)}
														{values.elaborate.minEvaluations > values.elaborate.maxEvaluations && (
															<span className="title-error">
																{this.props.intl.formatMessage({
																	id: 'oeq.phase.elaboration.minMaxError',
																})}
															</span>
														)}
													</Fragment>
												)}
											</Fragment>
										)}
										{this.props.shouldShowAdvancedFeatures === true && (
											<ModuleAdvancedOptionsToggle
												id="oeq-custom-translations-toggle"
												onClick={this.toggleIsTranslationsSectionExpanded}
												isAdvancedFeature
												isExpanded={this.state.isTranslationsSectionExpanded}
												label={this.props.intl.formatMessage({
													id: 'oeq.custom_translations',
												})}
											/>
										)}
										{this.state.isTranslationsSectionExpanded === true &&
											this.props.shouldShowAdvancedFeatures === true && (
												<Fragment>
													<Label
														label={this.props.intl.formatMessage({
															id: 'oeq.ideate_placeholder',
														})}
														secondaryLabel={<AdvancedFeatureLabel />}
													/>
													<Field
														component={Input}
														disabled={this.props.disabled}
														name={`translations.ideate_placeholder.${this.props.activeLanguage}`}
														type="text"
													/>
													<Label
														label={this.props.intl.formatMessage({
															id: 'oeq.elaborate_intro',
														})}
														secondaryLabel={<AdvancedFeatureLabel />}
													/>
													<Field
														component={Input}
														disabled={this.props.disabled}
														name={`translations.elaborate_intro.${this.props.activeLanguage}`}
														type="text"
													/>
													<Label
														label={this.props.intl.formatMessage({
															id: 'oeq.elaborate_agree_label',
														})}
														secondaryLabel={<AdvancedFeatureLabel />}
													/>
													<Field
														component={Input}
														disabled={this.props.disabled}
														name={`translations.elaborate_agree_label.${this.props.activeLanguage}`}
														type="text"
													/>
													<Label
														label={this.props.intl.formatMessage({
															id: 'oeq.elaborate_disagree_label',
														})}
														secondaryLabel={<AdvancedFeatureLabel />}
													/>
													<Field
														component={Input}
														disabled={this.props.disabled}
														name={`translations.elaborate_disagree_label.${this.props.activeLanguage}`}
														type="text"
													/>
													<Label
														label={this.props.intl.formatMessage({
															id: 'oeq.elaborate_indifferent_label',
														})}
														secondaryLabel={<AdvancedFeatureLabel />}
													/>
													<Field
														component={Input}
														disabled={this.props.disabled}
														name={`translations.elaborate_indifferent_label.${this.props.activeLanguage}`}
														type="text"
													/>
													<Label
														label={this.props.intl.formatMessage({
															id: 'oeq.elaborate_unclear_statement_label',
														})}
														secondaryLabel={<AdvancedFeatureLabel />}
													/>
													<Field
														component={Input}
														disabled={this.props.disabled}
														name={`translations.elaborate_unclear_statement_label.${this.props.activeLanguage}`}
														type="text"
													/>
													{this.props.disabled !== true && (
														<IconButton
															label={this.props.intl.formatMessage({
																id: 'oeq.custom_translations.reset_defaults',
															})}
															onClick={this.resetTranslations(setFieldValue)}
															iconClassName={classes.refresh}
														/>
													)}
												</Fragment>
											)}
										<Label
											label={this.props.intl.formatMessage({ id: 'oeq.preseeded_statements' })}
										/>
										<FieldArray name="preseededStatements">
											{arrayHelpers => (
												<div id="preseededStatements">
													{values.preseededStatements.map((statement, i) => (
														<div id={`preseededStatement-${i}`} key={i}>
															<div className={classes['preseeded-statement']}>
																<Field
																	component={Input}
																	className={classes['preseeded-statement__input']}
																	disabled={
																		this.props.disabled === true ||
																		(getIsStudyEditable(this.props.studyState) === false &&
																			statement.isUnsaved !== true)
																	}
																	name={`preseededStatements[${i}].label`}
																	onPaste={this.handleOnPaste(values, setFieldValue)}
																	placeholder={this.props.intl.formatMessage({
																		id: 'oeq.preseeded_statements.placeholder',
																	})}
																	type="text"
																	validate={{ required: false }}
																/>
																{(getIsStudyEditable(this.props.studyState) === true ||
																	statement.isUnsaved === true) && (
																	<div
																		className={classes['preseeded-statement__remove']}
																		onClick={() => {
																			arrayHelpers.remove(i)
																		}}
																		title={this.props.intl.formatMessage({ id: 'remove' })}
																	/>
																)}
															</div>
															{values.preseededStatements[i].length === 0 && (
																<span className="title-error">
																	{this.props.intl.formatMessage({
																		id: 'oeq.preseeded_statements.error.length',
																	})}
																</span>
															)}
														</div>
													))}
													{this.props.disabled === false && (
														<FieldArrayAdd
															label={this.props.intl.formatMessage({
																id: 'oeq.preseeded_statements.add',
															})}
															add={() => {
																setFieldValue('preseededStatements', [
																	...values.preseededStatements,
																	{ label: '', isUnsaved: true },
																])
															}}
														/>
													)}
												</div>
											)}
										</FieldArray>
										<span className="title-info">
											{this.props.intl.formatMessage(
												{ id: 'oeq.preseeded_statements.language_info' },
												{ language: solverDefaultLanguageTranslation },
											)}
										</span>
									</Form>
								)}
							/>
						</FormHolder>
					</Fragment>
				)}
			</Formik>
		)
	}
}

export default injectIntl(OpenQuestion)
