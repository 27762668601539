import FreeText from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/FreeText'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { getFreetextSettings } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getFreetextSettings'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'
import { GET_QUESTION_MESSAGE } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/MESSAGE'

import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

const getValidationPattern = additionalProperties =>
	getFreetextSettings(additionalProperties.freetextType).validationPattern

const getPlaceholder = additionalProperties => {
	const settings = getFreetextSettings(additionalProperties.freetextType)
	return getLanguagesLabelsObject(additionalProperties.languages, settings.placeholderTranslation)
}

const getInputHint = additionalProperties => {
	const settings = getFreetextSettings(additionalProperties.freetextType)
	return getLanguagesLabelsObject(additionalProperties.languages, settings.inputHintTranslation)
}

const getTitle = definition => getFreetextSettings(definition.freetextType).title

const getDescription = definition => getFreetextSettings(definition.freetextType).description

export default {
	type: VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT,
	style: 'free-text',
	title: definition => getTitle(definition),
	description: definition => getDescription(definition),
	component: FreeText,
	generator: (modules, additionalProperties) => ({
		shortName: getDatasetShortName(
			modules,
			getFreetextSettings(additionalProperties.freetextType).datasetShortName,
			VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT,
			additionalProperties.freetextType,
		),
		freetextType: additionalProperties.freetextType,
		idRelatedMessage: null,
		textareaForm: false,
		skip: false,
		placeholder: getPlaceholder(additionalProperties),
		inputHint: getInputHint(additionalProperties),
		maxInputLength: 100,
		validationPattern: getValidationPattern(additionalProperties),
		messages: [
			GET_QUESTION_MESSAGE({ ...additionalProperties, idTextTranslation: 'question_default' }),
		],
	}),
}
