import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import validateModule from './validateModule'

const validateModules = (
	modules,
	order,
	flatOrder,
	communityModules,
	proFlow,
	respondentSources,
	studyTags,
	languages,
	duplicateShortNamesObject,
) => {
	const invalidModules = []

	order.forEach(orderModule => {
		const module = modules[orderModule.id]

		if (module.type !== MODULE_DEFINITIONS.PLACEHOLDER.type) {
			invalidModules.push(
				...validateModule(
					module,
					modules,
					orderModule,
					flatOrder,
					communityModules,
					proFlow,
					respondentSources,
					studyTags,
					languages,
					duplicateShortNamesObject,
				),
			)
		}
	})

	return invalidModules
}

export default validateModules
