export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Continuați',
	allocation_option: 'Opțiune de alocare',
	choice_option: 'Opțiune de alegere',
	submit: 'Trimiteți',
	freetext_date_placeholder: 'Introduceți o dată',
	freetext_date_inputHint: 'de ex. zz/ll/aaaa',
	freetext_phone_placeholder: 'Introduceți un număr de telefon',
	freetext_prhone_inputHint: 'de ex. 000-123-4567',
	freetext_email_placeholder: 'Introduceți emailul dvs.',
	freetext_email_inputHint: 'de ex. my@mail.com',
	freetext_zip_placeholder: 'Introduceți codul poștal',
	freetext_zip_inputHint: 'de ex. 12345',
	freetext_answer_placeholder: 'Introduceți răspunsul dvs.',
	freetext_integer_inputHint: 'de ex. 123',
	freetext_number_inputHint: 'de ex. 1.5',
	freetext_amount_placeholder: 'Introduceți suma',
	freetext_amount_inputHint: 'de ex. 1000.00',
	matrix_question: 'Întrebare matrice',
	matrix_loop_question:
		'Asigurați-vă că includeți [[item]] în textul întrebării pentru a prezenta o întrebare unică pentru fiecare element din listă',
	maxdiff_option: 'Element',
	maxdiff_best_question:
		'În opinia dvs., care dintre acestea sunt cele **mai** importante atribute?',
	maxdiff_worst_question:
		'În opinia dvs., care dintre acestea sunt cele **mai puțin** importante atribute?',
	new_message: 'Mesaj nou',
	ranking_option: 'Opțiune de clasare',
	question_default: 'Introduceți întrebarea dumneavoastră',
	none_of_these: 'Niciunul dintre acestea',
	study_languages_language_choice_message: 'Care este limba dumneavoastră preferată?',
	ar: 'Arabă',
	zh: 'Chineză',
	da: 'Daneză',
	nl: 'Olandeză',
	en: 'Engleză',
	fr: 'Franceză',
	de: 'Germană',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indoneziană',
	it: 'Italiană',
	ja: 'Japoneză',
	ko: 'Coreeană',
	pl: 'Poloneză',
	pt: 'Portuguzească',
	ro: 'Română',
	ru: 'Rusă',
	sk: 'Slovacă',
	es: 'Spaniolă',
	sv: 'Suecă',
	tl: 'Tagalog',
	th: 'Thailandeză',
	tr: 'Turcă',
	yo: 'Yoruba',
}
