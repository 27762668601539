export const validate = (module, flow, config) => {
	const { languages } = config

	for (const language of languages) {
		const imageUrl = module.definition.imageUrl[language]

		if (imageUrl === undefined) {
			return {
				infos: null,
				warnings: null,
				errors: [`${language}: Heatmap imageUrl is not defined`],
			}
		}

		if (imageUrl.trim() === '') {
			return {
				infos: null,
				warnings: null,
				errors: [`${language}: Heatmap imageUrl cannot be empty`],
			}
		}
	}

	return null
}

export default validate
