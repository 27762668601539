export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'ดำเนินการต่อ',
	allocation_option: 'ตัวเลือกการจัดสรร',
	choice_option: 'ตัวเลือกการเลือก',
	submit: 'ส่ง',
	freetext_date_placeholder: 'กรอกวันที่',
	freetext_date_inputHint: 'เช่น mm/dd/year',
	freetext_phone_placeholder: 'กรอกหมายเลขโทรศัพท์',
	freetext_prhone_inputHint: 'เช่น 000-123-4567',
	freetext_email_placeholder: 'กรอกอีเมลของคุณ',
	freetext_email_inputHint: 'เช่น my@mail.com',
	freetext_zip_placeholder: 'กรอกรหัสไปรษณีย์',
	freetext_zip_inputHint: 'เช่น 12345',
	freetext_answer_placeholder: 'กรอกคำตอบของคุณ',
	freetext_integer_inputHint: 'เช่น 123',
	freetext_number_inputHint: 'เช่น 1.5',
	freetext_amount_placeholder: 'กรอกจำนวนเงิน',
	freetext_amount_inputHint: 'เช่น 1000.00',
	matrix_question: 'คำถามแบบแมทริกซ์',
	matrix_loop_question:
		'โปรดรวม [[item]] ในข้อความคำถามเพื่อแสดงคำถามที่ไม่ซ้ำกันสำหรับแต่ละรายการในรายการ',
	maxdiff_option: 'รายการ',
	maxdiff_best_question: 'ตามความเห็นของคุณ คุณคิดว่าอะไรคือคุณลักษณะที่ **สำคัญที่สุด**?',
	maxdiff_worst_question: 'ตามความเห็นของคุณ คุณคิดว่าอะไรคือคุณลักษณะที่ **ไม่สำคัญที่สุด**?',
	new_message: 'ข้อความใหม่',
	ranking_option: 'ตัวเลือกการจัดอันดับ',
	question_default: 'พิมพ์คำถามของคุณ',
	none_of_these: 'ไม่มีอันไหนเลย',
	study_languages_language_choice_message: 'ภาษาที่คุณต้องการคืออะไร?',
	ar: 'อารบิก',
	zh: 'จีน',
	da: 'เดนมาร์ก',
	nl: 'ดัตช์',
	en: 'อังกฤษ',
	fr: 'ฝรั่งเศส',
	de: 'เยอรมัน',
	ha: 'เฮาสา',
	hi: 'ฮินดี',
	ig: 'อิบู',
	id: 'อินโดนีเซีย',
	it: 'อิตาลี',
	ja: 'ญี่ปุ่น',
	ko: 'เกาหลี',
	pl: 'โปแลนด์',
	pt: 'โปรตุเกส',
	ro: 'โรมาเนีย',
	ru: 'รัสเซีย',
	sk: 'สโลวัก',
	es: 'สเปน',
	sv: 'สวีเดน',
	tl: 'ตากาล็อก',
	th: 'ไทย',
	tr: 'ตุรกี',
	yo: 'โยรูบา',
}
