import _ from 'lodash'
import {
	removeModuleIntent,
	removeModuleApprove,
} from 'routes/_study/StudyDesign/_store/studyDesign'

import isQuotaModule from 'helpers/visualFlowModules/isQuotaModule'
import isOpenAnswerModule from 'helpers/visualFlowModules/isOpenAnswerModule'
import isDatasetModule from 'helpers/visualFlowModules/isDatasetModule'
import { getNumberOfFlowErrors } from 'routes/_study/StudyDesign/_store/helpers/flowValidation/getNumberOfFlowErrors'
import { getEnabledLanguages } from 'helpers/languages/getEnabledLanguages'

/**
 * Setting up branch
 */
const branch = state => state.studyDesign

/**
 * Simple Selectors
 */

export const getProFlow = state => branch(state).proFlow
export const getIsProFlowVisible = state => branch(state).isProFlowVisible
export const getIsProFlowPrimarySource = state => branch(state).isProFlowPrimarySource
export const getRespondentSources = state => branch(state).respondentSources
export const getActiveLanguage = state => branch(state).activeLanguage
export const getStudyTags = state => branch(state).tags
export const getHasLanguageChoice = state => branch(state).hasLanguageChoice

export const getImportedModules = state => branch(state).importedModules

export const getOrder = state => branch(state).order
export const getModules = state => branch(state).modules
export const getFlowDetailRerenderRegister = state => branch(state).flowDetailRerenderRegister

export const getEstimatedLOI = state => branch(state).estimatedLOI

export const getIsRelatedMessageShown = state => branch(state).isRelatedMessageShown

export const getIdScrollToTask = state => branch(state).idScrollToTask
export const getUpperModulesIds = state => branch(state).upperModulesIds
export const getCurrentModuleId = state => branch(state).currentModuleId
export const getStudyNoteModuleId = state => branch(state).displayedNoteModuleId
export const getDeletingModule = state => branch(state).deletingModule
export const getInvalidModules = state => _.get(branch(state), 'invalidModules', [])
export const getIsCopyToLibraryFormVisible = state => branch(state).isCopyToLibraryFormVisible
export const getIsCreateLibraryFromStudy = state => branch(state).isCreateLibraryFromStudy
export const getIsStudyCreate = state => branch(state).isStudyCreate
export const getCopyToLibraryFormData = state => branch(state).copyToLibraryFormData

export const getIsVisualFlowEmpty = state => branch(state).order.length < 2
export const getModuleById = (idModule, state) => branch(state).modules[idModule]
export const getFlowIsChanged = state => branch(state).isFlowChanged
export const getOpenedAddModulePath = state => branch(state).openedAddModulePath
export const getIsSaveLoading = state => branch(state).isSaveLoading

export const getIsStudySettingsDetailOpen = state => branch(state).isStudySettingsDetailOpen

export const getStudySettings = state => branch(state).studySettings
export const getLanguages = state => getStudySettings(state).languages

export const getIdsMediaToDelete = state => branch(state).idsMediaToDelete
export const getIdsMediaToCopy = state => branch(state).idsMediaToCopy

export const getLibrarySegmentsToBeAdded = state => branch(state).librarySegmentsToBeAdded

export const getFlatOrder = state => branch(state).flatOrder
export const getOrderModulesCount = state => getFlatOrder(state).length

export const getIsFlowInvalid = state => getInvalidModules(state).length > 0
export const getFlowErrorsCount = state => {
	const invalidModules = getInvalidModules(state)
	const studySettingsErrors = getStudySettingsErrors(state)

	return getNumberOfFlowErrors(invalidModules, studySettingsErrors)
}

export const getStudySettingsErrors = state => {
	const settings = getStudySettings(state)
	const languages = getLanguages(state)
	const enabledLanguages = getEnabledLanguages(languages)
	const validationResults = {}

	if (enabledLanguages.some(({ language }) => settings.publicLabel[language].length === 0)) {
		validationResults.publicLabel = { message: 'welcome_screen_settings.error.required_title' }
	}

	if (enabledLanguages.some(({ language }) => settings.studyDescription[language].length > 140)) {
		validationResults.publicLabel = {
			message: 'welcome_screen_settings.error.description_too_long',
		}
	}

	return validationResults
}

export const getCurrentModule = state => {
	const modules = getModules(state)
	return modules[getCurrentModuleId(state)]
}

export const getRemoveModuleIntent = event => {
	if (event) {
		event.stopPropagation()
		return removeModuleIntent(event.currentTarget.getAttribute('data-moduleid'))
	}

	return removeModuleIntent()
}

export const getRemoveModuleApprove = event => {
	event.stopPropagation()
	return removeModuleApprove(
		event.currentTarget.getAttribute('data-removetype'),
		event.currentTarget.getAttribute('data-idmodule'),
	)
}

export const getOpenAnswersCount = state => {
	const modules = getModules(state)

	return Object.keys(modules).filter(idModule => isOpenAnswerModule(modules[idModule])).length
}

export const getDatasetsCount = state => {
	const modules = getModules(state)

	return Object.keys(modules).filter(
		idModule =>
			modules[idModule].definition.isMatrixChoice !== true &&
			isDatasetModule(modules[idModule]) === true,
	).length
}

export const getActiveDatasetsCount = state => {
	const modules = getModules(state)

	return Object.keys(modules).filter(
		idModule =>
			modules[idModule].isHidden !== true &&
			modules[idModule].definition.isMatrixChoice !== true &&
			isDatasetModule(modules[idModule]) === true,
	).length
}

export const getQuotasCount = state => {
	const modules = getModules(state)

	return Object.keys(modules).filter(idModule => isQuotaModule(modules[idModule])).length
}

export const getIsModuleInvalid = (idModule, idRelatedMessage) => state => {
	const invalidModules = getInvalidModules(state)

	return invalidModules.some(
		invalidModule =>
			invalidModule.id === idModule ||
			(idRelatedMessage !== null &&
				idRelatedMessage !== undefined &&
				invalidModule.id === idRelatedMessage),
	)
}
