import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

export const getStudyObjectDefinition = studyObject => {
	if (studyObject.type === MODULE_DEFINITIONS.A_SNIPPET.type) {
		return {
			...studyObject.definition.parsedCode,
			id: studyObject.definition.id,
		}
	}

	return studyObject.definition
}
