import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import Input from 'components/_formik/_base/Input'
import CopyableString from 'components/_scaffolding/CopyableString'

import helpers from 'routes/_study/StudyDesign/_store/helpers'
import { getSnippetNextStepLabel } from 'helpers/visualFlowModules/snippet'

import classes from './NextStep.module.scss'

const NextStep = props => {
	const moduleId = props.nextStep.id
	const module = props.modules[moduleId]

	return (
		<div className={classes['nextStep-holder']}>
			<Field
				component={Input}
				disabled={props.disabled}
				name={`nextSteps[${props.index}].name`}
				type="text"
			/>
			<div className={classes['nextStep-info-holder']}>
				<div className={classes['caret-right']} />
				<div className={classes['nextStep-info']}>
					<div className={classes['nextStep-type'] + ' ' + (module ? '' : classes['invalid'])}>
						{module ? helpers.getModuleNameByType(module.type) : 'Not found'}:&nbsp;
					</div>
					<div className={classes['nextStep-label']}>
						{module ? getSnippetNextStepLabel(module, moduleId, props.activeLanguage) : null}
					</div>
				</div>
				<div className={classes['nextStep-id']}>
					<CopyableString stringToDisplay={moduleId} />
				</div>
			</div>
		</div>
	)
}

NextStep.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	index: PropTypes.number.isRequired,
	nextStep: PropTypes.object.isRequired,
	modules: PropTypes.object.isRequired,
}

export default NextStep
