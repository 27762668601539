const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	$id: 'http://groupsolver.com/v1/LIST.json',
	properties: {
		items: {
			additionalItems: false,
			$id: '/properties/items',
			items: {
				$id: '/properties/items/items',
				properties: {},
				required: [],
				type: 'object',
			},
			minItems: 0,
			type: 'array',
			uniqueItems: true,
		},
		isFilterEveryOf: {
			type: 'boolean',
		},
		filterRules: {
			type: 'array',
			minItems: 0,
			additionalItems: false,
			items: {
				type: 'object',
				oneOf: [
					{
						additionalProperties: false,
						required: ['ruleType', 'listKey', 'communityKey', 'is', 'function'],
						properties: {
							ruleType: {
								type: 'string',
								enum: ['COMMUNITY'],
							},
							listKey: {
								type: 'string',
							},
							communityKey: {
								type: 'string',
							},
							is: {
								type: 'boolean',
							},
							function: {
								type: 'string',
							},
							includeNoData: {
								type: 'boolean',
							},
						},
					},
					{
						additionalProperties: false,
						required: ['ruleType', 'listKey', 'idModule', 'is', 'function'],
						properties: {
							ruleType: {
								type: 'string',
								enum: ['QUESTION'],
							},
							listKey: {
								type: 'string',
							},
							idModule: {
								type: 'string',
							},
							is: {
								type: 'boolean',
							},
							function: {
								type: 'string',
							},
							includeNoData: {
								type: 'boolean',
							},
						},
					},
					{
						additionalProperties: false,
						required: ['ruleType', 'listKey', 'idModule', 'is', 'function', 'value'],
						properties: {
							ruleType: {
								type: 'string',
								enum: ['ALLOCATION_QUESTION', 'RANKING_QUESTION'],
							},
							listKey: {
								type: 'string',
							},
							idModule: {
								type: 'string',
							},
							is: {
								type: 'boolean',
							},
							function: {
								type: 'string',
								enum: [
									'MAX',
									'MIN',
									'numberEqualTo',
									'greaterThan',
									'lessThan',
									'greaterThanOrEqualTo',
									'lessThanOrEqualTo',
								],
							},
							includeNoData: {
								type: 'boolean',
							},
							value: {
								type: ['integer', 'string'],
							},
						},
					},
					{
						additionalProperties: false,
						required: ['ruleType', 'idMatrix', 'is', 'function', 'options'],
						properties: {
							ruleType: {
								type: 'string',
								enum: ['MATRIX'],
							},
							idMatrix: {
								type: 'string',
							},
							is: {
								type: 'boolean',
							},
							function: {
								type: 'string',
							},
							options: {
								type: 'array',
								items: {
									type: 'string',
								},
							},
							includeNoData: {
								type: 'boolean',
							},
						},
					},
					{
						additionalProperties: false,
						required: ['ruleType', 'listKey', 'is', 'function', 'value', 'valueArray'],
						properties: {
							ruleType: {
								type: 'string',
								enum: ['ITEM_ATTRIBUTE'],
							},
							listKey: {
								type: 'string',
							},
							is: {
								type: 'boolean',
							},
							function: {
								type: 'string',
							},
							value: {
								type: 'string',
							},
							valueArray: {
								type: 'array',
								items: {
									type: 'string',
								},
							},
							includeNoData: {
								type: 'boolean',
							},
						},
					},
				],
			},
		},
		columns: {
			type: 'array',
			minItems: 0,
			additionalItems: false,
			items: {
				additionalProperties: false,
				required: ['key', 'isUnique'],
				type: 'object',
				properties: {
					key: {
						type: 'string',
					},
					isUnique: {
						type: 'boolean',
					},
				},
			},
		},
		inputType: {
			type: 'string',
			enum: ['LIST', 'FILE'],
		},
		idInputList: {
			type: 'string',
		},
		identifier: {
			type: 'string',
		},
		optionLabelIdentifiers: {
			$id: '/properties/optionLabelIdentifiers',
			type: 'object',
			additionalProperties: false,
			patternProperties: {'^[a-z]{2}$': {type: 'string'}},
		},
		filename: {
			type: 'string',
		},
	},
	required: [
		'columns',
		'filename',
		'filterRules',
		'idInputList',
		'identifier',
		'inputType',
		'isFilterEveryOf',
		'items',
		'optionLabelIdentifiers',
	],
	type: 'object',
}

export default schema
