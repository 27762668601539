import validateDatasetMessages from './_validateDatasetMessages'
import validateRelatedMessage from './_validateRelatedMessage'

const validateFreetext = (module, modules, studyTags, languages) => {
	const validationResult = []

	validationResult.push(...validateDatasetMessages(module, studyTags, languages))
	validationResult.push(...validateRelatedMessage(module, modules, studyTags, languages))

	return validationResult
}

export default validateFreetext
