export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Devam et',
	allocation_option: 'Tahsis seçeneği',
	choice_option: 'Seçim seçeneği',
	submit: 'Gönder',
	freetext_date_placeholder: 'Bir tarih girin',
	freetext_date_inputHint: 'örn. aa/gg/yıl',
	freetext_phone_placeholder: 'Bir telefon numarası girin',
	freetext_prhone_inputHint: 'örn. 000-123-4567',
	freetext_email_placeholder: 'E-posta adresinizi girin',
	freetext_email_inputHint: 'örn. my@mail.com',
	freetext_zip_placeholder: 'Posta kodunuzu girin',
	freetext_zip_inputHint: 'örn. 12345',
	freetext_answer_placeholder: 'Cevabınızı girin',
	freetext_integer_inputHint: 'örn. 123',
	freetext_number_inputHint: 'örn. 1.5',
	freetext_amount_placeholder: 'Miktarı girin',
	freetext_amount_inputHint: 'örn. 1000.00',
	matrix_question: 'Matris sorusu',
	matrix_loop_question:
		'Her liste öğesi için benzersiz bir soru sunmak için soru metninde [[item]] ifadesini eklemeyi unutmayın',
	maxdiff_option: 'Öğe',
	maxdiff_best_question: 'Size göre, bunlardan hangisi **en** önemli özelliklerdir?',
	maxdiff_worst_question: 'Size göre, bunlardan hangisi **en az** önemli özelliklerdir?',
	new_message: 'Yeni mesaj',
	ranking_option: 'Sıralama seçeneği',
	question_default: 'Sorunuzu yazın',
	none_of_these: 'Bunların hiçbiri',
	study_languages_language_choice_message: 'Tercih ettiğiniz dil nedir?',
	ar: 'Arapça',
	zh: 'Çince',
	da: 'Danca',
	nl: 'Flemenkçe',
	en: 'İngilizce',
	fr: 'Fransızca',
	de: 'Almanca',
	ha: 'Hausa',
	hi: 'Hintçe',
	ig: 'İgbo',
	id: 'Endonezyaca',
	it: 'İtalyanca',
	ja: 'Japonca',
	ko: 'Korece',
	pl: 'Lehçe',
	pt: 'Portekizce',
	ro: 'Romence',
	ru: 'Rusça',
	sk: 'Slovakça',
	es: 'İspanyolca',
	sv: 'İsveççe',
	tl: 'Tagalog',
	th: 'Tayca',
	tr: 'Türkçe',
	yo: 'Yoruba',
}
