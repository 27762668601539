import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import setUniqueIdsInDefinition from 'routes/_study/StudyDesign/_store/helpers/setUniqueIdsInDefinition'
import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'

import { matrixQuestionToChoice } from 'helpers/visualFlowModules/matrixQuestionToChoice'

const getDefinition = (modules, newModuleId, definition, moduleType, additionalProperties) => {
	if (definition === null) {
		// generate new module definition
		return {
			...MODULE_DEFINITIONS[moduleType].generator(modules, additionalProperties),
			id: newModuleId,
		}
	}

	// we're copying module
	const copiedDefinition = {
		...setUniqueIdsInDefinition(definition),
		id: newModuleId,
	}

	// generate new shortname if the original module has one
	if (definition.shortName !== undefined) {
		copiedDefinition.shortName = getDatasetShortName(
			modules,
			definition.shortName,
			MODULE_DEFINITIONS[moduleType].type,
			definition.subtype,
		)
	}

	// reset matrix questions filter that points to attributes of different matrix question
	if (definition.moduleFilter !== undefined) {
		copiedDefinition.moduleFilter = null
	}

	// handle related message in case we're copying old module
	// TODO
	// this should not be needed if we transform all existing data
	const idRelatedMessage = definition.idRelatedMessage
	const relatedMessage = modules[idRelatedMessage] ?? null

	if (relatedMessage !== null) {
		copiedDefinition.idRelatedMessage = null
		copiedDefinition.messages = [
			{
				type: MODULE_DEFINITIONS.A_MESSAGE.type,
				definition: {
					..._.cloneDeep(setUniqueIdsInDefinition(relatedMessage.definition)),
					id: uuidv4(),
				},
			},
		]
	}

	return copiedDefinition
}

const addToModules = (modules, newModuleProps) => {
	const updatedModules = { ...modules }
	const { newModuleId, definition, moduleType, additionalProperties } = newModuleProps

	// prepare new module
	const newModule = {
		type: MODULE_DEFINITIONS[moduleType].type,
		definition: getDefinition(modules, newModuleId, definition, moduleType, additionalProperties),
		isUnsaved: true,
		isHidden: false,
		isPreviewOnly: _.get(additionalProperties, 'isPreviewOnly', false),
	}

	// add chocices that will be generated by matrix to modules so they can be accessed
	// fast in condition builder and conditional container description
	if (
		newModule.type === MODULE_DEFINITIONS.MATRIX_CHOICE.type ||
		newModule.type === MODULE_DEFINITIONS.MATRIX_IMAGE_CHOICE.type
	) {
		newModule.definition.questions.forEach(question => {
			updatedModules[question.id] = matrixQuestionToChoice(
				newModule,
				question,
				additionalProperties.languages,
			)
			updatedModules[question.id].isUnsaved = true
		})
	}

	// add duplicate or new module to modules
	updatedModules[newModuleId] = newModule

	return updatedModules
}

export default addToModules
