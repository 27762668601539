export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Lanjutkan',
	allocation_option: 'Opsi alokasi',
	choice_option: 'Opsi pilihan',
	submit: 'Kirim',
	freetext_date_placeholder: 'Masukkan tanggal',
	freetext_date_inputHint: 'mis. dd/bb/tahun',
	freetext_phone_placeholder: 'Masukkan nomor telepon',
	freetext_prhone_inputHint: 'mis. 000-123-4567',
	freetext_email_placeholder: 'Masukkan email Anda',
	freetext_email_inputHint: 'mis. saya@mail.com',
	freetext_zip_placeholder: 'Masukkan kode pos',
	freetext_zip_inputHint: 'mis. 12345',
	freetext_answer_placeholder: 'Masukkan jawaban Anda',
	freetext_integer_inputHint: 'mis. 123',
	freetext_number_inputHint: 'mis. 1.5',
	freetext_amount_placeholder: 'Masukkan jumlah',
	freetext_amount_inputHint: 'mis. 1000.00',
	matrix_question: 'Pertanyaan matriks',
	matrix_loop_question:
		'Pastikan untuk menyertakan [[item]] dalam teks pertanyaan untuk menyajikan pertanyaan unik untuk setiap item daftar',
	maxdiff_option: 'Item',
	maxdiff_best_question:
		'Menurut Anda, mana di antara ini yang merupakan atribut **paling** penting?',
	maxdiff_worst_question:
		'Menurut Anda, mana di antara ini yang merupakan atribut **paling tidak** penting?',
	new_message: 'Pesan baru',
	ranking_option: 'Opsi peringkat',
	question_default: 'Ketikkan pertanyaan Anda',
	none_of_these: 'Tidak ada di antara ini',
	study_languages_language_choice_message: 'Apa bahasa pilihan Anda?',
	ar: 'Arab',
	zh: 'Tionghoa',
	da: 'Denmark',
	nl: 'Belanda',
	en: 'Inggris',
	fr: 'Perancis',
	de: 'Jerman',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonesia',
	it: 'Italia',
	ja: 'Jepang',
	ko: 'Korea',
	pl: 'Polandia',
	pt: 'Portugis',
	ro: 'Rumania',
	ru: 'Rusia',
	sk: 'Slovakia',
	es: 'Spanyol',
	sv: 'Swedia',
	tl: 'Tagalog',
	th: 'Thailand',
	tr: 'Turki',
	yo: 'Yoruba',
}
