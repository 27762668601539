import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'

import { BUCKET_NAMES } from 'constants/mediaUpload'
import { studyLanguagesShape } from 'constants/languages/studyLanguagesShape'
import { translations } from 'languages'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import Label from 'components/_formik/_base/Label'
import ImageTextArea from 'components/_formik/_complex/ImageTextArea'
import MessageModuleOptions from 'components/_formik/_complex/StudyDesign/MessageModuleOptions'

import LoopItemReferencingTooltip from 'components/_scaffolding/LoopItemReferencingTooltip'

import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import { getFieldName } from 'helpers/formik/getFieldName'

const getTextLabelTranslation = definition =>
	definition.idRelatedAnswer === null ? 'message.title' : 'question'

const getPlaceholderTranslation = definition =>
	definition.idRelatedAnswer === null ? 'message.placeholder' : 'message.question.default'

const getDefaultTextTranslation = definition =>
	definition.idRelatedAnswer === null ? 'new_message' : 'question_default'

const Message = props => {
	const intl = useIntl()

	const namePrefix = props.namePrefix ?? ''

	const { activeLanguage } = props
	const module = props.modules[props.initialValues.id]
	const isInLoop = module.path.includes('loop')

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ values, setFieldValue }) => (
				<Fragment>
					<Header
						activeLanguage={activeLanguage}
						closeModuleDetail={props.closeModuleDetail}
						copyModule={props.copyModule}
						copyModuleButtonParams={props.copyModuleButtonParams}
						disabled={props.disabled === true}
						generalDefinition={props.generalDefinition}
						isFlowChanged={props.isFlowChanged}
						isPreviewOnly={props.isPreviewOnly}
						isRelatedModuleInvalid={props.isRelatedModuleInvalid}
						knowledgeBaseLinkTypeOverride={props.knowledgeBaseLinkTypeOverride}
						languages={props.languages}
						languagesValidationResult={props.languagesValidationResult}
						moduleDefinition={values}
						modules={props.modules}
						openCopyToLibraryForm={props.openCopyToLibraryForm}
						setActiveLanguage={props.setActiveLanguage}
						showCopyToLibrary
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={() => (
								<Form>
									<Label label={intl.formatMessage({ id: getTextLabelTranslation(values) })} />
									<ImageTextArea
										activeLanguage={props.activeLanguage}
										bucketName={BUCKET_NAMES.PROJECT}
										defaultText={translations[activeLanguage][getDefaultTextTranslation(values)]}
										disabled={props.disabled}
										formProps={{
											setFieldValue,
											values,
										}}
										imageUploaderName={getFieldName(namePrefix, 'media')}
										placeholder={intl.formatMessage({ id: getPlaceholderTranslation(values) })}
										textareaName={getFieldName(namePrefix, `text.${activeLanguage}`)}
										openMediaManager={props.openMediaManager}
									/>
									{isInLoop === true && <LoopItemReferencingTooltip />}
									<MessageModuleOptions
										activeLanguage={props.activeLanguage}
										disabled={props.disabled}
										isAccessibilityEnabled={props.study.tags.isAccessibilityEnabled}
										languages={props.languages}
										namePrefix={namePrefix}
										formProps={{
											setFieldValue,
											values,
										}}
									/>
								</Form>
							)}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

Message.defaultProps = {
	knowledgeBaseLinkTypeOverride: null,
}

Message.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	closeModuleDetail: PropTypes.func.isRequired,
	copyModule: PropTypes.func.isRequired,
	copyModuleButtonParams: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isPreviewOnly: PropTypes.bool.isRequired,
	isRelatedModuleInvalid: PropTypes.bool.isRequired,
	knowledgeBaseLinkTypeOverride: PropTypes.string,
	languages: studyLanguagesShape.isRequired,
	languagesValidationResult: PropTypes.object.isRequired,
	modules: PropTypes.object.isRequired,
	namePrefix: PropTypes.string,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	openMediaManager: PropTypes.func.isRequired,
	saveModule: PropTypes.func.isRequired,
	setActiveLanguage: PropTypes.func.isRequired,
	study: PropTypes.object.isRequired,
}

export default Message
