import _ from 'lodash'

import { MAXDIFF_ROUND_TYPE_OPTIONS, REPORT_BLOB_TYPES } from 'constants/reports'
import { tools } from 'constants/tools'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { getOptionLabel } from 'helpers/reports/getOptionLabel'
import { assignOptionColors } from 'helpers/reports/assignOptionColors'

import {
	getSegmentTotalSupportCompletesKey,
	getSegmentTotalSupportDecimalPercentageKey,
	getSegmentTotalSupportPercentageKey,
} from 'store/reportBuilder'

/**
 * @param {string} idOption
 * @param {string} segmentData
 * @returns {Object.<string, unknown>} Option object
 */
const getSegmentOption = (idOption, segmentData) =>
	segmentData.answers.find(option => option.idOption === idOption)

/**
 * @param {Object.<string, unknown>} slideSettings
 * @param {Object.<string, Object.<string, unknown>>} blobData
 * @param {Object.<string, unknown>} datasetOptions
 * @returns {Object.<string, unknown>[]}
 */
export const calculateLegendIdeas = (
	slideSettings,
	blobData,
	datasetOptions,
	chartColorSettings,
	language,
) => {
	if (slideSettings === undefined) {
		return []
	}

	const { idStudy, idStudyObject, idsSegments, excludedAnswers } = slideSettings

	const totalSegmentDataToCheck =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.MAXDIFF_STATISTICS,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	const dataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.MAXDIFF_STATISTICS, idSegment)
			],
	)

	if (hasBlobData([...dataArray, totalSegmentDataToCheck], 'answers') === false) {
		return []
	}

	const totalSegmentData = assignOptionColors(
		totalSegmentDataToCheck,
		chartColorSettings,
		'answers',
	)

	const dataset = datasetOptions.find(selector => selector.value === idStudyObject)

	const options = dataset.module.options
		.map(option => {
			const totalSegmentOption = getSegmentOption(option.id, totalSegmentData)

			const customColor = _.get(slideSettings, `customColors.${option.id}`, null)
			const color = customColor ?? totalSegmentOption.color

			const chartOption = {
				code: option.code,
				color,
				fill: color,
				hasCustomColor: customColor !== null,
				id: option.id,
				idOption: option.id,
				label: getOptionLabel(option, slideSettings, language),
				letter: totalSegmentOption.letter,
				name: getOptionLabel(option, slideSettings, language),
				shown: true,
				themeName: null,
				unit: '%',
			}

			const percentAndCompletes = idsSegments.map(idSegment => {
				const segmentData =
					blobData[
						getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.MAXDIFF_STATISTICS, idSegment)
					]
				const segmentOption = getSegmentOption(option.id, segmentData)

				if (slideSettings.roundType === MAXDIFF_ROUND_TYPE_OPTIONS.DIFF.VALUE) {
					const bestCompletes =
						segmentOption[MAXDIFF_ROUND_TYPE_OPTIONS.BEST.ANSWER_KEY].respondentsCount
					const worstCompletes =
						segmentOption[MAXDIFF_ROUND_TYPE_OPTIONS.WORST.ANSWER_KEY].respondentsCount
					const respondentsCount = Math.ceil((bestCompletes + worstCompletes) / 2)

					const bestPercentage =
						segmentOption[MAXDIFF_ROUND_TYPE_OPTIONS.BEST.ANSWER_KEY].percentage
					const worstPercentage =
						segmentOption[MAXDIFF_ROUND_TYPE_OPTIONS.WORST.ANSWER_KEY].percentage
					const decimalPercentage = bestPercentage - worstPercentage
					const percentage = _.round(decimalPercentage * 100, 4)

					chartOption[getSegmentTotalSupportCompletesKey(idSegment)] = respondentsCount
					chartOption[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = decimalPercentage
					chartOption[getSegmentTotalSupportPercentageKey(idSegment)] = percentage

					chartOption.maxDiffValue = percentage

					return [percentage, respondentsCount]
				}

				const answerKey = MAXDIFF_ROUND_TYPE_OPTIONS[slideSettings.roundType].ANSWER_KEY

				const respondentsCount = segmentOption[answerKey].respondentsCount
				const decimalPercentage = segmentOption[answerKey].percentage
				const percentage = _.round(segmentOption[answerKey].percentage * 100, 4)

				chartOption[getSegmentTotalSupportCompletesKey(idSegment)] = respondentsCount
				chartOption[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = decimalPercentage
				chartOption[getSegmentTotalSupportPercentageKey(idSegment)] = percentage

				return [percentage, respondentsCount]
			})

			chartOption.toExport = () => [
				chartOption.letter,
				chartOption.label,
				...percentAndCompletes.flat(),
				chartOption.code,
			]

			return chartOption
		})
		.filter(option => option !== null && excludedAnswers.includes(option.idOption) === false)

	return options
}
