export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Magpatuloy',
	allocation_option: 'Opsyon sa alokasyon',
	choice_option: 'Opsyon sa pagpili',
	submit: 'Isumite',
	freetext_date_placeholder: 'Ilagay ang petsa',
	freetext_date_inputHint: 'hal. mm/dd/taon',
	freetext_phone_placeholder: 'Ilagay ang numero ng telepono',
	freetext_prhone_inputHint: 'hal. 000-123-4567',
	freetext_email_placeholder: 'Ilagay ang iyong email',
	freetext_email_inputHint: 'hal. my@mail.com',
	freetext_zip_placeholder: 'Ilagay ang iyong ZIP code',
	freetext_zip_inputHint: 'hal. 12345',
	freetext_answer_placeholder: 'Ilagay ang iyong sagot',
	freetext_integer_inputHint: 'hal. 123',
	freetext_number_inputHint: 'hal. 1.5',
	freetext_amount_placeholder: 'Ilagay ang halaga',
	freetext_amount_inputHint: 'hal. 1000.00',
	matrix_question: 'Tanong sa matrix',
	matrix_loop_question:
		'Tiyaking isama ang [[item]] sa teksto ng tanong upang ipakita ang natatanging tanong para sa bawat item sa listahan',
	maxdiff_option: 'Item',
	maxdiff_best_question: 'Sa iyong opinyon, alin sa mga ito ang **pinaka** mahalagang katangian?',
	maxdiff_worst_question:
		'Sa iyong opinyon, alin sa mga ito ang **pinaka** hindi mahalagang katangian?',
	new_message: 'Bagong mensahe',
	ranking_option: 'Opsyon sa pagraranggo',
	question_default: 'I-type ang iyong tanong',
	none_of_these: 'Wala sa mga ito',
	study_languages_language_choice_message: 'Ano ang iyong paboritong wika?',
	ar: 'Arabe',
	zh: 'Tsino',
	da: 'Danish',
	nl: 'Olandes',
	en: 'Ingles',
	fr: 'Pranses',
	de: 'Alemán',
	ha: 'Hausa',
	hi: 'Hindy',
	ig: 'Igbo',
	id: 'Indonesiano',
	it: 'Italyano',
	ja: 'Hapones',
	ko: 'Koreano',
	pl: 'Polako',
	pt: 'Portuguese',
	ro: 'Rumen',
	ru: 'Ruso',
	sk: 'Slovak',
	es: 'Espanyol',
	sv: 'Suwedo',
	tl: 'Tagalog',
	th: 'Thai',
	tr: 'Turko',
	yo: 'Yoruba',
}
