export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Fortsæt',
	allocation_option: 'Allokeringsmulighed',
	choice_option: 'Valgmulighed',
	submit: 'Indsend',
	freetext_date_placeholder: 'Indtast en dato',
	freetext_date_inputHint: 'f.eks. dd/mm/år',
	freetext_phone_placeholder: 'Indtast et telefonnummer',
	freetext_prhone_inputHint: 'f.eks. 000-123-4567',
	freetext_email_placeholder: 'Indtast din e-mail',
	freetext_email_inputHint: 'f.eks. min@mail.com',
	freetext_zip_placeholder: 'Indtast dit postnummer',
	freetext_zip_inputHint: 'f.eks. 12345',
	freetext_answer_placeholder: 'Indtast dit svar',
	freetext_integer_inputHint: 'f.eks. 123',
	freetext_number_inputHint: 'f.eks. 1,5',
	freetext_amount_placeholder: 'Indtast beløb',
	freetext_amount_inputHint: 'f.eks. 1000,00',
	matrix_question: 'Matrixspørgsmål',
	matrix_loop_question:
		'Sørg for at inkludere [[item]] i spørgsmålet for at præsentere unikt spørgsmål for hver listepost',
	maxdiff_option: 'Emne',
	maxdiff_best_question: 'Efter din mening, hvilke af disse er de **vigtigste** attributter?',
	maxdiff_worst_question: 'Efter din mening, hvilke af disse er de **mindst** vigtige attributter?',
	new_message: 'Ny besked',
	ranking_option: 'Rangeringsmulighed',
	question_default: 'Skriv dit spørgsmål',
	none_of_these: 'Ingen af disse',
	study_languages_language_choice_message: 'Hvad er dit foretrukne sprog?',
	ar: 'Arabisk',
	zh: 'Kinesisk',
	da: 'Dansk',
	nl: 'Hollandsk',
	en: 'Engelsk',
	fr: 'Fransk',
	de: 'Tysk',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonesisk',
	it: 'Italiensk',
	ja: 'Japansk',
	ko: 'Koreansk',
	pl: 'Polsk',
	pt: 'Portugisisk',
	ro: 'Rumænsk',
	ru: 'Russisk',
	sk: 'Slovakisk',
	es: 'Spansk',
	sv: 'Svensk',
	tl: 'Tagalog',
	th: 'Thai',
	tr: 'Tyrkisk',
	yo: 'Yoruba',
}
