import PropTypes from 'prop-types'

export const studyLanguagesShape = PropTypes.arrayOf(
	PropTypes.exact({
		language: PropTypes.string.isRequired,
		isEnabled: PropTypes.bool.isRequired,
		isSolverDefault: PropTypes.bool.isRequired,
		isReportDefault: PropTypes.bool.isRequired,
	}),
)
