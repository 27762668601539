import { FREE_TEXT_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/FREE_TEXT_TYPES'

const defaultTextSettings = {
	validationPattern: '.*',
	placeholder: 'Enter your answer',
	placeholderTranslation: 'freetext_answer_placeholder',
	inputHint: '',
	inputHintTranslation: '',
	title: 'freetext.text.title',
	description: 'freetext.text.description',
	datasetShortName: 'Text',
	freetextType: FREE_TEXT_TYPES.TEXT,
}

export const getFreetextSettings = freetextType => {
	switch (freetextType) {
		case FREE_TEXT_TYPES.TEXT:
			return defaultTextSettings
		case FREE_TEXT_TYPES.DATE:
			return {
				validationPattern: '^(0?[1-9]|1[0-2])\\/(0?[1-9]|[1,2]\\d|3[0,1])\\/\\d{4}$',
				placeholder: 'Enter a date',
				placeholderTranslation: 'freetext_date_placeholder',
				inputHint: 'e.g. mm/dd/year',
				inputHintTranslation: 'freetext_date_inputHint',
				title: 'freetext.date.title',
				description: 'freetext.date.description',
				datasetShortName: 'US Date',
				freetextType,
			}
		case FREE_TEXT_TYPES.PHONE:
			return {
				validationPattern: '^(\\([0-9]{3}\\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$',
				placeholder: 'Enter a phone number',
				placeholderTranslation: 'freetext_phone_placeholder',
				inputHint: 'e.g. 000-123-4567',
				inputHintTranslation: 'freetext_prhone_inputHint',
				title: 'freetext.phone.title',
				description: 'freetext.phone.description',
				datasetShortName: 'US Phone',
				freetextType,
			}
		case FREE_TEXT_TYPES.EMAIL:
			return {
				validationPattern: '\\S{1,}@\\S{1,}\\.\\S{2,}',
				placeholder: 'Enter your email',
				placeholderTranslation: 'freetext_email_placeholder',
				inputHint: 'e.g. my@mail.com',
				inputHintTranslation: 'freetext_email_inputHint',
				title: 'freetext.email.title',
				description: 'freetext.email.description',
				datasetShortName: 'Email',
				freetextType,
			}
		case FREE_TEXT_TYPES.ZIP:
			return {
				validationPattern: '^\\d{5}(-\\d{4})?$',
				placeholder: 'Enter your ZIP code',
				placeholderTranslation: 'freetext_zip_placeholder',
				inputHint: 'e.g. 12345',
				inputHintTranslation: 'freetext_zip_inputHint',
				title: 'freetext.zip.title',
				description: 'freetext.zip.description',
				datasetShortName: 'ZIP code',
				freetextType,
			}
		case FREE_TEXT_TYPES.INTEGER:
			return {
				validationPattern: '^-?\\d+$',
				placeholder: 'Enter your answer',
				placeholderTranslation: 'freetext_answer_placeholder',
				inputHint: 'e.g. 123',
				inputHintTranslation: 'freetext_integer_inputHint',
				title: 'freetext.integer.title',
				description: 'freetext.integer.description',
				datasetShortName: 'Integer',
				freetextType,
			}
		case FREE_TEXT_TYPES.NUMBER:
			return {
				validationPattern: '^-?\\d+((\\.|\\,)\\d+)?$',
				placeholder: 'Enter your answer',
				placeholderTranslation: 'freetext_answer_placeholder',
				inputHint: 'e.g. 1.5',
				inputHintTranslation: 'freetext_number_inputHint',
				title: 'freetext.number.title',
				description: 'freetext.number.description',
				datasetShortName: 'Number',
				freetextType,
			}
		case FREE_TEXT_TYPES.AMOUNT:
			return {
				validationPattern: '^-?\\d+((\\.|\\,)\\d{1,2})?$',
				placeholder: 'Enter amount',
				placeholderTranslation: 'freetext_amount_placeholder',
				inputHint: 'e.g. 1000.00',
				inputHintTranslation: 'freetext_amount_inputHint',
				title: 'freetext.amount.title',
				description: 'freetext.amount.description',
				datasetShortName: 'Amount',
				freetextType,
			}
		default:
			return defaultTextSettings
	}
}
