export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'المتابعة',
	allocation_option: 'خيار التوزيع',
	choice_option: 'خيار الاختيار',
	submit: 'إرسال',
	freetext_date_placeholder: 'أدخل التاريخ',
	freetext_date_inputHint: 'مثلًا: يوم/شهر/سنة',
	freetext_phone_placeholder: 'أدخل رقم الهاتف',
	freetext_prhone_inputHint: 'مثلًا: 000-123-4567',
	freetext_email_placeholder: 'أدخل بريدك الإلكتروني',
	freetext_email_inputHint: 'مثلًا: my@mail.com',
	freetext_zip_placeholder: 'أدخل الرمز البريدي',
	freetext_zip_inputHint: 'مثلًا: 12345',
	freetext_answer_placeholder: 'أدخل إجابتك',
	freetext_integer_inputHint: 'مثلًا: 123',
	freetext_number_inputHint: 'مثلًا: 1.5',
	freetext_amount_placeholder: 'أدخل المبلغ',
	freetext_amount_inputHint: 'مثلًا: 1000.00',
	matrix_question: 'سؤال المصفوفة',
	matrix_loop_question: 'تأكد من تضمين [[item]] في نص السؤال لتقديم سؤال فريد لكل عنصر في القائمة',
	maxdiff_option: 'عنصر',
	maxdiff_best_question: 'برأيك، ما هي أهم الخصائص **الأكثر** أهمية؟',
	maxdiff_worst_question: 'برأيك، ما هي أقل الخصائص **أهمية**؟',
	new_message: 'رسالة جديدة',
	ranking_option: 'خيار الترتيب',
	question_default: 'اكتب سؤالك',
	none_of_these: 'لا شيء من هؤلاء',
	study_languages_language_choice_message: 'ما هي لغتك المفضلة؟',
	ar: 'العربية',
	zh: 'الصينية',
	da: 'الدانماركية',
	nl: 'الهولندية',
	en: 'الإنجليزية',
	fr: 'الفرنسية',
	de: 'الألمانية',
	ha: 'الهوسا',
	hi: 'الهندية',
	ig: 'الإيغبو',
	id: 'الإندونيسية',
	it: 'الإيطالية',
	ja: 'اليابانية',
	ko: 'الكورية',
	pl: 'البولندية',
	pt: 'البرتغالية',
	ro: 'الرومانية',
	ru: 'الروسية',
	sk: 'السلوفاكية',
	es: 'الإسبانية',
	sv: 'السويدية',
	tl: 'التاغالوغ',
	th: 'التايلاندية',
	tr: 'التركية',
	yo: 'اليوروبا',
}
