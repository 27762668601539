const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	$id: 'http://groupsolver.com/v1/END_STUDY.json',
	properties: {
		enableRedirectButton: {
			$id: '/properties/enableRedirectButton',
			type: 'boolean',
		},
		redirectLabel: {
			$id: '/properties/redirectLabel',
			type: 'object',
			additionalProperties: false,
			patternProperties: {
				'^[a-z]{2}$': {type: 'string'},
			},
		},
		redirectUrl: {
			$id: '/properties/redirectUrl',
			type: ['string', 'null'],
			pattern:
				'^!forceValue!.*|(https?://)?(www\\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\\.{1}[a-z0-9]{2,}(/.*)?(\\?.*)?$',
		},
		subtype: {
			$id: '/properties/subtype',
			type: 'string',
			enum: ['COMPLETE', 'OVERQUOTA', 'TERMINATE', 'QUALITY_TERMINATE'],
		},
	},
	required: ['enableRedirectButton', 'redirectUrl', 'subtype', 'redirectLabel'],
	type: 'object',
}

export default schema
