export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Continuar',
	allocation_option: 'Opción de asignación',
	choice_option: 'Opción de elección',
	submit: 'Enviar',
	freetext_date_placeholder: 'Ingrese una fecha',
	freetext_date_inputHint: 'p. ej. mm/dd/año',
	freetext_phone_placeholder: 'Ingrese un número de teléfono',
	freetext_prhone_inputHint: 'p. ej. 000-123-4567',
	freetext_email_placeholder: 'Ingrese su correo electrónico',
	freetext_email_inputHint: 'p. ej. mi@correo.com',
	freetext_zip_placeholder: 'Ingrese su código postal',
	freetext_zip_inputHint: 'p. ej. 12345',
	freetext_answer_placeholder: 'Ingrese su respuesta',
	freetext_integer_inputHint: 'p. ej. 123',
	freetext_number_inputHint: 'p. ej. 1.5',
	freetext_amount_placeholder: 'Ingrese el monto',
	freetext_amount_inputHint: 'p. ej. 1000.00',
	matrix_question: 'Pregunta de matriz',
	matrix_loop_question:
		'Asegúrese de incluir [[item]] en el texto de la pregunta para presentar una pregunta única para cada elemento de la lista',
	maxdiff_option: 'Ítem',
	maxdiff_best_question: 'En su opinión, ¿cuáles de estos son los atributos **más** importantes?',
	maxdiff_worst_question:
		'En su opinión, ¿cuáles de estos son los atributos **menos** importantes?',
	new_message: 'Nuevo mensaje',
	ranking_option: 'Opción de clasificación',
	question_default: 'Escribe tu pregunta',
	none_of_these: 'Ninguno de estos',
	study_languages_language_choice_message: '¿Cuál es tu idioma preferido?',
	ar: 'Árabe',
	zh: 'Chino',
	da: 'Danés',
	nl: 'Holandés',
	en: 'Inglés',
	fr: 'Francés',
	de: 'Alemán',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonesio',
	it: 'Italiano',
	ja: 'Japonés',
	ko: 'Coreano',
	pl: 'Polaco',
	pt: 'Portugués',
	ro: 'Rumano',
	ru: 'Ruso',
	sk: 'Eslovaco',
	es: 'Español',
	sv: 'Sueco',
	tl: 'Tagalo',
	th: 'Tailandés',
	tr: 'Turco',
	yo: 'Yoruba',
}
