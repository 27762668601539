import { enTranslator as intl } from 'intl.js'

import {
	CHOICE_SUBTYPE,
	VISUAL_FLOW_MODULE_TYPES,
	CHOICE_OPTION_ORDER_MODE,
} from 'constants/studyDesign'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { CHOICE_OPTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/CHOICE_OPTION'
import { GET_QUESTION_MESSAGE } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/MESSAGE'
import { getDefaultOptionLabel } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getDefaultOptionLabel'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'

import Choice from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Choice'

const getShortName = (subtype, modules, additionalProperties) => {
	if (additionalProperties.isLanguageChoice === true) {
		return intl.formatMessage({ id: 'language' })
	}

	const title =
		subtype === CHOICE_SUBTYPE.IMAGE
			? intl.formatMessage({ id: 'imageChoice.title' })
			: intl.formatMessage({ id: 'choice.title' })
	return getDatasetShortName(modules, title, VISUAL_FLOW_MODULE_TYPES.A_CHOICE, subtype)
}

export const getDefaultDynamicOptionsSettings = languages => ({
	isActive: false,
	idMainList: null,
	idInputList: null,
	optionLabelColumn: getLanguagesLabelsObject(languages, null),
	optionsOrderMode: CHOICE_OPTION_ORDER_MODE.QUESTION_ORDER,
})

const getOptions = (subtype, additionalProperties) => {
	if (additionalProperties.isLanguageChoice === true) {
		return additionalProperties.languages.map(({ language }, languageIndex) =>
			CHOICE_OPTION(
				subtype,
				false,
				languageIndex + 1,
				getDefaultOptionLabel(language, additionalProperties.languages, false),
				false,
				additionalProperties.languages,
				null,
				language,
			),
		)
	}

	return [
		CHOICE_OPTION(
			subtype,
			false,
			1,
			getDefaultOptionLabel('choice_option', additionalProperties.languages, true, 0),
			false,
			additionalProperties.languages,
			null,
			null,
		),
		CHOICE_OPTION(
			subtype,
			false,
			2,
			getDefaultOptionLabel('choice_option', additionalProperties.languages, true, 1),
			false,
			additionalProperties.languages,
			null,
			null,
		),
	]
}

const CHOICE_DEFINITION = (subtype, modules, additionalProperties) => ({
	options: getOptions(subtype, additionalProperties),
	idRelatedMessage: null,
	shortName: getShortName(subtype, modules, additionalProperties),
	hasCenteredOptions: false,
	isLanguageChoice: additionalProperties.isLanguageChoice ?? false,
	filter: null,
	subtype,
	mandatory: true,
	multiple: false,
	minSelection: 1,
	maxSelection: 1,
	trapQuestionSettings: {
		isTrapQuestion: false,
		selection: 'equalTo',
		correctAnswers: [],
	},
	messages: [
		GET_QUESTION_MESSAGE({
			...additionalProperties,
			idTextTranslation:
				additionalProperties.isLanguageChoice === true
					? 'study_languages_language_choice_message'
					: 'question_default',
		}),
	],
	dynamicOptionsSettings: getDefaultDynamicOptionsSettings(additionalProperties.languages),
	optionsShownLimit: null,
})

const getTitle = definition => {
	if (definition.isLanguageChoice === true) {
		return 'study_languages_language_choice'
	}

	return definition.subtype === CHOICE_SUBTYPE.IMAGE ? 'imageChoice.title' : 'choice.title'
}

const getDescription = definition => {
	if (definition.isLanguageChoice === true) {
		return 'study_languages_language_choice_description'
	}

	return definition.subtype === CHOICE_SUBTYPE.IMAGE
		? 'imageChoice.dataset.description'
		: 'choice.dataset.description'
}

export default subtype => ({
	type: VISUAL_FLOW_MODULE_TYPES.A_CHOICE, // MODULE_DEFINITIONS.A_CHOICE.type,
	style: subtype === CHOICE_SUBTYPE.CHECKBOX ? 'choice' : 'image-choice',
	title: getTitle,
	description: getDescription,
	component: Choice,
	generator: (modules, additionalProperties) =>
		CHOICE_DEFINITION(subtype, modules, additionalProperties),
})
