import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'

import { SELECT_MENU_POSITION } from 'constants/select'

import classes from './SelectDropdownWrapper.module.scss'

const SelectDropdownWrapper = ({
	ControlComponent,
	dataTestId,
	MenuComponent,
	controlProps,
	isDisabled,
	menuClassName,
	menuProps,
	position,
}) => {
	const menu = useRef(null)
	const control = useRef(null)

	const [isOpen, setIsOpen] = useState(false)

	const close = () => setIsOpen(false)

	useEffect(() => {
		const handleClick = event => {
			if (control.current === null) {
				return
			}

			if (isDisabled === true) {
				return
			}

			if (control.current.contains(event.target) === true) {
				setIsOpen(current => current === false)

				return
			}

			if (isOpen === false) {
				return
			}

			if (menu.current === null) {
				return
			}

			if (menu.current.contains(event.target) === true) {
				return
			}

			if (document.contains(event.target) === false) {
				// element no longer present, e.g. select option
				return
			}

			setIsOpen(false)
		}

		window.addEventListener('click', handleClick)

		return () => window.removeEventListener('click', handleClick)
	}, [isDisabled, isOpen])

	return (
		<div className={classnames({ [classes.active]: isOpen === true })}>
			<div className={classes.wrapper} data-testid={dataTestId}>
				<div ref={control}>
					<ControlComponent {...controlProps} isDisabled={isDisabled} isOpen={isOpen} />
				</div>
				{isOpen === true && (
					<div
						className={classnames(classes.menu, {
							[classes['menu--left']]: position === SELECT_MENU_POSITION.LEFT,
							[classes['menu--right']]: position === SELECT_MENU_POSITION.RIGHT,
							[classes['menu--top']]: position === SELECT_MENU_POSITION.TOP,
							[classes['menu--top-centered']]: position === SELECT_MENU_POSITION.TOP_CENTERED,
							[classes['menu--top-left']]: position === SELECT_MENU_POSITION.TOP_LEFT,
							[classes['menu--bottom-left']]: position === SELECT_MENU_POSITION.BOTTOM_LEFT,
							[classes['menu--bottom-center']]: position === SELECT_MENU_POSITION.BOTTOM_CENTER,
							[classes['menu--bottom-right']]: position === SELECT_MENU_POSITION.BOTTOM_RIGHT,
							[classes['menu--center']]: position === SELECT_MENU_POSITION.CENTER,
							[classes['menu--left-centered']]: position === SELECT_MENU_POSITION.LEFT_CENTERED,
							[menuClassName]: menuClassName !== null,
						})}
						ref={menu}
					>
						<MenuComponent close={close} isDisabled={isDisabled} {...menuProps} />
					</div>
				)}
			</div>
		</div>
	)
}

SelectDropdownWrapper.defaultProps = {
	dataTestId: '',
	isDisabled: false,
	menuClassName: null,
	position: SELECT_MENU_POSITION.BOTTOM_RIGHT,
}

SelectDropdownWrapper.POSITION = SELECT_MENU_POSITION

SelectDropdownWrapper.propTypes = {
	ControlComponent: PropTypes.elementType.isRequired,
	dataTestId: PropTypes.string.isRequired,
	MenuComponent: PropTypes.elementType.isRequired,
	controlProps: PropTypes.object.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	menuClassName: PropTypes.string,
	menuProps: PropTypes.object.isRequired,
	position: PropTypes.oneOf(Object.values(SELECT_MENU_POSITION)).isRequired,
}

export default SelectDropdownWrapper
