import { messages as enMessages } from 'languages/en'
import { createIntl } from 'react-intl'

export const DEFAULT_LANGUAGE = 'en'
export const messages = enMessages

const createTranslator = (locale, localeMessages) => {
	const intl = createIntl({ locale, messages: localeMessages })

	return intl
}

export const enTranslator = createTranslator('en', enMessages)

export const safariPolyfill = () => {
	if (window.Intl.PluralRules === undefined) {
		require('@formatjs/intl-pluralrules/polyfill')
		require('@formatjs/intl-pluralrules/locale-data/en')
	}
}

safariPolyfill()
