export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Doorgaan',
	allocation_option: 'Toewijzingsoptie',
	choice_option: 'Keuzeoptie',
	submit: 'Indienen',
	freetext_date_placeholder: 'Voer een datum in',
	freetext_date_inputHint: 'bijv. mm/dd/jaar',
	freetext_phone_placeholder: 'Voer een telefoonnummer in',
	freetext_prhone_inputHint: 'bijv. 000-123-4567',
	freetext_email_placeholder: 'Voer je e-mail in',
	freetext_email_inputHint: 'bijv. mijn@mail.com',
	freetext_zip_placeholder: 'Voer je postcode in',
	freetext_zip_inputHint: 'bijv. 12345',
	freetext_answer_placeholder: 'Voer je antwoord in',
	freetext_integer_inputHint: 'bijv. 123',
	freetext_number_inputHint: 'bijv. 1.5',
	freetext_amount_placeholder: 'Voer bedrag in',
	freetext_amount_inputHint: 'bijv. 1000.00',
	matrix_question: 'Matrixvraag',
	matrix_loop_question:
		'Zorg ervoor dat [[item]] in de vraagtekst wordt opgenomen om een unieke vraag voor elk lijstitem te presenteren',
	maxdiff_option: 'Item',
	maxdiff_best_question: 'Welke van deze eigenschappen zijn volgens jou de **belangrijkste**?',
	maxdiff_worst_question: 'Welke van deze eigenschappen zijn volgens jou de **minst** belangrijke?',
	new_message: 'Nieuw bericht',
	ranking_option: 'Rangschikkingsoptie',
	question_default: 'Typ je vraag in',
	none_of_these: 'Geen van deze',
	study_languages_language_choice_message: 'Wat is je voorkeurstaal?',
	ar: 'Arabisch',
	zh: 'Chinees',
	da: 'Deens',
	nl: 'Nederlands',
	en: 'Engels',
	fr: 'Frans',
	de: 'Duits',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonesisch',
	it: 'Italiaans',
	ja: 'Japans',
	ko: 'Koreaans',
	pl: 'Pools',
	pt: 'Portugees',
	ro: 'Roemeens',
	ru: 'Russisch',
	sk: 'Slowaaks',
	es: 'Spaans',
	sv: 'Zweeds',
	tl: 'Tagalog',
	th: 'Thais',
	tr: 'Turks',
	yo: 'Yoruba',
}
