import { translations } from 'languages'

/**
 *
 * @param {string} idTranslation
 * @param {[{language: string}]} languages
 * @param {boolean} shouldAddIndex
 * @param {number | string} index
 * @returns {{[languageCode: string]: string}}
 */
export const getDefaultOptionLabel = (idTranslation, languages, shouldAddIndex, index) => {
	return languages.reduce((acc, { language }) => {
		const label = translations[language][idTranslation] ?? idTranslation

		const labelWithNumber = shouldAddIndex === true ? `${label} ${index + 1}` : label

		return {
			...acc,
			[language]: labelWithNumber,
		}
	}, {})
}
