import _ from 'lodash'
import { enTranslator as intl } from 'intl.js'

import { CHOICE_SUBTYPE, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { CHOICE_OPTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/CHOICE_OPTION'
import { MATRIX_QUESTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MATRIX_QUESTION'
import { getDefaultOptionLabel } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getDefaultOptionLabel'

import Choice from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Choice'

export const getMatrixQuestion = ({ index, shortNameIndex, shortName, text, languages }) => {
	const questionShortName =
		_.isNil(shortName) === true
			? `${intl.formatMessage({ id: 'question' })} ${shortNameIndex || index + 1}`
			: shortName

	return MATRIX_QUESTION({
		shortName: questionShortName,
		text: text ?? 'question_default',
		languages,
		idListItem: null,
	})
}

const getTitle = definition =>
	definition.subtype === CHOICE_SUBTYPE.IMAGE ? 'matrixImageChoice.title' : 'matrixChoice.title'

const getDescription = definition =>
	definition.subtype === CHOICE_SUBTYPE.IMAGE
		? 'matrixImageChoice.dataset.description'
		: 'matrixChoice.dataset.description'

const getQuestionsDescription = definition =>
	definition.subtype === CHOICE_SUBTYPE.IMAGE
		? 'matrixImageChoice.question.description'
		: 'matrixChoice.question.description'

const getShortName = (subtype, modules) => {
	const title =
		subtype === CHOICE_SUBTYPE.IMAGE
			? intl.formatMessage({ id: 'matrixImageChoice.title' })
			: intl.formatMessage({ id: 'matrixChoice.title' })

	return getDatasetShortName(modules, title, VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE, subtype)
}

export default subtype => ({
	type: VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE,
	style: subtype === CHOICE_SUBTYPE.CHECKBOX ? 'choice' : 'image-choice',
	title: getTitle,
	description: getDescription,
	questionsDescription: getQuestionsDescription,
	component: Choice,
	generator: (modules, additionalProperties) => ({
		questions: [
			getMatrixQuestion({ index: 0, languages: additionalProperties.languages }),
			getMatrixQuestion({ index: 1, languages: additionalProperties.languages }),
		],
		options: [
			CHOICE_OPTION(
				subtype,
				false,
				1,
				getDefaultOptionLabel('choice_option', additionalProperties.languages, true, 0),
				false,
				additionalProperties.languages,
				null,
				null,
			),
			CHOICE_OPTION(
				subtype,
				false,
				2,
				getDefaultOptionLabel('choice_option', additionalProperties.languages, true, 1),
				false,
				additionalProperties.languages,
				null,
				null,
			),
		],
		shortName: getShortName(subtype, modules),
		isSharedMessageEnabled: true,
		sharedMessage: getMatrixQuestion({
			index: '',
			shortNameIndex: null,
			shortName: 'shared-message',
			text: 'matrix_question',
			languages: additionalProperties.languages,
		}),
		// filter for displayed questions
		moduleFilter: null,
		// filter for options
		filter: null,
		subtype,
		mandatory: true,
		multiple: false,
		minSelection: 1,
		maxSelection: 1,
		randomize: false,
		randomizerCount: 2,
		randomizerShowAll: true,
		randomizerAllowSkip: true,
		hasCenteredOptions: false,
		optionsShownLimit: null,
		listSettings: {
			isActive: false,
			idInputList: '',
			idMainList: '',
			question: MATRIX_QUESTION({
				shortName: '[[item]]',
				text: 'matrix_loop_question',
				languages: additionalProperties.languages,
				idListItem: null,
			}),
			maxIterations: null,
			listAllowSkip: true,
			attributeShortNameColumn: null,
		},
	}),
})
