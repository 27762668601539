export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Kontynuuj',
	allocation_option: 'Opcja alokacji',
	choice_option: 'Opcja wyboru',
	submit: 'Wyślij',
	freetext_date_placeholder: 'Wprowadź datę',
	freetext_date_inputHint: 'np. mm/dd/rok',
	freetext_phone_placeholder: 'Wprowadź numer telefonu',
	freetext_prhone_inputHint: 'np. 000-123-4567',
	freetext_email_placeholder: 'Wprowadź swój email',
	freetext_email_inputHint: 'np. my@mail.com',
	freetext_zip_placeholder: 'Wprowadź swój kod pocztowy',
	freetext_zip_inputHint: 'np. 12345',
	freetext_answer_placeholder: 'Wprowadź swoją odpowiedź',
	freetext_integer_inputHint: 'np. 123',
	freetext_number_inputHint: 'np. 1.5',
	freetext_amount_placeholder: 'Wprowadź kwotę',
	freetext_amount_inputHint: 'np. 1000.00',
	matrix_question: 'Pytanie z macierzy',
	matrix_loop_question:
		'Upewnij się, że uwzględniasz [[item]] w treści pytania, aby przedstawić unikalne pytanie dla każdego elementu listy',
	maxdiff_option: 'Element',
	maxdiff_best_question: 'Twoim zdaniem, które z tych są **najważniejszymi** atrybutami?',
	maxdiff_worst_question: 'Twoim zdaniem, które z tych są **najmniej ważnymi** atrybutami?',
	new_message: 'Nowa wiadomość',
	ranking_option: 'Opcja rankingowa',
	question_default: 'Wpisz swoje pytanie',
	none_of_these: 'Żadne z tych',
	study_languages_language_choice_message: 'Jaki jest twój preferowany język?',
	ar: 'Arabski',
	zh: 'Chiński',
	da: 'Duński',
	nl: 'Holenderski',
	en: 'Angielski',
	fr: 'Francuski',
	de: 'Niemiecki',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonezyjski',
	it: 'Włoski',
	ja: 'Japoński',
	ko: 'Koreański',
	pl: 'Polski',
	pt: 'Portugalski',
	ro: 'Rumuński',
	ru: 'Rosyjski',
	sk: 'Słowacki',
	es: 'Hiszpański',
	sv: 'Szwedzki',
	tl: 'Tagalog',
	th: 'Tajski',
	tr: 'Turecki',
	yo: 'Joruba',
}
