export const validate = (module, flow, config) => {
	const { languages } = config

	for (const option of module.definition.options) {
		for (const language of languages) {
			if (option.simpleName[language] === undefined) {
				return {
					infos: null,
					warnings: null,
					errors: [`${language} choice option simpleName is not defined`],
				}
			}
		}
	}

	const hasDuplicateOptionSimpleNames = module.definition.options.some(option =>
		languages.some(
			language =>
				option.simpleName[language].trim() !== '' &&
				module.definition.options.some(
					otherOption =>
						otherOption.id !== option.id &&
						otherOption.simpleName[language].trim() === option.simpleName[language].trim(),
				),
		),
	)

	return hasDuplicateOptionSimpleNames === true
		? {
				infos: null,
				warnings: null,
				errors: ['Choice option SimpleNames have to be unique.'],
		  }
		: null
}

export default validate
