import { messages as arMessages } from './ar'
import { messages as zhMessages } from './zh'
import { messages as daMessages } from './da'
import { messages as nlMessages } from './nl'
import { messages as enMessages } from './en'
import { messages as frMessages } from './fr'
import { messages as deMessages } from './de'
import { messages as haMessages } from './ha'
import { messages as hiMessages } from './hi'
import { messages as igMessages } from './ig'
import { messages as idMessages } from './id'
import { messages as itMessages } from './it'
import { messages as jaMessages } from './ja'
import { messages as koMessages } from './ko'
import { messages as plMessages } from './pl'
import { messages as ptMessages } from './pt'
import { messages as roMessages } from './ro'
import { messages as ruMessages } from './ru'
import { messages as skMessages } from './sk'
import { messages as esMessages } from './es'
import { messages as svMessages } from './sv'
import { messages as tlMessages } from './tl'
import { messages as thMessages } from './th'
import { messages as trMessages } from './tr'
import { messages as yoMessages } from './yo'

export const translations = {
	ar: arMessages,
	zh: zhMessages,
	da: daMessages,
	nl: nlMessages,
	en: enMessages,
	fr: frMessages,
	de: deMessages,
	ha: haMessages,
	hi: hiMessages,
	ig: igMessages,
	id: idMessages,
	it: itMessages,
	ja: jaMessages,
	ko: koMessages,
	pl: plMessages,
	pt: ptMessages,
	ro: roMessages,
	ru: ruMessages,
	sk: skMessages,
	es: esMessages,
	sv: svMessages,
	tl: tlMessages,
	th: thMessages,
	tr: trMessages,
	yo: yoMessages,
}
