const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	$id: 'http://groupsolver.com/v1/MODULE_INTRO.json',
	properties: {
		id: {
			$id: '/properties/id',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			title: 'UUID of a module',
			type: 'string',
		},
		code: {
			$id: '/properties/code',
			title: 'Code of a module',
			type: ['string', 'null'],
		},
		nextStep: {
			$id: '/properties/nextStep',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			title: 'UUID of a next module',
			type: ['string', 'null'],
		},
		entryPoint: {
			$id: '/properties/entryPoint',
			default: false,
			type: 'boolean',
		},
		isHidden: {
			$id: '/properties/isHidden',
			default: false,
			type: 'boolean',
		},
		type: {
			description: 'Have to be one of supported modules',
			enum: [
				'ALLOCATION',
				'A_CHOICE',
				'A_END_STUDY',
				'A_EVALUATOR',
				'A_FREE_TEXT',
				'A_MESSAGE',
				'A_OEQ',
				'HEATMAP',
				'LIST',
				'MAXDIFF',
				'RANKING',
				'RESEARCH_LABELING',
				'UI_COMMAND',
			],
			$id: '/properties/type',
			title: 'Module type',
			type: 'string',
		},
		definition: {
			additionalProperties: true,
			$id: '/properties/definition',
			properties: {},
			type: 'object',
		},
		order: {
			$id: '/properties/order',
			title: 'Order of a module in analytics',
			type: 'string',
		},
		simpleName: {
			$id: '/properties/simpleName',
			title: 'Name of a module in analytics',
			type: ['string', 'null'],
		},
		isPreviewOnly: {
			$id: '/properties/simpisPreviewOnlyleName',
			default: false,
			type: 'boolean',
		},
		listSettings: {
			type: 'array',
			additionalItems: false,
			items: {
				type: 'object',
				additionalProperties: false,
				required: ['idParent', 'idList', 'idListItem', 'idOriginalModule'],
				properties: {
					idParent: {
						type: 'string',
					},
					idList: {
						type: 'string',
					},
					idListItem: {
						type: 'string',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
					},
					idOriginalModule: {
						type: 'string',
					},
				},
			},
		},
	},
	required: [
		'id',
		'code',
		'type',
		'order',
		'simpleName',
		'entryPoint',
		'nextStep',
		'definition',
		'listSettings',
		'isHidden',
		'isPreviewOnly',
	],
	type: 'object',
}

export default schema
