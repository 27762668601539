import { VISUAL_FLOW_INVALID_MODULE_TYPES, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { normalizeInput } from 'helpers/string/normalizeInput'

import validateShortName from './_validateShortName'
import { validateChoiceOptions, hasInvalidOptionsFilter } from './validateChoice'
import validateMessage from './validateMessage'
import hasInvalidModuleFilter from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/_hasInvalidModuleFilter'
import getUpperModuleIds from 'routes/_study/StudyDesign/_store/helpers/getUpperModuleIds'

/***
 * single question helper
 */
export const hasNonUniqueSimpleName = (question, allQuestions) =>
	allQuestions.filter(q => normalizeInput(q.shortName) === normalizeInput(question.shortName))
		.length > 1

const hasNonUniqueQuestionShortNames = module =>
	module.definition.questions.find(question =>
		hasNonUniqueSimpleName(question, module.definition.questions),
	) !== undefined

const getHasEmptyListName = module => {
	if (module.definition.listSettings.isActive === false) {
		return false
	}

	return module.definition.listSettings.idInputList === ''
}

const getHasInvalidListSelected = (module, modules, orderModule, flatOrder) => {
	if (module.definition.listSettings.isActive === false) {
		return false
	}

	if (getHasEmptyListName(module) === true) {
		return false
	}

	const idsUpperModules = getUpperModuleIds(orderModule.path, flatOrder, modules)

	const upperModules = idsUpperModules.map(id => modules[id])

	const upperLists = upperModules.filter(
		module => VISUAL_FLOW_MODULE_TYPES.LIST === module.type && module.isHidden !== true,
	)

	const selectedList = upperLists.find(
		list => list.definition.id === module.definition.listSettings.idInputList,
	)

	return selectedList === undefined
}

const getHasInvalidAttributeShortNameColumnSelected = (module, modules) => {
	if (module.definition.listSettings.isActive === false) {
		return false
	}

	const { idMainList, attributeShortNameColumn } = module.definition.listSettings

	const selectedList = modules[idMainList]

	if (selectedList === undefined) {
		return false
	}

	const selectedColumn = selectedList.definition.columns.find(
		column => column.key === attributeShortNameColumn,
	)

	return selectedColumn === undefined || selectedColumn.isUnique === false
}

export const validateMatrixQuestions = (module, studyTags, languages) => {
	const validationResult = []

	module.definition.questions.forEach(question => {
		validationResult.push(
			...validateShortName(
				{
					definition: {
						id: module.definition.id,
						shortName: normalizeInput(question.shortName),
					},
				},
				{},
			),
		)

		if (module.isHidden === true) {
			return
		}

		validationResult.push(
			...validateMessage(
				{
					definition: {
						id: module.definition.id,
						text: question.text,
						timeout: question.timeout,
						media: question.media,
					},
				},
				studyTags,
				languages,
			),
		)
	})

	return validationResult
}

const validateMatrixChoice = (module, modules, orderModule, flatOrder, studyTags, languages) => {
	const validationResult = []

	validationResult.push(...validateMatrixQuestions(module, studyTags, languages))

	validationResult.push(
		...validateMessage(
			{
				definition: {
					id: module.definition.id,
					text: module.definition.sharedMessage.text,
					timeout: module.definition.sharedMessage.timeout,
					media: module.definition.sharedMessage.media,
				},
			},
			studyTags,
			languages,
		),
	)

	if (hasNonUniqueQuestionShortNames(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.matrix_choice_nonUniqueShortNames,
		})
	}

	if (hasInvalidOptionsFilter(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_invalidFilter,
		})
	}

	if (hasInvalidModuleFilter(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.invalid_module_filter,
		})
	}

	const hasEmptyListName = getHasEmptyListName(module)

	if (hasEmptyListName === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_filter_name_empty,
		})

		return validationResult
	}

	const hasInvalidListSelected = getHasInvalidListSelected(module, modules, orderModule, flatOrder)

	if (hasInvalidListSelected === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.matrix_choice_invalid_list_selected,
		})

		return validationResult
	}

	const hasInvalidAttributeShortNameColumnSelected = getHasInvalidAttributeShortNameColumnSelected(
		module,
		modules,
	)

	if (hasInvalidAttributeShortNameColumnSelected === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.matrix_choice_invalid_list_column_selected,
		})

		return validationResult
	}

	validationResult.push(...validateChoiceOptions(module, languages))

	return validationResult
}

export default validateMatrixChoice
