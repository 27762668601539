import { OPTION_LABEL_SOURCE } from 'constants/reports'

export const getOptionLabel = (option, chartSettings, language) => {
	const optionLabel = option.label[language]

	if (chartSettings.optionLabelSource === OPTION_LABEL_SOURCE.LABEL) {
		return optionLabel
	}

	// imported modules don't have option.simpleName
	if (option.simpleName !== undefined) {
		// return label if simpleName is empty
		return option.simpleName[language] || optionLabel
	}

	return optionLabel
}
