export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Continuer',
	allocation_option: "Option d'allocation",
	choice_option: 'Option de choix',
	submit: 'Soumettre',
	freetext_date_placeholder: 'Entrez une date',
	freetext_date_inputHint: 'par ex. mm/jj/année',
	freetext_phone_placeholder: 'Entrez un numéro de téléphone',
	freetext_prhone_inputHint: 'par ex. 000-123-4567',
	freetext_email_placeholder: 'Entrez votre email',
	freetext_email_inputHint: 'par ex. mon@mail.com',
	freetext_zip_placeholder: 'Entrez votre code postal',
	freetext_zip_inputHint: 'par ex. 12345',
	freetext_answer_placeholder: 'Entrez votre réponse',
	freetext_integer_inputHint: 'par ex. 123',
	freetext_number_inputHint: 'par ex. 1.5',
	freetext_amount_placeholder: 'Entrez un montant',
	freetext_amount_inputHint: 'par ex. 1000.00',
	matrix_question: 'Question matricielle',
	matrix_loop_question:
		"Assurez-vous d'inclure [[item]] dans le texte de la question pour présenter une question unique pour chaque élément de la liste",
	maxdiff_option: 'Élément',
	maxdiff_best_question: 'Selon vous, quelles sont les caractéristiques les **plus** importantes?',
	maxdiff_worst_question:
		'Selon vous, quelles sont les caractéristiques les **moins** importantes?',
	new_message: 'Nouveau message',
	ranking_option: 'Option de classement',
	question_default: 'Tapez votre question',
	none_of_these: 'Aucun de ceux-ci',
	study_languages_language_choice_message: 'Quelle est votre langue préférée ?',
	ar: 'Arabe',
	zh: 'Chinois',
	da: 'Danois',
	nl: 'Néerlandais',
	en: 'Anglais',
	fr: 'Français',
	de: 'Allemand',
	ha: 'Haoussa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonésien',
	it: 'Italien',
	ja: 'Japonais',
	ko: 'Coréen',
	pl: 'Polonais',
	pt: 'Portugais',
	ro: 'Roumain',
	ru: 'Russe',
	sk: 'Slovaque',
	es: 'Espagnol',
	sv: 'Suédois',
	tl: 'Tagalog',
	th: 'Thaï',
	tr: 'Turc',
	yo: 'Yoruba',
}
