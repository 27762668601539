/**
 * @param {{languages: [{language: string, isReportDefault: boolean}]}} study
 * @returns string
 */
export const getSolverDefaultLanguageCode = study => {
	const languageObject = study.languages.find(
		language => language.isEnabled === true && language.isSolverDefault === true,
	)

	if (languageObject === undefined) {
		throw new Error('default report langauge does not exist')
	}

	return languageObject.language
}
