import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import classnames from 'classnames'
import { Field, Formik, Form } from 'formik'
import { useIntl } from 'react-intl'

import { studyLanguagesShape } from 'constants/languages/studyLanguagesShape'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'

import Label from 'components/_formik/_base/Label'
import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import Input from 'components/_formik/_base/Input'

import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'

import classes from './Heatmap.module.scss'

const additionalActions = []

const Heatmap = props => {
	const intl = useIntl()

	const renderError = idTranslation => (
		<div className="title-error">{intl.formatMessage({ id: idTranslation })}</div>
	)

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ values, setValues }) => {
				const handleMediaUpload = (_id, url, thumbnailUrl) => {
					setValues({
						...values,
						imageUrl: {
							...values.imageUrl,
							[props.activeLanguage]: url,
						},
						thumbnailUrl: {
							...values.thumbnailUrl,
							[props.activeLanguage]: thumbnailUrl,
						},
					})
				}

				const openMediaManager = () => {
					if (props.disabled === true) {
						return
					}

					props.openMediaManager(
						{ name: 'imageUrl' },
						{},
						false,
						handleMediaUpload,
						props.activeLanguage,
					)
				}

				const imageUrl = values.imageUrl[props.activeLanguage]

				return (
					<Fragment>
						<Header
							activeLanguage={props.activeLanguage}
							additionalActions={additionalActions}
							closeModuleDetail={props.closeModuleDetail}
							copyModule={props.copyModule}
							copyModuleButtonParams={props.copyModuleButtonParams}
							disabled={props.disabled}
							generalDefinition={props.generalDefinition}
							isFlowChanged={props.isFlowChanged}
							isRelatedModuleInvalid={props.isRelatedModuleInvalid}
							languages={props.languages}
							languagesValidationResult={props.languagesValidationResult}
							moduleDefinition={values}
							openCopyToLibraryForm={props.openCopyToLibraryForm}
							setActiveLanguage={props.setActiveLanguage}
							showCopyToLibrary
						/>
						<FormHolder>
							<AutoSubmit
								values={values}
								onSave={props.saveModule}
								formComponent={() => (
									<Form>
										<DatasetShortName
											disabled={props.disabled}
											errorInvalidSimpleName={props.errorInvalidSimpleName}
											errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
											values={values}
										/>
										<Label label={intl.formatMessage({ id: 'image' })} />
										{imageUrl === '' ? (
											<div
												className={classnames(classes.upload, {
													[classes['upload--disabled']]: props.disabled === true,
												})}
												onClick={openMediaManager}
											>
												{intl.formatMessage({ id: 'heatmap.detail.image.upload' })}
											</div>
										) : (
											<div
												className={classnames(classes.image, {
													[classes['image--disabled']]: props.disabled === true,
												})}
												onClick={openMediaManager}
											>
												<img
													src={imageUrl}
													alt={intl.formatMessage({ id: 'heatmap.detail.image.alt' })}
												/>
												<div
													className={classnames(classes.image__change, {
														[classes['image__change--disabled']]: props.disabled === true,
													})}
												>
													{intl.formatMessage({ id: 'heatmap.detail.image.change' })}
												</div>
											</div>
										)}
										{imageUrl === '' && renderError('heatmap.detail.image.upload.error')}
										<Label label={intl.formatMessage({ id: 'heatmap.detail.min_answers' })} />
										<Field
											component={Input}
											disabled={props.disabled}
											name={'minAnswers'}
											type="number"
											min={1}
										/>
										{values.minAnswers < 1 && renderError('heatmap.detail.min_answers.error')}
										<Label label={intl.formatMessage({ id: 'heatmap.detail.max_answers' })} />
										<Field
											component={Input}
											disabled={props.disabled}
											name={'maxAnswers'}
											type="number"
											min={values.minAnswers}
										/>
										{values.minAnswers > values.maxAnswers &&
											renderError('heatmap.detail.max_answers.error')}
									</Form>
								)}
							/>
						</FormHolder>
					</Fragment>
				)
			}}
		</Formik>
	)
}

Heatmap.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	closeModuleDetail: PropTypes.func.isRequired,
	copyModule: PropTypes.func.isRequired,
	copyModuleButtonParams: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isRelatedModuleInvalid: PropTypes.bool.isRequired,
	languages: studyLanguagesShape.isRequired,
	languagesValidationResult: PropTypes.object.isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	openMediaManager: PropTypes.func.isRequired,
	saveModule: PropTypes.func.isRequired,
	setActiveLanguage: PropTypes.func.isRequired,
}

export default Heatmap
