import { enTranslator as intl } from 'intl.js'

import MaxDiff from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/MaxDiff'

import { CHOICE_SUBTYPE, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { CHOICE_OPTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/CHOICE_OPTION'
import { GET_QUESTION_MESSAGE } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/MESSAGE'
import { getDefaultOptionLabel } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getDefaultOptionLabel'

const defaultOptionsIndices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

const NUMBER_OF_ITEMS_PER_ROUND = 3

export default {
	type: VISUAL_FLOW_MODULE_TYPES.MAXDIFF,
	style: 'max-diff',
	title: definition =>
		definition.subtype === CHOICE_SUBTYPE.CHECKBOX ? 'maxDiff.title' : 'maxDiff.image.title',
	description: () => 'maxDiff.description',
	component: MaxDiff,
	generator: (modules, additionalProperties) => ({
		idRelatedMessage: null,
		mandatory: true,
		options: defaultOptionsIndices.map(index =>
			CHOICE_OPTION(
				CHOICE_SUBTYPE.CHECKBOX,
				false,
				index,
				getDefaultOptionLabel('maxdiff_option', additionalProperties.languages, true, index),
				true,
				additionalProperties.languages,
				null,
				null,
			),
		),
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'maxDiff.title' }),
			VISUAL_FLOW_MODULE_TYPES.MAXDIFF,
		),
		numberOfItemsPerRound: NUMBER_OF_ITEMS_PER_ROUND,
		messages: [],
		bestQuestion: GET_QUESTION_MESSAGE({
			...additionalProperties,
			idTextTranslation: 'maxdiff_best_question',
		}),
		worstQuestion: GET_QUESTION_MESSAGE({
			...additionalProperties,
			idTextTranslation: 'maxdiff_worst_question',
		}),
		subtype: CHOICE_SUBTYPE.CHECKBOX,
		blurBetweenRounds: true,
	}),
}
