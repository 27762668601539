export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: '继续',
	allocation_option: '分配选项',
	choice_option: '选择选项',
	submit: '提交',
	freetext_date_placeholder: '输入日期',
	freetext_date_inputHint: '例如：月/日/年',
	freetext_phone_placeholder: '输入电话号码',
	freetext_prhone_inputHint: '例如：000-123-4567',
	freetext_email_placeholder: '输入您的电子邮件',
	freetext_email_inputHint: '例如：my@mail.com',
	freetext_zip_placeholder: '输入邮政编码',
	freetext_zip_inputHint: '例如：12345',
	freetext_answer_placeholder: '输入您的答案',
	freetext_integer_inputHint: '例如：123',
	freetext_number_inputHint: '例如：1.5',
	freetext_amount_placeholder: '输入金额',
	freetext_amount_inputHint: '例如：1000.00',
	matrix_question: '矩阵问题',
	matrix_loop_question: '请确保在问题文本中包含[[item]]，以针对每个列表项呈现唯一问题',
	maxdiff_option: '项目',
	maxdiff_best_question: '在您看来，以下哪些是**最**重要的属性？',
	maxdiff_worst_question: '在您看来，以下哪些是**最不**重要的属性？',
	new_message: '新消息',
	ranking_option: '排序选项',
	question_default: '输入您的问题',
	none_of_these: '以上都不是',
	study_languages_language_choice_message: '您偏好的语言是什么？',
	ar: '阿拉伯语',
	zh: '中文',
	da: '丹麦语',
	nl: '荷兰语',
	en: '英语',
	fr: '法语',
	de: '德语',
	ha: '豪萨语',
	hi: '印地语',
	ig: '伊博语',
	id: '印尼语',
	it: '意大利语',
	ja: '日语',
	ko: '韩语',
	pl: '波兰语',
	pt: '葡萄牙语',
	ro: '罗马尼亚语',
	ru: '俄语',
	sk: '斯洛伐克语',
	es: '西班牙语',
	sv: '瑞典语',
	tl: '塔加洛语',
	th: '泰语',
	tr: '土耳其语',
	yo: '约鲁巴语',
}
