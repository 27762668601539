export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: '진행',
	allocation_option: '할당 옵션',
	choice_option: '선택 옵션',
	submit: '제출',
	freetext_date_placeholder: '날짜 입력',
	freetext_date_inputHint: '예: mm/dd/년',
	freetext_phone_placeholder: '전화번호 입력',
	freetext_prhone_inputHint: '예: 000-123-4567',
	freetext_email_placeholder: '이메일 입력',
	freetext_email_inputHint: '예: my@mail.com',
	freetext_zip_placeholder: '우편번호 입력',
	freetext_zip_inputHint: '예: 12345',
	freetext_answer_placeholder: '답변 입력',
	freetext_integer_inputHint: '예: 123',
	freetext_number_inputHint: '예: 1.5',
	freetext_amount_placeholder: '금액 입력',
	freetext_amount_inputHint: '예: 1000.00',
	matrix_question: '행렬 질문',
	matrix_loop_question:
		'모든 목록 항목에 대해 고유한 질문을 제시하기 위해 질문 텍스트에 [[item]]을 포함해야 합니다.',
	maxdiff_option: '항목',
	maxdiff_best_question: '귀하의 의견으로, 이 중에서 **가장** 중요한 속성은 무엇인가요?',
	maxdiff_worst_question: '귀하의 의견으로, 이 중에서 **가장 덜** 중요한 속성은 무엇인가요?',
	new_message: '새 메시지',
	ranking_option: '순위 옵션',
	question_default: '질문을 입력하세요',
	none_of_these: '이 중 어느 것도 아님',
	study_languages_language_choice_message: '선호하는 언어는 무엇입니까?',
	ar: '아랍어',
	zh: '중국어',
	da: '덴마크어',
	nl: '네덜란드어',
	en: '영어',
	fr: '프랑스어',
	de: '독일어',
	ha: '하우사어',
	hi: '힌디어',
	ig: '이그보어',
	id: '인도네시아어',
	it: '이탈리아어',
	ja: '일본어',
	ko: '한국어',
	pl: '폴란드어',
	pt: '포르투갈어',
	ro: '루마니아어',
	ru: '러시아어',
	sk: '슬로바키아어',
	es: '스페인어',
	sv: '스웨덴어',
	tl: '타갈로그어',
	th: '태국어',
	tr: '터키어',
	yo: '요루바어',
}
