import { PROFLOW_TYPES, CHOICE_OPTION_ORDER_MODE } from 'constants/studyDesign'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject'
import MEDIA_OBJECT from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MEDIA_OBJECT'

export const snippetTypeOptions = [
	{ value: PROFLOW_TYPES.ALLOCATION, label: 'Allocation' },
	{ value: PROFLOW_TYPES.A_CHOICE, label: 'Choice' },
	{ value: PROFLOW_TYPES.A_END_STUDY, label: 'Endstudy' },
	{ value: PROFLOW_TYPES.A_EVALUATOR, label: 'Evaluator' },
	{ value: PROFLOW_TYPES.A_FREE_TEXT, label: 'Freetext' },
	{ value: PROFLOW_TYPES.A_MESSAGE, label: 'Message' },
	{ value: PROFLOW_TYPES.A_OEQ, label: 'AI Open-End' },
	{ value: PROFLOW_TYPES.HEATMAP, label: 'Heatmap' },
	{ value: PROFLOW_TYPES.RANKING, label: 'Ranking' },
	{ value: PROFLOW_TYPES.RESEARCH_LABELING, label: 'Research labeling service' },
	{ value: PROFLOW_TYPES.UI_COMMAND, label: 'Checkpoint' },
]

// TODO: handle multiple languages
export const PROFLOW_DEFINITIONS = {
	[PROFLOW_TYPES.ALLOCATION]: languages =>
		JSON.stringify(
			{
				idRelatedMessage: null,
				subtype: 'INPUT',
				mandatory: true,
				limit: {
					useLimitAsMin: true,
					isEnabled: true,
					value: {
						type: 'VALUE | FREE_TEXT_ANSWER | VARIABLE',
						value: 100,
					},
				},
				hintValueSettings: {
					addSpace: false,
					isLeft: false,
					unitLabel: '',
				},
				range: {
					min: 0,
					max: null,
					step: 1,
				},
				filter: null,
				options: [
					{
						id: 'UUID',
						label: getLanguagesLabelsObject(languages, 'Option 1'),
						media: null,
						isNoneOfThese: false,
						showLabel: true,
						randomize: false,
						code: 1,
						simpleName: getLanguagesLabelsObject(languages, ''),
						defaultValue: 0,
						idListItem: null,
						isHidden: false,
						languageCode: null,
					},
					{
						id: 'UUID 2',
						label: getLanguagesLabelsObject(languages, 'Option 2'),
						media: null,
						isNoneOfThese: false,
						showLabel: true,
						randomize: false,
						code: 2,
						simpleName: getLanguagesLabelsObject(languages, ''),
						defaultValue: 0,
						idListItem: null,
						isHidden: false,
						languageCode: null,
					},
				],
				messages: [],
				dynamicOptionsSettings: {
					isActive: false,
					idMainList: null,
					idInputList: null,
					optionLabelColumn: getLanguagesLabelsObject(languages, null),
					optionsOrderMode: CHOICE_OPTION_ORDER_MODE.QUESTION_ORDER,
				},
			},
			null,
			2,
		),
	[PROFLOW_TYPES.A_CHOICE]: languages =>
		JSON.stringify(
			{
				idRelatedMessage: null,
				subtype: 'CHECKBOX | SELECT | IMAGE',
				mandatory: true,
				multiple: false,
				minSelection: 1,
				maxSelection: 1,
				filter: null,
				options: [],
				isLanguageChoice: false,
				trapQuestionSettings: {
					isTrapQuestion: false,
					selection: 'equalTo',
					correctAnswers: [],
				},
				messages: [],
				hasCenteredOptions: false,
				dynamicOptionsSettings: {
					isActive: false,
					idMainList: null,
					idInputList: null,
					optionLabelColumn: getLanguagesLabelsObject(languages, null),
					optionsOrderMode: CHOICE_OPTION_ORDER_MODE.QUESTION_ORDER,
				},
			},
			null,
			2,
		),
	[PROFLOW_TYPES.A_END_STUDY]: languages =>
		JSON.stringify(
			{
				subtype: 'TERMINATE | COMPLETE | OVERQUOTA',
				redirectLabel: getLanguagesLabelsObject(languages, ''),
				redirectUrl: null,
			},
			null,
			2,
		),
	[PROFLOW_TYPES.A_EVALUATOR]: () =>
		JSON.stringify(
			{
				logic: [],
			},
			null,
			2,
		),
	[PROFLOW_TYPES.A_FREE_TEXT]: languages =>
		JSON.stringify(
			{
				idRelatedMessage: null,
				subtype: 'INPUT | TEXTAREA',
				mandatory: true,
				placeholder: getLanguagesLabelsObject(languages, ''),
				inputHint: getLanguagesLabelsObject(languages, ''),
				maxInputLength: null,
				validationPattern: '.*',
				messages: [],
			},
			null,
			2,
		),
	[PROFLOW_TYPES.A_MESSAGE]: languages =>
		JSON.stringify(
			{
				text: getLanguagesLabelsObject(languages, ''),
				timeout: 1000,
				media: {
					...MEDIA_OBJECT({ languages }),
					id: '',
					type: 'A_VIDEO | A_IMAGE',
				},
			},
			null,
			2,
		),
	[PROFLOW_TYPES.A_OEQ]: languages =>
		JSON.stringify(
			{
				idRelatedMessage: null,
				enableAutoTranslate: false,
				enableGibberishFilter: true,
				enableProfanityFilter: true,
				preseededStatements: [],
				messages: [],
				ideate: {
					show: true,
					idPriorMessage: null,
					minIdeateUpperLimit: null,
					minIdeateLowerLimit: null,
					ideaPoolLimitShare: null,
					ideaPoolLimitNumber: null,
					disableSentenceSplitter: false,
				},
				eliminate: {
					idPriorMessage: null,
					showGrammar: true,
					showSuggestions: true,
					useAutoElimination: true,
				},
				elaborate: {
					show: true,
					idPriorMessage: null,
					minEvaluations: 8,
					maxEvaluations: 12,
				},
				translations: {
					ideate_placeholder: getLanguagesLabelsObject(languages, ''),
					elaborate_intro: getLanguagesLabelsObject(languages, ''),
					elaborate_agree_label: getLanguagesLabelsObject(languages, ''),
					elaborate_disagree_label: getLanguagesLabelsObject(languages, ''),
					elaborate_indifferent_label: getLanguagesLabelsObject(languages, ''),
					elaborate_unclear_statement_label: getLanguagesLabelsObject(languages, ''),
				},
			},
			null,
			2,
		),
	[PROFLOW_TYPES.UI_COMMAND]: () =>
		JSON.stringify(
			{
				actionButton: null,
				layout: 'null | CINEMA_START | CINEMA_END',
				history: 'null | BLUR | UNBLUR | CLEAR',
			},
			null,
			2,
		),
	[PROFLOW_TYPES.RANKING]: languages =>
		JSON.stringify(
			{
				filter: '',
				idRelatedMessage: null,
				mandatory: true,
				options: [
					{
						id: 'UUID',
						label: getLanguagesLabelsObject(languages, 'Option 1'),
						media: null,
						isNoneOfThese: false,
						showLabel: true,
						randomize: false,
						code: 1,
						simpleName: getLanguagesLabelsObject(languages, ''),
						idListItem: null,
						isHidden: false,
						languageCode: null,
					},
					{
						id: 'UUID 2',
						label: getLanguagesLabelsObject(languages, 'Option 2'),
						media: null,
						isNoneOfThese: false,
						showLabel: true,
						randomize: false,
						code: 2,
						simpleName: getLanguagesLabelsObject(languages, ''),
						idListItem: null,
						isHidden: false,
						languageCode: null,
					},
				],
				requireUniqueValues: true,
				subtype: 'RANKING_DRAG_AND_DROP | RANKING_SELECT',
				messages: [],
				dynamicOptionsSettings: {
					isActive: false,
					idMainList: null,
					idInputList: null,
					optionLabelColumn: getLanguagesLabelsObject(languages, null),
					optionsOrderMode: CHOICE_OPTION_ORDER_MODE.QUESTION_ORDER,
				},
			},
			null,
			2,
		),
	[PROFLOW_TYPES.RESEARCH_LABELING]: () =>
		JSON.stringify(
			{
				class: 'item-class',
				require: 20,
			},
			null,
			2,
		),
	[PROFLOW_TYPES.HEATMAP]: languages =>
		JSON.stringify(
			{
				blurBefore: false,
				blurAfter: false,
				idRelatedMessage: null,
				imageUrl: getLanguagesLabelsObject(languages, ''),
				thumbnailUrl: getLanguagesLabelsObject(languages, ''),
				isRanking: false,
				mandatory: true,
				maxAnswers: 1,
				maxTimer: null,
				minAnswers: 1,
				messages: [],
			},
			null,
			2,
		),
}

export const MODULES_TO_TEST = {
	VALID_CHOICE: {
		type: PROFLOW_TYPES.A_CHOICE,
		code: {
			idRelatedMessage: null,
			subtype: 'SELECT',
			mandatory: true,
			multiple: false,
			minSelection: 1,
			maxSelection: 1,
			filter: null,
			hasCenteredOptions: false,
			isLanguageChoice: false,
			options: [
				{
					id: '3fe3ca38-8a7d-444e-b878-f7a087e51bf9',
					label: { en: 'Yes' },
					simpleName: { en: '' },
					randomize: false,
					isNoneOfThese: false,
					showLabel: true,
					media: null,
					idListItem: null,
					isHidden: false,
					languageCode: null,
				},
				{
					id: '24553569-32cf-42f2-a19e-15ad0eb4fe53',
					label: { en: 'No' },
					simpleName: { en: '' },
					randomize: false,
					isNoneOfThese: false,
					showLabel: true,
					media: null,
					idListItem: null,
					isHidden: false,
					languageCode: null,
				},
			],
		},
	},
	INVALID_CHOICE: {
		type: PROFLOW_TYPES.A_CHOICE,
		code: {
			idRelatedMessage: null,
			subtype: 'SELECT',
			mandatory: true,
			multiple: false,
			minSelection: 1,
			maxSelection: 1,
			filter: null,
			isLanguageChoice: false,
			options: [],
		},
	},
	VALID_END_STUDY: {
		type: PROFLOW_TYPES.A_END_STUDY,
		code: {
			subtype: 'COMPLETE',
			redirectLabel: null,
			redirectUrl: null,
		},
	},
	INVALID_END_STUDY: {
		type: PROFLOW_TYPES.A_END_STUDY,
		code: {
			subtype: 'TERMINATE | COMPLETE | OVERQUOTA',
			redirectLabel: null,
			redirectUrl: null,
		},
	},
	VALID_EVALUATOR: {
		type: PROFLOW_TYPES.A_EVALUATOR,
		code: {
			logic: [
				{
					if: {
						condition: {
							and: [
								{
									arrayEquals: {
										left: ['46abc1df-fbb6-421e-992d-5d4997d9c588'],
										right: {
											var: '186c66a4-7aba-49da-bde0-e8b45a7d771d',
										},
									},
								},
							],
						},
						then: [
							{
								set: {
									nextStep: '602a132b-436e-4c21-827d-445440f4a0de',
								},
							},
						],
						else: [
							{
								set: {
									nextStep: '1d4711dc-412a-44a8-b656-0b96616e3474',
								},
							},
						],
					},
				},
			],
		},
	},
	INVALID_EVALUATOR: {
		type: PROFLOW_TYPES.A_EVALUATOR,
		code: {
			logic: [
				{
					iof: {
						condition: {
							and: [
								{
									arrayEquals: {
										left: ['46abc1df-fbb6-421e-992d-5d4997d9c588'],
										right: {
											var: '186c66a4-7aba-49da-bde0-e8b45a7d771d',
										},
									},
								},
							],
						},
						then: [
							{
								set: {
									nextStep: '602a132b-436e-4c21-827d-445440f4a0de',
								},
							},
						],
						else: [
							{
								set: {
									nextStep: '1d4711dc-412a-44a8-b656-0b96616e3474',
								},
							},
						],
					},
				},
			],
		},
	},
	VALID_FREE_TEXT: {
		type: PROFLOW_TYPES.A_FREE_TEXT,
		code: {
			idRelatedMessage: null,
			subtype: 'INPUT',
			mandatory: true,
			placeholder: 'placeholder',
			inputHint: null,
			maxInputLength: null,
			validationPattern: '.*',
		},
	},
	INVALID_FREE_TEXT: {
		type: PROFLOW_TYPES.A_FREE_TEXT,
		code: {
			idRelatedMessage: null,
			subtype: 'INPUT',
			mandatory: true,
			placeholder: 'placeholder',
			maxInputLength: null,
			validationPattern: '.*',
		},
	},
	VALID_MESSAGE: {
		type: PROFLOW_TYPES.A_MESSAGE,
		code: {
			text: 'message text',
			timeout: 1000,
			media: null,
		},
	},
	INVALID_MESSAGE: {
		type: PROFLOW_TYPES.A_MESSAGE,
		code: {
			text: 'message text',
			timeout: 1000,
		},
	},
	VALID_OEQ: {
		type: PROFLOW_TYPES.A_OEQ,
		code: {
			idRelatedMessage: null,
			enableGibberishFilter: true,
			enableProfanityFilter: true,
			ideate: {
				show: true,
				idPriorMessage: null,
				minIdeateUpperLimit: null,
				minIdeateLowerLimit: null,
				ideaPoolLimitShare: null,
				ideaPoolLimitNumber: null,
			},
			eliminate: {
				show: true,
				idPriorMessage: null,
			},
			elaborate: {
				show: true,
				idPriorMessage: null,
			},
			translations: {
				ideate_placeholder: '',
				elaborate_intro: '',
				elaborate_agree_label: '',
				elaborate_disagree_label: '',
				elaborate_indifferent_label: '',
				elaborate_unclear_statement_label: '',
			},
			match: {
				show: true,
				idPriorMessage: null,
			},
		},
	},
	INVALID_OEQ: {
		type: PROFLOW_TYPES.A_OEQ,
		code: {
			idRelatedMessage: null,
			enableGibberishFilter: true,
			enableProfanityFilter: true,
			ideate: {
				show: true,
				idPriorMessage: null,
				minIdeateUpperLimit: null,
				minIdeateLowerLimit: null,
				ideaPoolLimitShare: null,
				ideaPoolLimitNumber: null,
			},
			elaborate: {
				show: true,
				idPriorMessage: null,
			},
			translations: {
				ideate_placeholder: '',
				elaborate_intro: '',
				elaborate_agree_label: '',
				elaborate_disagree_label: '',
				elaborate_indifferent_label: '',
				elaborate_unclear_statement_label: '',
			},
			match: {
				show: true,
				idPriorMessage: null,
			},
		},
	},
	VALID_UI_COMMAND: {
		type: PROFLOW_TYPES.UI_COMMAND,
		code: {
			actionButton: null,
			layout: null,
			history: 'BLUR',
		},
	},
	INVALID_UI_COMMAND: {
		type: PROFLOW_TYPES.UI_COMMAND,
		code: {
			actionButton: null,
			layout: null,
			history: 'invalid command',
		},
	},
}
