export const validate = (module, flow, config) => {
	const { languages } = config

	for (const option of module.definition.options) {
		for (const language of languages) {
			if (option.label[language] === undefined) {
				return {
					infos: null,
					warnings: null,
					errors: [`${language} choice option label is not defined`],
				}
			}
		}
	}

	const hasDuplicateOptionLabels = module.definition.options.some(option =>
		languages.some(language =>
			module.definition.options.some(
				otherOption =>
					otherOption.id !== option.id &&
					otherOption.label[language].trim() === option.label[language].trim(),
			),
		),
	)

	return hasDuplicateOptionLabels === true
		? {
				infos: null,
				warnings: null,
				errors: ['Choice option Labels have to be unique.'],
		  }
		: null
}

export default validate
