import definition from './flowDefinition'
import { mergeValidationResults } from './validateHelpers'
import flowConfig from './flowConfig'

const validateSchema = (flowDefinition, flowObject, flow) => {
	// validate module against flowDefinition with schema validator
	const validationResult = flowDefinition.validate(flowObject)

	if (flowDefinition.validate.errors === null) {
		return null
	} else {
		return {
			errors: flowDefinition.validate.errors,
			warnings: null,
			infos: null,
		}
	}
}

const validateCode = (flowDefinition, module, flow, config) => {
	// validate module against flowDefinition with code validators
	let codeValidators = flowDefinition.codeValidators.map(codeValidator => {
		let validatorName = Object.keys(codeValidator)[0]

		const validationResult = codeValidator[validatorName](module, flow, config)

		return validationResult
	})

	return codeValidators
}

const validateModuleIntro = (module, flow, config) => {
	// module intro validation
	const schemaValidity = validateSchema(definition.MODULE_INTRO, module, flow)
	const codeValidity = validateCode(definition.MODULE_INTRO, module, flow, config)

	return [].concat(schemaValidity, codeValidity)
}

export const validateModuleDefinition = (module, flow, config) => {
	// module definition validation
	const schemaValidity = validateSchema(definition[module.type], module.definition, flow)

	if (schemaValidity !== null) {
		return [].concat(schemaValidity, null)
	}

	const codeValidity = validateCode(definition[module.type], module, flow, config)

	return [].concat(schemaValidity, codeValidity)
}

export const validate = (flow, config = flowConfig) => {
	// validate flow structure and paths
	const flowCodeValidity = validateCode(definition.FLOW, null, flow, config)
	const flowResults = mergeValidationResults(flowCodeValidity)

	// validate one module at a time
	let modulesResults = flow.map(module => {
		if (definition[module.type]) {
			// validate module intro
			let introValidity = validateModuleIntro(module, flow, config)
			// validate module definition
			let definitionValidity = validateModuleDefinition(module, flow, config)

			return mergeValidationResults(introValidity.concat(definitionValidity), module)
		} else {
			// return Unknown module validation result in case module was not found
			return {
				module,
				errors: ['Flow validator: Unknown module type: ' + module.type],
				warnings: null,
				infos: null,
			}
		}
	})

	// merge flow results and all results of all modules, filter nulls
	const validationResults = []
		.concat(flowResults, modulesResults)
		.filter(flowResult => flowResult !== null)
	return validationResults
}

export default validate
