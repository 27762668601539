import { v4 as uuidv4 } from 'uuid'

import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'

export default ({ enabled = false, label = 'Proceed', languages }) => ({
	id: uuidv4(),
	label: getLanguagesLabelsObject(languages, label),
	minTimer: enabled === true ? 0 : null,
	maxTimer: null,
	maxTimerEnabled: false,
	enabled,
})
