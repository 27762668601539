import { v4 as uuidv4 } from 'uuid'
import MEDIA_OBJECT from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MEDIA_OBJECT'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'

/**
 * @param {{shortName: string, text: string, langauges: [{[languageCode: string]: string}], idListItem: string | null}}
 * @returns
 */
export const MATRIX_QUESTION = ({ shortName, text, languages, idListItem }) => ({
	id: uuidv4(),
	idRelatedMessage: null,
	shortName,
	text: getLanguagesLabelsObject(languages, text),
	media: MEDIA_OBJECT({ languages }),
	timeout: 1000,
	isExpanded: true,
	isUnsaved: true,
	isHidden: false,
	idListItem,
})
