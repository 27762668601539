export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Ci gaba',
	allocation_option: 'Zaɓin rarrabawa',
	choice_option: 'Zaɓin zaɓi',
	submit: 'Aika',
	freetext_date_placeholder: 'Shigar da kwanan wata',
	freetext_date_inputHint: 'misali mm/kk/shekara',
	freetext_phone_placeholder: 'Shigar da lambar waya',
	freetext_prhone_inputHint: 'misali 000-123-4567',
	freetext_email_placeholder: 'Shigar da adireshin imel',
	freetext_email_inputHint: 'misali ni@mail.com',
	freetext_zip_placeholder: 'Shigar da lambar ZIP',
	freetext_zip_inputHint: 'misali 12345',
	freetext_answer_placeholder: 'Shigar da amsarka',
	freetext_integer_inputHint: 'misali 123',
	freetext_number_inputHint: 'misali 1.5',
	freetext_amount_placeholder: 'Shigar da adadi',
	freetext_amount_inputHint: 'misali 1000.00',
	matrix_question: 'Tambayar matrix',
	matrix_loop_question:
		'Tabbatar da haɗa [[item]] a cikin rubutun tambaya don gabatar da tambaya ta musamman ga kowane abu a jerin',
	maxdiff_option: 'Abu',
	maxdiff_best_question: "A ra'ayinka, waɗanne daga cikin waɗannan ne **mafi** mahimmanci siffofi?",
	maxdiff_worst_question:
		"A ra'ayinka, waɗanne daga cikin waɗannan ne **ƙarancin** mahimmanci siffofi?",
	new_message: 'Sako Sabon',
	ranking_option: 'Zaɓin matsayi',
	question_default: 'Shigar da tambayarka',
	none_of_these: 'Babu ɗaya daga cikin waɗannan',
	study_languages_language_choice_message: 'Wace ce harshen da ka fi so?',
	ar: 'Larabci',
	zh: 'Sinanci',
	da: 'Danish',
	nl: 'Holanci',
	en: 'Turanci',
	fr: 'Faransanci',
	de: 'Jamusanci',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Inyamuranci',
	id: 'Indonesiyanci',
	it: 'Italiyanci',
	ja: 'Japananci',
	ko: 'Koreyanci',
	pl: 'Harshen Poland',
	pt: 'Harshen Portugal',
	ro: 'Romaniyanci',
	ru: 'Rashanci',
	sk: 'Sloyak',
	es: 'Sifananci',
	sv: 'Harshen Suwedan',
	tl: 'Tagalog',
	th: 'Thai',
	tr: 'Turkish',
	yo: 'Yarbanci',
}
