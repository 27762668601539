import { enTranslator as intl } from 'intl.js'

import Allocation from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Allocation'

import {
	VISUAL_FLOW_MODULE_TYPES,
	ALLOCATION_SUBTYPE,
	ALLOCATION_LIMIT_TYPES,
} from 'constants/studyDesign'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { GET_QUESTION_MESSAGE } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/MESSAGE'
import { getDefaultDynamicOptionsSettings } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/CHOICE'
import { CHOICE_OPTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/CHOICE_OPTION'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'
import { getDefaultOptionLabel } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getDefaultOptionLabel'

export default {
	type: VISUAL_FLOW_MODULE_TYPES.ALLOCATION,
	style: 'allocation',
	title: () => 'allocation.title',
	description: () => 'allocation.description',
	component: Allocation,
	generator: (modules, additionalProperties) => ({
		filter: '',
		idRelatedMessage: null,
		limit: {
			isEnabled: true,
			useLimitAsMin: true,
			value: {
				type: ALLOCATION_LIMIT_TYPES.VALUE,
				value: 100,
			},
		},
		hintValueSettings: {
			addSpace: false,
			isLeft: false,
			unitLabel: '',
		},
		instructionSettings: {
			isVisible: false,
			isCustom: false,
			text: getLanguagesLabelsObject(additionalProperties.languages, ''),
		},
		mandatory: true,
		dynamicOptionsSettings: getDefaultDynamicOptionsSettings(additionalProperties.languages),
		options: [
			CHOICE_OPTION(
				ALLOCATION_SUBTYPE.INPUT,
				false,
				1,
				getDefaultOptionLabel('allocation_option', additionalProperties.languages, true, 0),
				true,
				additionalProperties.languages,
				null,
				null,
			),
			CHOICE_OPTION(
				ALLOCATION_SUBTYPE.INPUT,
				false,
				2,
				getDefaultOptionLabel('allocation_option', additionalProperties.languages, true, 1),
				true,
				additionalProperties.languages,
				null,
				null,
			),
		],
		range: {
			max: '',
			min: 0,
			step: 0, // will be used if we'll implement slider subtype
		},
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'allocation.title' }),
			VISUAL_FLOW_MODULE_TYPES.ALLOCATION,
		),
		subtype: ALLOCATION_SUBTYPE.INPUT,
		messages: [
			GET_QUESTION_MESSAGE({ ...additionalProperties, idTextTranslation: 'question_default' }),
		],
	}),
}
