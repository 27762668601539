import { translations } from 'languages'

export const getLanguagesLabelsObject = (languages, value) =>
	languages.reduce(
		(acc, { language }) => ({
			...acc,
			[language]: translations[language][value] ?? value,
		}),
		{},
	)
