import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

import Icon from 'components/_scaffolding/Icon'
import Label from 'components/_formik/_base/Label'

import classes from './DynamicOptionsPreview.module.scss'

const DynamicOptionsPreview = ({ activeLanguage, disabled, options, setFieldValue }) => {
	const intl = useIntl()

	return (
		<Fragment>
			<Label label={intl.formatMessage({ id: 'options' })} />
			<div className={classes['grid-wrapper']}>
				<Label label={intl.formatMessage({ id: 'choice.option.label' })} />
				<div />
				<div className={classes['grid-wrapper__code']}>
					<Label label={intl.formatMessage({ id: 'choice.option.code' })} />
				</div>
				{options
					.filter(option => option.isNoneOfThese === false)
					.map((option, index) => (
						<Fragment key={option.id}>
							<div
								className={classnames(classes['grid-wrapper__label'], {
									'opacity-80': option.isHidden === true,
								})}
							>
								{option.label[activeLanguage]}
							</div>
							<div
								className={classnames(classes['grid-wrapper__icon'], {
									'opacity-80': option.isHidden === true,
									[classes['grid-wrapper__icon--disabled']]: disabled === true,
								})}
								onClick={() => {
									if (disabled === true) {
										return
									}

									setFieldValue(`options[${index}].isHidden`, option.isHidden !== true)
								}}
							>
								<Icon name={option.isHidden === true ? Icon.NAMES.EYE_CROSSED : Icon.NAMES.EYE} />
							</div>
							<div
								className={classnames(classes['grid-wrapper__code'], {
									'opacity-80': option.isHidden === true,
								})}
							>
								{option.code}
							</div>
						</Fragment>
					))}
			</div>
		</Fragment>
	)
}

DynamicOptionsPreview.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	setFieldValue: PropTypes.func.isRequired,
}

export default DynamicOptionsPreview
