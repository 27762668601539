export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Prosseguir',
	allocation_option: 'Opção de alocação',
	choice_option: 'Opção de escolha',
	submit: 'Enviar',
	freetext_date_placeholder: 'Digite uma data',
	freetext_date_inputHint: 'ex. mm/dd/ano',
	freetext_phone_placeholder: 'Digite um número de telefone',
	freetext_prhone_inputHint: 'ex. 000-123-4567',
	freetext_email_placeholder: 'Digite seu email',
	freetext_email_inputHint: 'ex. meu@mail.com',
	freetext_zip_placeholder: 'Digite seu CEP',
	freetext_zip_inputHint: 'ex. 12345',
	freetext_answer_placeholder: 'Digite sua resposta',
	freetext_integer_inputHint: 'ex. 123',
	freetext_number_inputHint: 'ex. 1.5',
	freetext_amount_placeholder: 'Digite o valor',
	freetext_amount_inputHint: 'ex. 1000,00',
	matrix_question: 'Pergunta da matriz',
	matrix_loop_question:
		'Certifique-se de incluir [[item]] no texto da pergunta para apresentar uma pergunta única para cada item da lista',
	maxdiff_option: 'Item',
	maxdiff_best_question: 'Na sua opinião, quais desses são os **mais** importantes atributos?',
	maxdiff_worst_question: 'Na sua opinião, quais desses são os **menos** importantes atributos?',
	new_message: 'Nova mensagem',
	ranking_option: 'Opção de classificação',
	question_default: 'Digite sua pergunta',
	none_of_these: 'Nenhuma dessas',
	study_languages_language_choice_message: 'Qual é o seu idioma preferido?',
	ar: 'Árabe',
	zh: 'Chinês',
	da: 'Dinamarquês',
	nl: 'Holandês',
	en: 'Inglês',
	fr: 'Francês',
	de: 'Alemão',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonésio',
	it: 'Italiano',
	ja: 'Japonês',
	ko: 'Coreano',
	pl: 'Polonês',
	pt: 'Português',
	ro: 'Romeno',
	ru: 'Russo',
	sk: 'Eslovaco',
	es: 'Espanhol',
	sv: 'Sueco',
	tl: 'Tagalog',
	th: 'Tailandês',
	tr: 'Turco',
	yo: 'Iorubá',
}
