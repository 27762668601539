export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Tẹsiwaju',
	allocation_option: 'Yiyan pinpin',
	choice_option: 'Yiyan aṣayan',
	submit: 'Fọwọsi',
	freetext_date_placeholder: 'Tẹ ọjọ kan',
	freetext_date_inputHint: 'e.g. mm/dd/year',
	freetext_phone_placeholder: 'Tẹ nọmba foonu kan',
	freetext_prhone_inputHint: 'e.g. 000-123-4567',
	freetext_email_placeholder: 'Tẹ imeeli rẹ',
	freetext_email_inputHint: 'e.g. my@mail.com',
	freetext_zip_placeholder: 'Tẹ koodu ZIP rẹ',
	freetext_zip_inputHint: 'e.g. 12345',
	freetext_answer_placeholder: 'Tẹ idahun rẹ',
	freetext_integer_inputHint: 'e.g. 123',
	freetext_number_inputHint: 'e.g. 1.5',
	freetext_amount_placeholder: 'Tẹ iye',
	freetext_amount_inputHint: 'e.g. 1000.00',
	matrix_question: 'Ibeere matrix',
	matrix_loop_question:
		'Rii daju lati fi [[item]] si ọrọ ibeere lati ṣe afihan ibeere alailẹgbẹ fun gbogbo nkan akojọ',
	maxdiff_option: 'Nkan',
	maxdiff_best_question: 'Ni ọpọlọ rẹ, kini ninu awọn wọnyi ni awọn ẹya pataki **jùlọ**?',
	maxdiff_worst_question: 'Ni ọpọlọ rẹ, kini ninu awọn wọnyi ni awọn ẹya pataki **kekere**?',
	new_message: 'Iṣẹ tuntun',
	ranking_option: 'Yiyan ipo',
	question_default: 'Kọ ibeere rẹ',
	none_of_these: 'Kò si ọkan ninu wọn',
	study_languages_language_choice_message: 'Kí ni èdè tó fẹ́ràn rẹ?',
	ar: 'Ara mọ́',
	zh: 'Ṣáínà',
	da: 'Danish',
	nl: 'Dutch',
	en: 'Gẹ́gẹ́',
	fr: 'Faranse',
	de: 'Jámánì',
	ha: 'Hausa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonesian',
	it: 'Italian',
	ja: 'Japanese',
	ko: 'Korean',
	pl: 'Polish',
	pt: 'Portuguese',
	ro: 'Romanian',
	ru: 'Russian',
	sk: 'Slovak',
	es: 'Spanish',
	sv: 'Swedish',
	tl: 'Tagalog',
	th: 'Thai',
	tr: 'Turkish',
	yo: 'Yoruba',
}
