import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const Organization = lazy(() => import('./Organization'))
const WrappedOrganization = errorWrapper(Organization)

export default () => ({
	path: 'organization',
	element: <WrappedOrganization />,
})
