import { v4 as uuidv4 } from 'uuid'

import { MEDIA_AGATHA_TYPE } from 'constants/mediaObject'

import ACTION_BUTTON from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/ACTION_BUTTON'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'

const getMediaUrl = (isNoneOfThese, languages) =>
	languages.reduce(
		(acc, { language }) => ({
			...acc,
			[language]: isNoneOfThese ? `/assets/nonOfThese/${language}.jpg` : '',
		}),
		{},
	)

export default ({ isNoneOfThese = false, languages }) => ({
	id: uuidv4(),
	type: MEDIA_AGATHA_TYPE.A_IMAGE, // MEDIA_AGATHA_TYPE.A_VIDEO
	alt: getLanguagesLabelsObject(languages, ''),
	url: getMediaUrl(isNoneOfThese, languages),
	thumbnailUrl: getMediaUrl(isNoneOfThese, languages),
	render: {
		forceFullscreen: false,
		blurBefore: false,
		hideAfter: false,
		videoShowControls: true,
		videoAutoPlay: false,
		imageFullscreenZoom: null,
	},
	actionButton: ACTION_BUTTON({ languages }),
})
