const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	required: [
		'blurAfter',
		'blurBefore',
		'idRelatedMessage',
		'imageUrl',
		'isRanking',
		'mandatory',
		'maxAnswers',
		'maxTimer',
		'messages',
		'minAnswers',
		'thumbnailUrl',
	],
	definitions: {},
	$id: 'http://groupsolver.com/v1/HEATMAP.json',
	properties: {
		blurBefore: {
			$id: '/properties/blurBefore',
			default: false,
			type: 'boolean',
		},
		blurAfter: {
			$id: '/properties/blurAfter',
			default: false,
			type: 'boolean',
		},
		idRelatedMessage: {
			$id: '/properties/idRelatedMessage',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			type: ['string', 'null'],
		},
		imageUrl: {
			$id: '/properties/imageUrl',
			type: 'object',
			additionalProperties: false,
			patternProperties: {
				'^[a-z]{2}$': {type: 'string'},
			},
		},
		thumbnailUrl: {
			$id: '/properties/thumbnailUrl',
			type: 'object',
			additionalProperties: false,
			patternProperties: {
				'^[a-z]{2}$': {type: 'string'},
			},
		},
		isRanking: {
			$id: '/properties/isRanking',
			default: false,
			type: 'boolean',
		},
		mandatory: {
			$id: '/properties/mandatory',
			default: true,
			type: 'boolean',
		},
		maxAnswers: {
			$id: '/properties/maxAnswers',
			minimum: 1,
			type: 'integer',
		},
		maxTimer: {
			$id: '/properties/maxTimer',
			type: ['integer', 'null'],
		},
		minAnswers: {
			$id: '/properties/minAnswers',
			minimum: 1,
			type: 'integer',
		},
		messages: {
			$id: '/properties/messages',
			type: 'array',
			additionalItems: false,
			uniqueItems: true,
			items: {
				$id: '/properties/messages/items',
				type: 'object',
				additionalProperties: false,
				required: ['id', 'definition', 'type'],
				properties: {
					id: {
						$id: '/properties/messages/items/id',
						type: 'string',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
					},
					type: {
						$id: '/properties/messages/items/type',
						type: 'string',
						enum: ['A_MESSAGE'],
					},
					definition: {
						$id: '/properties/messages/items/definition',
						$ref: 'http://groupsolver.com/v1/MESSAGE.json',
					},
				},
			},
		},
	},
}

export default schema
