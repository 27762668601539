import { gql } from '@apollo/client'

import { PATHS } from 'constants/router'
import { REPORT_BLOB_TYPES } from 'constants/reports'

import { prepareQuery } from 'store/tools/graphql'

import { getBlobDataGraphQLKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getIsInPresentationSharePath, getIsPresentationRoute } from 'helpers/router'
import { getStudyQueryKey } from 'helpers/gql/getStudyQueryKey'
import { getUploadedFileKey } from 'helpers/gql/getUploadedFileKey'

import {
	dataImport,
	dataDownload,
	finishedStatistics,
	flow,
	limits,
	note,
	quantQualSlide,
	quotaStatistics,
	respondents,
	statistics,
	studyStatus,
} from 'gql/fragments/study'
import { mediaObject } from 'gql/fragments/mediaObject'
import {
	respondentSource,
	groupSolverPanelInfo,
	groupSolverPanelPayment,
} from 'gql/fragments/respondentSources'
import { segment } from 'gql/fragments/segments'
import { statement, statementGroup, statementTheme } from 'gql/fragments/answerManager'
import {
	heatmapSlide,
	maxDiffSlide,
	oldSlide,
	slide,
	sunburstSlide,
} from 'gql/fragments/reportBuilder'
import {
	segmentationTypingTool,
	segmentationTypingToolPublicLiveSlides,
} from 'gql/fragments/segmentationTypingTool'

const createMultipleStudiesQueryString = (idsStudies, fields) => {
	return idsStudies.reduce((acc, idStudy) => {
		return `${acc}\n${getStudyQueryKey(idStudy)}: study(idStudy: "${idStudy}") {${fields}}`
	}, '')
}

export const MULTIPLE_STUDIES = idsStudies => {
	const isInAuthorizedMode = getIsInPresentationSharePath() === false

	const fields = `
		idStudy
		...flow
		importedModules
		languages {
			idStudyLanguage
			language
			isEnabled
			isReportDefault
			isSolverDefault
		}
		isLibrary
		...studyStatus
		segments {
			...segment
		}
		...respondents
		statistics {
			...finishedStatistics
		}
		quotaStatistics {
			...quotaStatistics
		}
		segmentationTypingTools {
			${
				isInAuthorizedMode === true
					? '...segmentationTypingTool'
					: '...segmentationTypingToolPublicLiveSlides'
			}
		}
		${isInAuthorizedMode === true ? 'idAccount' : ''}
		${isInAuthorizedMode === true ? `mediaFiles {...mediaObject}` : ''}
	`

	const studies = createMultipleStudiesQueryString(idsStudies, fields)

	return prepareQuery(
		{
			query: gql`
				query STUDIES {
					${studies}
				}
			`,
		},
		[
			finishedStatistics,
			flow,
			isInAuthorizedMode === true ? mediaObject : null,
			quotaStatistics,
			respondents,
			segment,
			studyStatus,
			isInAuthorizedMode === true ? segmentationTypingTool : segmentationTypingToolPublicLiveSlides,
		].filter(fragment => fragment !== null),
	)
}

export const STUDY_DETAIL = (idStudy, fetchPolicy = 'cache-first') =>
	prepareQuery(
		{
			variables: { idStudy: idStudy },
			query: gql`
				query STUDY_DETAIL($idStudy: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						idAccount
						respondentSources {
							...respondentSource
						}
						isLibrary
						isPostAnalytics
						note
						...flow
						importedModules
						enableAutoTranslate
						custom {
							limits
							translations
							theme {
								baseColor
							}
						}
						solverUrl
						languages {
							idStudyLanguage
							language
							isEnabled
							isReportDefault
							isSolverDefault
						}
						...studyStatus
						stateDiagram
						segments {
							...segment
						}
						...respondents
						dataImport(isCommunity: false) {
							...dataImport
						}
						community: dataImport(isCommunity: true) {
							...dataImport
						}
						hasBotsErrors
						statistics {
							...statistics
						}
						quotaStatistics {
							...quotaStatistics
						}
						respondentSourcesStatistics
						mediaFiles {
							...mediaObject
						}
						deleteOn
						notes {
							...note
						}
						...limits
						segmentationTypingTools {
							...segmentationTypingTool
						}
						generateThemesStatus
					}
				}
			`,
			options: { fetchPolicy },
		},
		[
			dataImport,
			flow,
			limits,
			mediaObject,
			note,
			quotaStatistics,
			respondents,
			respondentSource,
			segment,
			statistics,
			studyStatus,
			segmentationTypingTool,
		],
	)

export const STUDY_STATUS = idStudy =>
	prepareQuery(
		{
			variables: { idStudy: idStudy },
			query: gql`
				query GET_LIVE_UPDATE($idStudy: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						isLibrary
						isPostAnalytics
						deleteOn
						dataImport(isCommunity: false) {
							idDataImport
							importedModules
						}
						hasBotsErrors
						...studyStatus
						segments {
							...segment
						}
						flow {
							stats
						}
						...respondents
						statistics {
							...statistics
						}
						dataDownload {
							...dataDownload
						}
						quotaStatistics {
							...quotaStatistics
						}
						respondentSourcesStatistics
						respondentSources {
							idRespondentSource
							isEnabled
							provider {
								idPanelProvider
								isFinished
								providerType
								...groupSolverPanelInfo
								...groupSolverPanelPayment
							}
						}
						mediaFiles {
							...mediaObject
						}
						generateThemesStatus
					}
				}
			`,
		},
		[
			dataDownload,
			groupSolverPanelInfo,
			groupSolverPanelPayment,
			mediaObject,
			quotaStatistics,
			respondents,
			segment,
			statistics,
			studyStatus,
		],
	)

export const GET_OFFERS = idStudy =>
	prepareQuery({
		variables: { idStudy },
		query: gql`
			query GET_OFFERS($idStudy: UUID!) {
				study(idStudy: $idStudy) {
					idStudy
					dataDownload {
						offers {
							default
							title
							type
							filters {
								idQuestions
							}
							availableFormats
							options {
								key
								label
								description
								values {
									label
									description
									default
									value
								}
							}
						}
					}
				}
			}
		`,
	})

export const GET_DATA_IMPORT = (idStudy, isCommunity = false) => {
	const uploadedFileKey = getUploadedFileKey(isCommunity)

	return prepareQuery(
		{
			variables: { idStudy, isCommunity },
			query: gql`
				query GET_DATA_IMPORT($idStudy: UUID!, $isCommunity: Boolean!) {
					study(idStudy: $idStudy) {
						idStudy
						${uploadedFileKey}: dataImport(isCommunity: $isCommunity) {
							...dataImport
						}
					}
				}
			`,
		},
		[dataImport],
	)
}

export const GET_STUDY_OBJECT_DATA = (idStudy, idStudyObject) => {
	const isPresentationRoute = getIsPresentationRoute()

	const isLiveSlidesView = window.location.pathname.includes(PATHS.VIEW) === true

	return prepareQuery(
		{
			variables: { idStudy, idStudyObject },
			options: { fetchPolicy: isLiveSlidesView === true ? 'cache-first' : 'network-only' },
			query: gql`
				query GET_STUDY_OBJECT_DATA($idStudy: UUID!, $idStudyObject: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						answerManager {
							studyObjectData(idStudyObject: $idStudyObject) {
								idStudyObjectData
								idStudyObject
								statements {
									...statement
								}
								statementGroups {
									...statementGroup
								}
								statementThemes {
									...statementTheme
								}
								${isPresentationRoute === true ? '' : 'idGenerateThemesRun'}
							}
						}
					}
				}
			`,
		},
		[statement, statementGroup, statementTheme],
	)
}

export const GET_GENERATED_THEMES = onCompleted =>
	prepareQuery(
		{
			options: { fetchPolicy: 'network-only', onCompleted },
			query: gql`
				query GET_GENERATED_THEMES($idStudy: UUID!, $idStudyObject: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						answerManager {
							getGeneratedThemes(idStudyObject: $idStudyObject) {
								statementThemes {
									...statementTheme
								}
								idGenerateThemesRun
							}
						}
					}
				}
			`,
		},
		[statementTheme],
	)

export const GET_RESPONDENT_LIST = idStudy =>
	prepareQuery({
		variables: { idStudy },
		query: gql`
			query GET_RESPONDENT_LIST($idStudy: UUID!) {
				study(idStudy: $idStudy) {
					idStudy
					respondentManager {
						respondents {
							idRespondent
							isExcluded
							idRespondentSource
							loginParams
							tracking
							startTime
							numberOfFailedTrapQuestions
							lastStudyObject {
								idStudyObject
								timestamp
							}
							studySolvingTime {
								deviationClass
								standardDeviation
								singleDeviation
							}
						}
					}
				}
			}
		`,
	})

export const SLIDES = idStudy =>
	prepareQuery(
		{
			variables: { idStudy },
			query: gql`
				query SLIDES($idStudy: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						slides {
							...slide
						}
						oldSlides {
							...oldSlide
						}
						sunburstSlides {
							...sunburstSlide
						}
						maxDiffSlides {
							...maxDiffSlide
						}
						heatmapSlides {
							...heatmapSlide
						}
					}
				}
			`,
		},
		[oldSlide, slide, sunburstSlide, maxDiffSlide, heatmapSlide],
	)

export const OLD_SLIDES = idStudy =>
	prepareQuery(
		{
			variables: { idStudy },
			query: gql`
				query OLD_SLIDES($idStudy: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						oldSlides {
							...oldSlide
						}
					}
				}
			`,
		},
		[oldSlide],
	)

export const SLIDE = (idStudy, idSlide) =>
	prepareQuery(
		{
			variables: { idStudy, idSlide },
			query: gql`
				query SLIDE($idStudy: UUID!, $idSlide: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						slide(idSlide: $idSlide) {
							...slide
						}
					}
				}
			`,
		},
		[slide],
	)

export const SLIDE_OLD = (idStudy, idSlide) =>
	prepareQuery(
		{
			variables: { idStudy, idSlide },
			query: gql`
				query SLIDE_OLD($idStudy: UUID!, $idSlide: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						oldSlide(idSlide: $idSlide) {
							...oldSlide
						}
					}
				}
			`,
		},
		[oldSlide],
	)

export const HEATMAP_SLIDE = (idStudy, idSlide) =>
	prepareQuery(
		{
			variables: { idStudy, idSlide },
			query: gql`
				query HEATMAP_SLIDE($idSlide: UUID!, $idStudy: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						heatmapSlide(idSlide: $idSlide) {
							...heatmapSlide
						}
					}
				}
			`,
		},
		[heatmapSlide],
	)

export const MAXDIFF_SLIDE = (idStudy, idSlide) =>
	prepareQuery(
		{
			variables: { idStudy, idSlide },
			query: gql`
				query MAXDIFF_SLIDE($idSlide: UUID!, $idStudy: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						maxDiffSlide(idSlide: $idSlide) {
							...maxDiffSlide
						}
					}
				}
			`,
		},
		[maxDiffSlide],
	)

export const SUNBURST_SLIDE = (idStudy, idSlide) =>
	prepareQuery(
		{
			variables: { idStudy, idSlide },
			query: gql`
				query SUNBURST_SLIDE($idStudy: UUID!, $idSlide: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						sunburstSlide(idSlide: $idSlide) {
							...sunburstSlide
						}
					}
				}
			`,
		},
		[sunburstSlide],
	)

export const QUANT_QUAL_SLIDE = (idStudy, idSlide) =>
	prepareQuery(
		{
			variables: { idStudy, idSlide },
			query: gql`
				query QUANT_QUAL_SLIDE($idStudy: UUID!, $idSlide: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						quantQualSlide(idSlide: $idSlide) {
							...quantQualSlide
						}
					}
				}
			`,
		},
		[quantQualSlide],
	)

export const STUDY_NOTES = idStudy =>
	prepareQuery(
		{
			variables: { idStudy },
			query: gql`
				query STUDY_NOTES($idStudy: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						notes {
							...note
						}
					}
				}
			`,
		},
		[note],
	)

const getStudyBlobsQueryString = (idStudy, blobs) => {
	let queryString = ''

	blobs.forEach(blob => {
		const { idStudyObject, idSegment, blobType, idChoiceQuestion } = blob

		if (blobType === REPORT_BLOB_TYPES.QUANT_QUAL) {
			queryString = `
			${queryString}
			${getBlobDataGraphQLKey(idStudyObject, idSegment, blobType, idChoiceQuestion)}: dataBlobSignedUrl(
				idStudyObject: "${idStudyObject}"
				idChoiceQuestion: "${idChoiceQuestion}"
				idSegment: "${idSegment}"
				blobType: ${blobType}
			) {
				idSegment
				idStudyDataSignedUrl
				idStudyObject
				idChoiceQuestion
				blobType
				url
			}
		`
		} else {
			const idQuestion =
				blobType === REPORT_BLOB_TYPES.CHOICE_STATISTICS && idChoiceQuestion !== undefined
					? idChoiceQuestion
					: idStudyObject

			queryString = `
			${queryString}
			${getBlobDataGraphQLKey(idQuestion, idSegment, blobType)}: dataBlobSignedUrl(
				idStudyObject: "${idQuestion}"
				idSegment: "${idSegment}"
				blobType: ${blobType}
			) {
				idSegment
				idStudyDataSignedUrl
				idStudyObject
				blobType
				url
			}
		`
		}
	})

	return `${getStudyQueryKey(idStudy)}: study(idStudy: "${idStudy}") {
		idStudy
		${queryString}
	}`
}

export const GET_QUANT_QUAL_BLOB_URL = (
	blobType,
	idStudy,
	idChoiceQuestion,
	idStudyObject,
	idSegment,
) => {
	return prepareQuery({
		variables: {
			blobType,
			idChoiceQuestion,
			idSegment,
			idStudy,
			idStudyObject,
		},
		options: { fetchPolicy: 'network-only' },
		query: gql`
			query GET_QUANT_QUAL_BLOB_URL(
				$blobType: ReportBlobType!
				$idChoiceQuestion: UUID!
				$idSegment: UUID!
				$idStudy: UUID!
				$idStudyObject: UUID!
			) {
				study(idStudy: $idStudy) {
					idStudy
					dataBlobSignedUrl(
						blobType: $blobType
						idChoiceQuestion: $idChoiceQuestion
						idSegment: $idSegment
						idStudyObject: $idStudyObject
					) {
						idSegment
						idStudyDataSignedUrl
						idStudyObject
						idChoiceQuestion
						blobType
						url
					}
				}
			}
		`,
	})
}

export const GET_BLOBS_SIGNED_URLS = (idsStudies, blobs) => {
	let queryString = ''

	idsStudies
		.filter(idStudy => blobs[idStudy] !== undefined)
		.forEach(idStudy => {
			queryString = `
			${queryString}
			${getStudyBlobsQueryString(idStudy, blobs[idStudy])}
		`
		})

	// no blobs to load
	// query is skipped but we're calling gql few lines below so we need
	// a valid query string
	if (queryString === '') {
		queryString = `
			heartbeat
		`
	}

	return prepareQuery({
		query: gql`
			query GET_BLOBS_SIGNED_URLS {
				${queryString}
			}
		`,
	})
}

export const LOAD_RESPONDENT_SOURCES_NAMES = idAccount =>
	prepareQuery({
		variables: {
			idAccount,
		},
		query: gql`
			query LOAD_RESPONDENT_SOURCES_NAMES($idAccount: UUID!) {
				studies(idAccount: $idAccount) {
					idStudy
					label
					respondentSources {
						idRespondentSource
						label
					}
				}
			}
		`,
	})

export const KEYWORD_SEARCH = (idStudy, idStudyObject, keywords, includeSynonyms) => {
	return prepareQuery({
		variables: {
			idStudy,
			input: { idStudyObject, keywords, includeSynonyms },
		},
		query: gql`
			query KEYWORD_SEARCH($idStudy: UUID!, $input: KeywordSearchInput!) {
				study(idStudy: $idStudy) {
					idStudy
					answerManager {
						keywordSearch(input: $input) {
							idStudyObject
							matches {
								keyword
								idsMatchedStatements
							}
						}
					}
				}
			}
		`,
	})
}

export const GET_IMPORTED_MODULES = idStudy =>
	prepareQuery({
		variables: { idStudy },
		query: gql`
			query GET_IMPORTED_MODULES($idStudy: UUID!) {
				study(idStudy: $idStudy) {
					idStudy
					importedModules
				}
			}
		`,
	})

export const GET_SEGMENTS = idStudy =>
	prepareQuery(
		{
			variables: { idStudy: idStudy },
			query: gql`
				query GET_SEGMENTS($idStudy: UUID!) {
					study(idStudy: $idStudy) {
						idStudy
						segments {
							...segment
						}
					}
				}
			`,
			// options: { pollInterval: 10 * 1000 },
		},
		[segment],
	)

export const GET_STUDY_QUANT_QUAL_SLIDES = idStudy =>
	prepareQuery({
		variables: { idStudy: idStudy },
		query: gql`
			query GET_STUDY_QUANT_QUAL_SLIDES($idStudy: UUID!) {
				study(idStudy: $idStudy) {
					idStudy
					quantQualSlides {
						idQuantQualSlide
						idSlide
						settings {
							title
							idChoiceQuestion
							idAIOpenEndQuestion
						}
					}
				}
			}
		`,
	})
