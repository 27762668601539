export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'आगे बढ़ें',
	allocation_option: 'आवंटन विकल्प',
	choice_option: 'चयन विकल्प',
	submit: 'प्रस्तुत करें',
	freetext_date_placeholder: 'एक तारीख दर्ज करें',
	freetext_date_inputHint: 'जैसे mm/dd/वर्ष',
	freetext_phone_placeholder: 'फोन नंबर दर्ज करें',
	freetext_prhone_inputHint: 'जैसे 000-123-4567',
	freetext_email_placeholder: 'अपना ईमेल दर्ज करें',
	freetext_email_inputHint: 'जैसे my@mail.com',
	freetext_zip_placeholder: 'अपना ZIP कोड दर्ज करें',
	freetext_zip_inputHint: 'जैसे 12345',
	freetext_answer_placeholder: 'अपना उत्तर दर्ज करें',
	freetext_integer_inputHint: 'जैसे 123',
	freetext_number_inputHint: 'जैसे 1.5',
	freetext_amount_placeholder: 'राशि दर्ज करें',
	freetext_amount_inputHint: 'जैसे 1000.00',
	matrix_question: 'मैट्रिक्स प्रश्न',
	matrix_loop_question:
		'हर सूची आइटम के लिए अनूठा प्रश्न प्रस्तुत करने के लिए प्रश्न पाठ में [[item]] को शामिल करना सुनिश्चित करें',
	maxdiff_option: 'आइटम',
	maxdiff_best_question: 'आपकी राय में, इनमें से कौन सा सबसे महत्वपूर्ण गुण है?',
	maxdiff_worst_question: 'आपकी राय में, इनमें से कौन सा सबसे कम महत्वपूर्ण गुण है?',
	new_message: 'नया संदेश',
	ranking_option: 'रैंकिंग विकल्प',
	question_default: 'अपना सवाल लिखें',
	none_of_these: 'इनमें से कोई नहीं',
	study_languages_language_choice_message: 'आपकी पसंदीदा भाषा क्या है?',
	ar: 'अरबी',
	zh: 'चीनी',
	da: 'डैनिश',
	nl: 'डच',
	en: 'अंग्रेज़ी',
	fr: 'फ़्रेंच',
	de: 'जर्मन',
	ha: 'हौसा',
	hi: 'हिंदी',
	ig: 'इग्बो',
	id: 'इंडोनेशियाई',
	it: 'इतालवी',
	ja: 'जापानी',
	ko: 'कोरियाई',
	pl: 'पोलिश',
	pt: 'पुर्तगाली',
	ro: 'रोमानियाई',
	ru: 'रूसी',
	sk: 'स्लोवाक',
	es: 'स्पेनिश',
	sv: 'स्वीडिश',
	tl: 'टैगालोग',
	th: 'थाई',
	tr: 'तुर्की',
	yo: 'योरूबा',
}
