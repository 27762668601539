import {
	VISUAL_FLOW_INVALID_MODULE_TYPES,
	LIST_INPUT_TYPE,
	VISUAL_FLOW_MODULE_TYPES,
	LIST_FILTER_RULE_TYPES,
} from 'constants/studyDesign'

import getUpperModuleIds from 'routes/_study/StudyDesign/_store/helpers/getUpperModuleIds'

import { findSelectedList } from 'helpers/visualFlowModules/findSelectedList'
import { numberConditionSelectionTypes } from 'helpers/conditionBuilder/getNumberConditionSelectionTypeOptions'

const getHasEmptyListName = module =>
	module.definition.inputType === LIST_INPUT_TYPE.LIST && module.definition.idInputList === ''

const getHasInvalidListSelected = (module, selectedList) => {
	if (module.definition.inputType !== LIST_INPUT_TYPE.LIST) {
		return false
	}

	if (getHasEmptyListName(module) === true) {
		return false
	}

	return selectedList === undefined
}

const getHasInvalidMatrixFilterRule = (module, modules, idsUpperModules) => {
	return module.definition.filterRules.some(rule => {
		if (rule.ruleType !== LIST_FILTER_RULE_TYPES.MATRIX) {
			return false
		}

		if (module.definition.inputType === LIST_INPUT_TYPE.FILE) {
			return true
		}

		if (rule.idMatrix === '') {
			return true
		}

		if (idsUpperModules.includes(rule.idMatrix) === false) {
			return true
		}

		const selectedMatrix = modules[rule.idMatrix]

		if (selectedMatrix === undefined) {
			return true
		}

		if (selectedMatrix.isHidden === true) {
			return true
		}

		if (selectedMatrix.definition.listSettings.idInputList !== module.definition.idInputList) {
			return true
		}

		if (rule.options.length === 0) {
			return true
		}

		return false
	})
}

const getHasInvalidCommunityRule = (module, modules, communityModules) => {
	return module.definition.filterRules.some(rule => {
		if (rule.ruleType !== LIST_FILTER_RULE_TYPES.COMMUNITY) {
			return false
		}

		if (rule.communityKey === '') {
			return true
		}

		if (rule.listKey === '') {
			return true
		}

		const selectedCommunityModule = communityModules.find(
			communitModule => communitModule.id === rule.communityKey,
		)

		if (selectedCommunityModule === undefined) {
			return true
		}

		return false
	})
}

const getHasInvalidQuestionRule = (module, modules, idsUpperModules, selectedList) => {
	return module.definition.filterRules.some(rule => {
		if (rule.ruleType !== LIST_FILTER_RULE_TYPES.QUESTION) {
			return false
		}

		if (idsUpperModules.includes(rule.idModule) === false) {
			return true
		}

		const selectedModule = modules[rule.idModule]

		if (selectedModule === undefined) {
			return true
		}

		if (selectedModule.isHidden === true) {
			return true
		}

		if (selectedModule.type !== VISUAL_FLOW_MODULE_TYPES.A_CHOICE) {
			return true
		}

		if (
			selectedModule.definition.dynamicOptionsSettings.isActive === false &&
			rule.listKey === ''
		) {
			return true
		}

		if (selectedModule.definition.dynamicOptionsSettings.isActive === false) {
			const selectedColumn = selectedList.definition.columns.find(
				column => column.key === rule.listKey,
			)

			if (selectedColumn === undefined) {
				return true
			}
		}

		return false
	})
}

const getHasInvalidAllocationOrRankingQuestionRule = (module, modules, idsUpperModules) => {
	return module.definition.filterRules.some(rule => {
		if (
			rule.ruleType !== LIST_FILTER_RULE_TYPES.ALLOCATION_QUESTION &&
			rule.ruleType !== LIST_FILTER_RULE_TYPES.RANKING_QUESTION
		) {
			return false
		}

		if (idsUpperModules.includes(rule.idModule) === false) {
			return true
		}

		const selectedModule = modules[rule.idModule]

		if (selectedModule === undefined) {
			return true
		}

		if (selectedModule.isHidden === true) {
			return true
		}

		if (
			rule.ruleType === LIST_FILTER_RULE_TYPES.ALLOCATION_QUESTION &&
			selectedModule.type !== VISUAL_FLOW_MODULE_TYPES.ALLOCATION
		) {
			return true
		}

		if (
			rule.ruleType === LIST_FILTER_RULE_TYPES.RANKING_QUESTION &&
			selectedModule.type !== VISUAL_FLOW_MODULE_TYPES.RANKING
		) {
			return true
		}

		const hasNumberRule = numberConditionSelectionTypes.some(({ value }) => value === rule.function)

		if (hasNumberRule === true) {
			return isNaN(rule.value) === true || rule.value === ''
		}

		return false
	})
}

const getHasInvalidItemAttributeRule = (module, selectedList, upperLists) => {
	return module.definition.filterRules.some(rule => {
		if (rule.ruleType !== LIST_FILTER_RULE_TYPES.ITEM_ATTRIBUTE) {
			return false
		}

		if (rule.listKey === '') {
			return true
		}

		if (rule.function === null) {
			return true
		}

		const mainList =
			selectedList.definition.inputType === LIST_INPUT_TYPE.FILE
				? selectedList
				: findSelectedList(selectedList.definition.idInputList, upperLists)

		const selectedColumn = mainList.definition.columns.find(column => column.key === rule.listKey)

		if (selectedColumn === undefined) {
			return true
		}

		const hasNumberRule = numberConditionSelectionTypes.some(({ value }) => value === rule.function)

		if (hasNumberRule === true) {
			return isNaN(rule.value) === true || rule.value === ''
		}

		return false
	})
}

const validateList = (module, modules, orderModule, flatOrder, communityModules, languages) => {
	const validationResult = []

	if (module.definition.inputType === null) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_input_type_empty,
		})
	}

	if (
		module.definition.inputType === LIST_INPUT_TYPE.FILE &&
		module.definition.items.length === 0
	) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_empty,
		})
	}

	if (
		module.definition.inputType === LIST_INPUT_TYPE.FILE &&
		languages.some(({ language }) => module.definition.optionLabelIdentifiers[language] === '')
	) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_empty_option_label_identifier,
		})
	}

	const hasEmptyListName = getHasEmptyListName(module)

	if (hasEmptyListName === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_filter_name_empty,
		})

		return validationResult
	}

	const idsUpperModules = getUpperModuleIds(orderModule.path, flatOrder, modules)

	const upperModules = idsUpperModules.map(id => modules[id])

	const upperLists = upperModules.filter(
		module => VISUAL_FLOW_MODULE_TYPES.LIST === module.type && module.isHidden !== true,
	)

	const selectedList =
		module.definition.inputType === LIST_INPUT_TYPE.FILE
			? module
			: upperLists.find(list => list.definition.id === module.definition.idInputList)

	const hasInvalidListSelected = getHasInvalidListSelected(module, selectedList)

	if (hasInvalidListSelected === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_filter_invalid_selected,
		})

		return validationResult
	}

	const hasInvalidMatrixRule = getHasInvalidMatrixFilterRule(module, modules, idsUpperModules)

	if (hasInvalidMatrixRule === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_filter_invalid_matrix_rule,
		})
	}

	const hasInvalidCommunityRule = getHasInvalidCommunityRule(module, modules, communityModules)

	if (hasInvalidCommunityRule === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_filter_invalid_community_rule,
		})
	}

	const hasInvalidQuestionRule = getHasInvalidQuestionRule(
		module,
		modules,
		idsUpperModules,
		selectedList,
	)

	if (hasInvalidQuestionRule === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_filter_invalid_question_rule,
		})
	}

	const hasInvalidAllocationOrRankingQuestionRule = getHasInvalidAllocationOrRankingQuestionRule(
		module,
		modules,
		idsUpperModules,
	)

	if (hasInvalidAllocationOrRankingQuestionRule === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_filter_invalid_allocation_question_rule,
		})
	}

	const hasInvalidItemAttributeRule = getHasInvalidItemAttributeRule(
		module,
		selectedList,
		upperLists,
	)

	if (hasInvalidItemAttributeRule === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_filter_invalid_item_attribute_rule,
		})
	}

	return validationResult
}

export default validateList
