const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	$id: 'http://groupsolver.com/v1/OEQ.json',
	properties: {
		elaborate: {
			additionalProperties: false,
			$id: '/properties/elaborate',
			properties: {
				idPriorMessage: {
					$id: '/properties/elaborate/properties/idPriorMessage',
					pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
					type: ['string', 'null'],
				},
				show: {
					default: true,
					$id: '/properties/elaborate/properties/show',
					type: 'boolean',
				},
				minEvaluations: {
					minimum: 0,
					default: 8,
					$id: '/properties/elaborate/properties/minEvaluations',
					type: 'number',
				},
				maxEvaluations: {
					minimum: 0,
					default: 12,
					$id: '/properties/elaborate/properties/maxEvaluations',
					type: 'number',
				},
			},
			required: ['idPriorMessage', 'show', 'minEvaluations', 'maxEvaluations'],
			type: 'object',
		},
		eliminate: {
			additionalProperties: false,
			$id: '/properties/eliminate',
			type: 'object',
			required: ['idPriorMessage', 'showGrammar', 'showSuggestions', 'useAutoElimination'],
			properties: {
				idPriorMessage: {
					$id: '/properties/eliminate/properties/idPriorMessage',
					type: ['string', 'null'],
				},
				showGrammar: {
					default: true,
					$id: '/properties/eliminate/properties/showGrammar',
					type: 'boolean',
				},
				showSuggestions: {
					default: true,
					$id: '/properties/eliminate/properties/showSuggestions',
					type: 'boolean',
				},
				useAutoElimination: {
					default: true,
					$id: '/properties/eliminate/properties/useAutoElimination',
					type: 'boolean',
				},
			},
		},
		match: {
			type: 'object',
		},
		idRelatedMessage: {
			$id: '/properties/idRelatedMessage',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			type: ['string', 'null'],
		},
		enableGibberishFilter: {
			default: true,
			$id: '/properties/enableGibberishFilter',
			type: 'boolean',
		},
		enableProfanityFilter: {
			default: true,
			$id: '/properties/enableProfanityFilter',
			type: 'boolean',
		},
		enableAutoTranslate: {
			default: true,
			$id: '/properties/enableAutoTranslate',
			type: 'boolean',
		},
		ideate: {
			additionalProperties: false,
			$id: '/properties/ideate',
			properties: {
				ideaPoolLimitShare: {
					minimum: 0,
					maximum: 1,
					default: null,
					$id: '/properties/ideate/properties/ideaPoolLimitShare',
					type: ['number', 'null'],
				},
				ideaPoolLimitNumber: {
					default: null,
					minimum: 0,
					maximum: 50000,
					$id: '/properties/ideate/properties/ideaPoolLimitNumber',
					type: ['integer', 'null'],
				},
				minIdeateLowerLimit: {
					minimum: 0,
					$id: '/properties/ideate/properties/minIdeateLowerLimit',
					type: ['integer', 'null'],
				},
				minIdeateUpperLimit: {
					minimum: 0,
					$id: '/properties/ideate/properties/minIdeateUpperLimit',
					type: ['integer', 'null'],
				},
				idPriorMessage: {
					$id: '/properties/ideate/properties/idPriorMessage',
					pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
					type: ['string', 'null'],
				},
				show: {
					default: true,
					$id: '/properties/ideate/properties/show',
					type: 'boolean',
				},
				disableSentenceSplitter: {
					default: false,
					$id: '/properties/ideate/properties/disableSentenceSplitter',
					type: 'boolean',
				},
			},
			required: [
				'idPriorMessage',
				'minIdeateUpperLimit',
				'minIdeateLowerLimit',
				'ideaPoolLimitShare',
				'ideaPoolLimitNumber',
				'show',
				'disableSentenceSplitter',
			],
			type: 'object',
		},
		preseededStatements: {
			$id: '/properties/preseededStatements',
			type: 'array',
			items: {
				type: 'string',
				$id: '/properties/preseededStatements/items',
			},
		},
		translations: {
			$id: '/properties/translations',
			type: 'object',
			additionalProperties: false,
			properties: {
				ideate_placeholder: {
					default: '',
					$id: '/properties/translations/properties/ideate_placeholder',
					type: 'object',
					additionalProperties: false,
					patternProperties: {
						'^[a-z]{2}$': {type: 'string'},
					},
				},
				elaborate_intro: {
					default: '',
					$id: '/properties/translations/properties/elaborate_intro',
					type: 'object',
					additionalProperties: false,
					patternProperties: {
						'^[a-z]{2}$': {type: 'string'},
					},
				},
				elaborate_agree_label: {
					default: '',
					$id: '/properties/translations/properties/elaborate_agree_label',
					type: 'object',
					additionalProperties: false,
					patternProperties: {
						'^[a-z]{2}$': {type: 'string'},
					},
				},
				elaborate_disagree_label: {
					default: '',
					$id: '/properties/translations/properties/elaborate_disagree_label',
					type: 'object',
					additionalProperties: false,
					patternProperties: {
						'^[a-z]{2}$': {type: 'string'},
					},
				},
				elaborate_indifferent_label: {
					default: '',
					$id: '/properties/translations/properties/elaborate_indifferent_label',
					type: 'object',
					additionalProperties: false,
					patternProperties: {
						'^[a-z]{2}$': {type: 'string'},
					},
				},
				elaborate_unclear_statement_label: {
					default: '',
					$id: '/properties/translations/properties/elaborate_unclear_statement_label',
					type: 'object',
					additionalProperties: false,
					patternProperties: {
						'^[a-z]{2}$': {type: 'string'},
					},
				},
			},
			required: [
				'ideate_placeholder',
				'elaborate_intro',
				'elaborate_agree_label',
				'elaborate_disagree_label',
				'elaborate_indifferent_label',
				'elaborate_unclear_statement_label',
			],
		},
		messages: {
			$id: '/properties/messages',
			type: 'array',
			additionalItems: false,
			uniqueItems: true,
			items: {
				$id: '/properties/messages/items',
				type: 'object',
				additionalProperties: false,
				required: ['id', 'definition', 'type'],
				properties: {
					id: {
						$id: '/properties/messages/items/id',
						type: 'string',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
					},
					type: {
						$id: '/properties/messages/items/type',
						type: 'string',
						enum: ['A_MESSAGE'],
					},
					definition: {
						$id: '/properties/messages/items/definition',
						$ref: 'http://groupsolver.com/v1/MESSAGE.json',
					},
				},
			},
		},
	},
	required: [
		'elaborate',
		'idRelatedMessage',
		'ideate',
		'eliminate',
		'enableGibberishFilter',
		'enableProfanityFilter',
		'enableAutoTranslate',
		'preseededStatements',
		'translations',
		'messages',
	],
	type: 'object',
}

export default schema
