import _ from 'lodash'

import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { CONDITION_LANGUAGE } from 'constants/conditionBuilder'

import { getStudyObjectDefinition } from 'helpers/conditionBuilder/getStudyObjectDefinition'
import { getVisibleOptions } from 'helpers/visualFlowModules/getVisibleOptions'
import isChoiceModule from 'helpers/visualFlowModules/isChoiceModule'
import getIsAllocationModule from 'helpers/visualFlowModules/getIsAllocationModule'
import getIsRankingModule from 'helpers/visualFlowModules/getIsRankingModule'

export const getQuestionSelectOptions = (studyObject, activeLanguage) => {
	if (_.isNil(studyObject) === true) {
		return []
	}

	return getVisibleOptions(getStudyObjectDefinition(studyObject).options).map(o => ({
		label: o.label[activeLanguage],
		value: o.id,
	}))
}

const getFormattedOptionForSelect = (intl, studyObjectOption) => {
	if (studyObjectOption === undefined) {
		return {
			label: intl.formatMessage({ id: 'option_no_longer_exists' }),
			isValid: false,
			value: null,
		}
	}

	return {
		label: studyObjectOption.label,
		value: studyObjectOption.value,
	}
}

export const getQuestionSelectedOptionsValues = (
	intl,
	selectedValue,
	studyObject,
	activeLanguage,
) => {
	const selectOptions = getQuestionSelectOptions(studyObject, activeLanguage)

	if (
		isChoiceModule(studyObject) === true ||
		studyObject.type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE ||
		studyObject.type === CONDITION_LANGUAGE
	) {
		if (Array.isArray(selectedValue) === false) {
			return []
		}

		return selectedValue.map(id => {
			const studyObjectOption = selectOptions.find(o => o.value === id)

			return getFormattedOptionForSelect(intl, studyObjectOption)
		})
	}

	if (getIsAllocationModule(studyObject) === true || getIsRankingModule(studyObject) === true) {
		const studyObjectOption = selectOptions.find(o => o.value === selectedValue)

		return getFormattedOptionForSelect(intl, studyObjectOption)
	}

	throw new Error(`Cannot find options for ${studyObject.type} study object.`)
}
