import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'

import { CONDITION_TYPES } from 'constants/conditionBuilder'
import {
	hasTooFewOptionsSelected,
	hasTooManyOptionsSelected,
} from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateUICommand'
import {
	getQuestionSelectedOptionsValues,
	getQuestionSelectOptions,
} from 'helpers/conditionBuilder/getQuestionOptionsValues'

import IncludedStatements from 'components/_formik/_complex/IncludedStatements'

import classes from './ChoiceAnswerMultiselect.module.scss'

const getTooManyOptionsErrorMessage = studyObject => {
	if (studyObject?.isSegmentationTypingTool === true) {
		return 'choice_options_multiselect.segmentation_typing_tool.tooManyOptions'
	}

	if (studyObject?.isLanguage === true) {
		return 'choice_options_multiselect.tooManyLanguages'
	}

	return 'choice_options_multiselect.tooManyOptions'
}

const getNoOptionsErrorMessage = studyObject => {
	if (studyObject?.isSegmentationTypingTool === true) {
		return 'choice_options_multiselect.segmentation_typing_tool.no_segments_selected'
	}

	if (studyObject?.isLanguage === true) {
		return 'choice_options_multiselect.no_languages_selected'
	}

	return 'choice_options_multiselect.no_options_selected'
}

const renderError = (intl, idTranslation) => (
	<span className="title-error">{intl.formatMessage({ id: idTranslation })}</span>
)

const ChoiceAnswerMultiselect = ({
	activeLanguage,
	disabled,
	name,
	onChange,
	selectionType,
	studyObject,
	value,
	shouldValidate = true,
}) => {
	const intl = useIntl()

	const conditionObject = {
		type: studyObject?.isLanguage === true ? CONDITION_TYPES.LANGUAGE : CONDITION_TYPES.CONDITION,
		left: value,
		selection: {
			type: selectionType,
		},
	}

	const isTooManyOptionsSelected =
		shouldValidate === true &&
		studyObject !== undefined &&
		hasTooManyOptionsSelected(conditionObject, studyObject) === true

	const isTooFewOptionsSelected =
		shouldValidate === true &&
		studyObject !== undefined &&
		hasTooFewOptionsSelected(conditionObject, studyObject) === true

	return (
		<div data-id={`${name}-selected-options`} className={classes['selected-options']}>
			<IncludedStatements
				disabled={disabled}
				fieldName={name}
				label={{ showLabel: false, errorLabel: intl.formatMessage({ id: 'options' }) }}
				maxHeight={75}
				onChange={onChange}
				options={getQuestionSelectOptions(studyObject, activeLanguage)}
				value={getQuestionSelectedOptionsValues(intl, value, studyObject, activeLanguage)}
			/>

			{value.length === 0 && renderError(intl, getNoOptionsErrorMessage(studyObject))}
			{isTooManyOptionsSelected === true &&
				renderError(intl, getTooManyOptionsErrorMessage(studyObject))}
			{value.length !== 0 &&
				isTooFewOptionsSelected === true &&
				renderError(intl, 'choice_options_multiselect.tooFewOptions')}
		</div>
	)
}

ChoiceAnswerMultiselect.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	selectionType: PropTypes.string.isRequired,
	studyObject: PropTypes.object.isRequired,
	value: PropTypes.array.isRequired,
}

export default ChoiceAnswerMultiselect
