export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: "Gaa n'ihu",
	allocation_option: 'Nhọrọ nkesa',
	choice_option: 'Nhọrọ nhọrọ',
	submit: 'Zipu',
	freetext_date_placeholder: 'Tinye ụbọchị',
	freetext_date_inputHint: 'e.g. mm/dd/afọ',
	freetext_phone_placeholder: 'Tinye nọmba ekwentị',
	freetext_prhone_inputHint: 'e.g. 000-123-4567',
	freetext_email_placeholder: 'Tinye email gị',
	freetext_email_inputHint: 'e.g. my@mail.com',
	freetext_zip_placeholder: 'Tinye koodu ZIP',
	freetext_zip_inputHint: 'e.g. 12345',
	freetext_answer_placeholder: 'Tinye azịza gị',
	freetext_integer_inputHint: 'e.g. 123',
	freetext_number_inputHint: 'e.g. 1.5',
	freetext_amount_placeholder: 'Tinye ego',
	freetext_amount_inputHint: 'e.g. 1000.00',
	matrix_question: 'Ajụjụ matrix',
	matrix_loop_question:
		'Mee ka o doo anya na ị gụnyere [[item]] na ajụjụ iji gosi ajụjụ pụrụ iche maka ihe ndepụta ọ bụla',
	maxdiff_option: 'Ihe',
	maxdiff_best_question: "N'echiche gị, nke ndị a bụ **kasị** mkpa njirimara?",
	maxdiff_worst_question: "N'echiche gị, nke ndị a bụ **dịkarịrị** mkpa njirimara?",
	new_message: 'Ozi ọhụrụ',
	ranking_option: 'Nhọrọ ogo',
	question_default: 'Dee ajụjụ gị',
	none_of_these: 'Ọ dịghị nke ndị a',
	study_languages_language_choice_message: 'Kedu asụsụ ị na-ahọrọ?',
	ar: 'Asụsụ Arabic',
	zh: 'Asụsụ Chinese',
	da: 'Asụsụ Danish',
	nl: 'Asụsụ Dutch',
	en: 'Asụsụ Bekee',
	fr: 'Asụsụ French',
	de: 'Asụsụ German',
	ha: 'Asụsụ Hausa',
	hi: 'Asụsụ Hindi',
	ig: 'Asụsụ Igbo',
	id: 'Asụsụ Indonesian',
	it: 'Asụsụ Italian',
	ja: 'Asụsụ Japanese',
	ko: 'Asụsụ Korean',
	pl: 'Asụsụ Polish',
	pt: 'Asụsụ Portuguese',
	ro: 'Asụsụ Romanian',
	ru: 'Asụsụ Russian',
	sk: 'Asụsụ Slovak',
	es: 'Asụsụ Spanish',
	sv: 'Asụsụ Swedish',
	tl: 'Asụsụ Tagalog',
	th: 'Asụsụ Thai',
	tr: 'Asụsụ Turkish',
	yo: 'Asụsụ Yoruba',
}
