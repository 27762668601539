import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

export const getOptionLabelIdTranslation = (moduleType, isNoneOfThese) => {
	if (isNoneOfThese === true) {
		return 'none_of_these'
	}

	if (moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF) {
		return 'maxdiff_option'
	}

	if (moduleType === VISUAL_FLOW_MODULE_TYPES.ALLOCATION) {
		return 'allocation_option'
	}

	if (moduleType === VISUAL_FLOW_MODULE_TYPES.RANKING) {
		return 'ranking_option'
	}

	return 'choice_option'
}
