import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'
import { getVisibleOptions } from 'helpers/visualFlowModules/getVisibleOptions'

import validateMessage from './validateMessage'
import {
	hasEmptyChoiceOption,
	hasNonUniqueOptionLabels,
	hasNonUniqueOptionSimpleNames,
	hasInvalidImageOption,
} from './validateChoice'

const validateMaxDiff = (module, studyTags, languages) => {
	const validationResult = []

	validateMessage(module.definition.bestQuestion, studyTags, languages).forEach(error => {
		validationResult.push({
			...error,
			id: module.definition.id,
		})
	})

	validateMessage(module.definition.worstQuestion, studyTags, languages).forEach(error => {
		validationResult.push({
			...error,
			id: module.definition.id,
		})
	})

	if (
		module.definition.numberOfItemsPerRound > getVisibleOptions(module.definition.options).length
	) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.max_diff_too_few_options,
		})
	}

	if (module.definition.numberOfItemsPerRound < 2) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.max_diff_too_few_items_per_round,
		})
	}

	if (hasEmptyChoiceOption(module, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_emptyOptions,
		})
	}

	if (hasNonUniqueOptionLabels(module, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_nonUniqueOptions,
		})
	}

	if (hasNonUniqueOptionSimpleNames(module, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_nonUniqueSimpleNames,
		})
	}

	if (hasInvalidImageOption(module, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_noImage,
		})
	}

	return validationResult
}

export default validateMaxDiff
