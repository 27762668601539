import { MATRIX_QUESTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MATRIX_QUESTION'
import { replaceItemInText } from 'helpers/visualFlowModules/replaceItemInText'
import { sanitizeMatrixAttributesShortName } from 'helpers/visualFlowModules/sanitizeMatrixAttributesShortName'

/**
 * @param {*} list
 * @param {string} attributeShortNameColumn
 * @param {{[languageCode: string]: string}} text
 * @param {[{[languageCode: string]: string}]} languages
 * @returns
 */
export const createMatrixAttributesFromList = (list, attributeShortNameColumn, text, languages) =>
	list.definition.items.map(item => {
		const itemLabel = item[attributeShortNameColumn]

		const question = MATRIX_QUESTION({
			shortName: sanitizeMatrixAttributesShortName(itemLabel),
			text: '',
			languages,
			idListItem: item.gsTagIdItem,
		})

		languages.forEach(({ language }) => {
			question.text[language] = replaceItemInText(
				text[language],
				itemLabel,
				item,
				list.definition.columns,
			)
		})

		return question
	})
