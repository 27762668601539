export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Продолжить',
	allocation_option: 'Опция распределения',
	choice_option: 'Опция выбора',
	submit: 'Отправить',
	freetext_date_placeholder: 'Введите дату',
	freetext_date_inputHint: 'например, дд/мм/гггг',
	freetext_phone_placeholder: 'Введите номер телефона',
	freetext_prhone_inputHint: 'например, 000-123-4567',
	freetext_email_placeholder: 'Введите ваш email',
	freetext_email_inputHint: 'например, my@mail.com',
	freetext_zip_placeholder: 'Введите ваш почтовый индекс',
	freetext_zip_inputHint: 'например, 12345',
	freetext_answer_placeholder: 'Введите ваш ответ',
	freetext_integer_inputHint: 'например, 123',
	freetext_number_inputHint: 'например, 1.5',
	freetext_amount_placeholder: 'Введите сумму',
	freetext_amount_inputHint: 'например, 1000.00',
	matrix_question: 'Матрица вопрос',
	matrix_loop_question:
		'Убедитесь, что вы включили [[item]] в текст вопроса, чтобы представить уникальный вопрос для каждого элемента списка',
	maxdiff_option: 'Элемент',
	maxdiff_best_question: 'На ваш взгляд, какие из этих атрибутов являются **самыми** важными?',
	maxdiff_worst_question: 'На ваш взгляд, какие из этих атрибутов являются **наименее** важными?',
	new_message: 'Новое сообщение',
	ranking_option: 'Опция ранжирования',
	question_default: 'Введите ваш вопрос',
	none_of_these: 'Ни одно из этих',
	study_languages_language_choice_message: 'Какой язык вы предпочитаете?',
	ar: 'Арабский',
	zh: 'Китайский',
	da: 'Датский',
	nl: 'Нидерландский',
	en: 'Английский',
	fr: 'Французский',
	de: 'Немецкий',
	ha: 'Хауса',
	hi: 'Хинди',
	ig: 'Игбо',
	id: 'Индонезийский',
	it: 'Итальянский',
	ja: 'Японский',
	ko: 'Корейский',
	pl: 'Польский',
	pt: 'Португальский',
	ro: 'Румынский',
	ru: 'Русский',
	sk: 'Словацкий',
	es: 'Испанский',
	sv: 'Шведский',
	tl: 'Тагалог',
	th: 'Тайский',
	tr: 'Турецкий',
	yo: 'Йоруба',
}
