import { v4 as uuidv4 } from 'uuid'
import MEDIA_OBJECT from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MEDIA_OBJECT'
import { CHOICE_SUBTYPE, ALLOCATION_SUBTYPE } from 'constants/studyDesign'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'

const getDefaultOption = (
	subtype,
	isNoneOfThese,
	code,
	label,
	showLabel,
	languages,
	idListItem,
	languageCode,
) => ({
	id: uuidv4(),
	label,
	randomize: false,
	isNoneOfThese: isNoneOfThese,
	showLabel,
	media: subtype === CHOICE_SUBTYPE.IMAGE ? MEDIA_OBJECT({ isNoneOfThese, languages }) : null,
	code,
	simpleName: getLanguagesLabelsObject(languages, ''),
	isUnsaved: true,
	isHidden: false,
	idListItem,
	languageCode,
})

/**
 *
 * @param {string} subtype
 * @param {boolean} isNoneOfThese
 * @param {number} code
 * @param {{[languageCode: string]: string}} label
 * @param {boolean} showLabel
 * @param {[{language: string, [key]: any}]} languages
 * @param {string} idListItem
 * @returns
 */
export const CHOICE_OPTION = (
	subtype,
	isNoneOfThese,
	code,
	label,
	showLabel,
	languages,
	idListItem,
	languageCode,
) => {
	const option = getDefaultOption(
		subtype,
		isNoneOfThese,
		code,
		label,
		showLabel,
		languages,
		idListItem,
		languageCode,
	)

	// add allocation option fields
	if (subtype === ALLOCATION_SUBTYPE.INPUT) {
		option.defaultValue = 0
	}

	return option
}
