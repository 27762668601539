/**
 * Imports
 */
import _ from 'lodash'
import { tools } from 'constants/tools'
import { filterData, sortData } from 'helpers/sortAndFilter'
import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { getDecimalPointNumberFromLeadingZeroNumber } from 'helpers/reportBuilder/getDecimalPointNumberFromLeadingZeroNumber'
import { getOptionLabel } from 'helpers/reports/getOptionLabel'
import { assignOptionColors } from 'helpers/reports/assignOptionColors'

import {
	getSegmentTotalSupportPercentageKey,
	getSegmentTotalSupportCompletesKey,
	getSegmentTotalSupportDecimalPercentageKey,
} from 'store/reportBuilder'
import { REPORT_TYPES, REPORT_BLOB_TYPES } from 'constants/reports'

/**
 * Munge Functions
 */
const mungeIdeas = (options, idsSegments, legendState, slideSettings) => {
	const chartOptions = options.map(option => {
		const percentAndCompletes = _.flatten(
			idsSegments.map(idSegment => {
				const percent = option[getSegmentTotalSupportDecimalPercentageKey(idSegment)]
				const completes = option[getSegmentTotalSupportCompletesKey(idSegment)]

				return [percent, completes]
			}),
		)

		const customColor = _.get(slideSettings, `customColors.${option.idOption}`, null)

		const formattedOption = {
			..._.pick(
				option,
				_.flatMap(idsSegments, idSegment => [
					getSegmentTotalSupportPercentageKey(idSegment),
					getSegmentTotalSupportCompletesKey(idSegment),
				]),
			),
			letter: option.letter,
			name: option.label,
			code: option.code,
			themeName: null,
			fill: customColor ?? option.color,
			hasCustomColor: customColor !== null,
			id: option.idOption,
			shown: true,
			unit: '%',
			toExport: () => [option.letter, option.label, ...percentAndCompletes, option.code],
			aiDescription: {
				label: option.label,
			},
		}

		idsSegments.forEach(idSegment => {
			formattedOption.aiDescription[idSegment] =
				formattedOption[getSegmentTotalSupportPercentageKey(idSegment)]
		})

		return formattedOption
	})

	return sortData(filterData(chartOptions, legendState.filtered), legendState.sorted)
}

/**
 * Calculators
 */
const getSegmentOption = (idOption, segmentData) =>
	segmentData.options.find(option => option.idOption === idOption)

const getAnswerPercentage = (fraction, whole) =>
	whole > 0 ? Math.round(Number(Math.round((fraction / whole) * 10000) / 100)) : 0

export const calculateLegendIdeas = (
	idStudy,
	reportType,
	datasetOptions,
	slideSettings,
	legendState,
	blobData,
	chartColorSettings,
	language,
) => {
	if (reportType !== REPORT_TYPES.CHOICE_ANSWERS) {
		return []
	}

	if (slideSettings === undefined) return []

	const { idStudyObject, idsSegments, options: hiddenOptions } = slideSettings

	const dataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.CHOICE_STATISTICS, idSegment)
			],
	)
	const totalSegmentDataToCheck =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.CHOICE_STATISTICS,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	if (hasBlobData([...dataArray, totalSegmentDataToCheck], 'options') === false) {
		return []
	}

	const totalSegmentData = assignOptionColors(
		totalSegmentDataToCheck,
		chartColorSettings,
		'options',
	)

	const dataset = datasetOptions.find(selector => selector.value === idStudyObject)

	const newOptions = dataset.options
		.map(option => {
			const totalSegmentOption = getSegmentOption(option.id, totalSegmentData)

			const chartOption = {
				idOption: option.id,
				color: totalSegmentOption.color,
				letter: totalSegmentOption.letter,
				label: getOptionLabel(option, slideSettings, language),
				code: option.code,
			}

			idsSegments.forEach(idSegment => {
				const segmentData =
					blobData[
						getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.CHOICE_STATISTICS, idSegment)
					]
				const segmentOption = getSegmentOption(option.id, segmentData)

				const percentage = getAnswerPercentage(
					segmentOption.respondentsCount,
					segmentData.respondentsCount,
				)
				const percentageDecimal = getDecimalPointNumberFromLeadingZeroNumber(
					segmentOption.respondentsCount / segmentData.respondentsCount,
				)

				chartOption[getSegmentTotalSupportCompletesKey(idSegment)] = Number(
					segmentOption.respondentsCount,
				)
				chartOption[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = Number(
					percentageDecimal,
				)
				chartOption[getSegmentTotalSupportPercentageKey(idSegment)] = Number(percentage)
			})

			return chartOption
		})
		.filter(option => option !== null && hiddenOptions.includes(option.idOption) === false)

	return mungeIdeas(newOptions, idsSegments, legendState, slideSettings)
}
