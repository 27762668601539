import { CHOICE_OPTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/CHOICE_OPTION'

export const createChoiceOptionFromListItem = (
	item,
	itemIndex,
	optionLabelIdentifiers,
	optionSubtype,
	languages,
) => {
	const label = languages.reduce(
		(acc, { language }) => ({
			...acc,
			[language]: item[optionLabelIdentifiers[language]],
		}),
		{},
	)

	const option = {
		...CHOICE_OPTION(
			optionSubtype,
			false,
			itemIndex + 1,
			label,
			false,
			languages,
			item.gsTagIdItem,
			null,
		),
		randomize: true,
	}

	return option
}

export const createChoiceOptionsFromList = (
	list,
	optionLabelIdentifiers,
	optionSubtype,
	languages,
) => {
	return list.definition.items.map((item, itemIndex) =>
		createChoiceOptionFromListItem(
			item,
			itemIndex,
			optionLabelIdentifiers,
			optionSubtype,
			languages,
		),
	)
}
