import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { handleCloseOnEscape } from 'helpers/closeOnEscape'

import StaticPortalOverlay from '../StaticPortalOverlay/StaticPortalOverlay'
import InteractiveBackground from '../InteractiveBackground'
import InteractiveContent from '../InteractiveContent'
import OverlayCloseButton from 'components/_scaffolding/OverlayCloseButton'

const StaticInteractiveOverlay = ({
	backgroundClassName,
	children,
	contentClassName,
	closeClassName,
	handleCloseClick,
	isCloseButtonHidden,
}) => {
	useEffect(() => {
		const handleKeyDown = event => handleCloseOnEscape(event, handleCloseClick)

		document.addEventListener('keydown', handleKeyDown, false)

		return () => {
			document.removeEventListener('keydown', handleKeyDown, false)
		}
	}, [handleCloseClick])

	return (
		<StaticPortalOverlay>
			<InteractiveBackground backgroundClassName={backgroundClassName}>
				<InteractiveContent contentClassName={contentClassName}>
					{children}
					{isCloseButtonHidden === false && (
						<OverlayCloseButton onClick={handleCloseClick} closeClassName={closeClassName} />
					)}
				</InteractiveContent>
			</InteractiveBackground>
		</StaticPortalOverlay>
	)
}

StaticInteractiveOverlay.defaultProps = {
	contentClassName: null,
	isCloseButtonHidden: false,
}
StaticInteractiveOverlay.propTypes = {
	backgroundClassName: PropTypes.string,
	children: PropTypes.node.isRequired,
	contentClassName: PropTypes.string,
	closeClassName: PropTypes.string,
	handleCloseClick: PropTypes.func.isRequired,
	isCloseButtonHidden: PropTypes.bool.isRequired,
}
export default StaticInteractiveOverlay
