export const EMAIL_NOTIFICATIONS_MODES = {
	DISABLED: 'DISABLED',
	INTERVAL: 'INTERVAL',
	DAILY: 'DAILY',
}

export const CARD_INFO_NOTIFICATION_TYPES = {
	CARD_ADDED: 'CARD_ADDED',
	CARD_CHANGED: 'CARD_CHANGED',
	CARD_REMOVED: 'CARD_REMOVED',
	BILLING_INFORMATION_UPDATED: 'BILLING_INFORMATION_UPDATED',
}

export const USER_NOTIFICATION_TYPES = {
	USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
	USER_ROLE_REMOVED: 'USER_ROLE_REMOVED',
}

export const STUDY_NOTIFICATION_TYPES = {
	STUDY_STATUS: 'STUDY_STATUS',
	STUDY_PROGRESS: 'STUDY_PROGRESS',
	STUDY_AUTO_CLOSE_REMINDER: 'STUDY_AUTO_CLOSE_REMINDER',
	STUDY_DELETE_INDICATION: 'STUDY_DELETE_INDICATION',
	STUDY_DELETE_REMINDER: 'STUDY_DELETE_REMINDER',
}

export const ACCOUNT_NOTIFICATION_TYPES = {
	ACCOUNT_PREPAID_RESPONDENTS_REACHED: 'ACCOUNT_PREPAID_RESPONDENTS_REACHED',
	ACCOUNT_TRIAL_EXPIRES_IN_5_DAYS: 'ACCOUNT_TRIAL_EXPIRES_IN_5_DAYS',
	ACCOUNT_TRIAL_EXPIRES_IN_1_DAY: 'ACCOUNT_TRIAL_EXPIRES_IN_1_DAY',
	ACCOUNT_TRIAL_EXPIRED: 'ACCOUNT_TRIAL_EXPIRED',
	ACCOUNT_WILL_BE_LOCKED_IN_5_DAYS: 'ACCOUNT_WILL_BE_LOCKED_IN_5_DAYS',
	ACCOUNT_WILL_BE_LOCKED_IN_1_DAY: 'ACCOUNT_WILL_BE_LOCKED_IN_1_DAY',
	ACCOUNT_WAS_LOCKED_AND_WILL_BE_DELETED: 'ACCOUNT_WAS_LOCKED_AND_WILL_BE_DELETED',
	ACCOUNT_WILL_BE_DELETED_IN_5_DAYS: 'ACCOUNT_WILL_BE_DELETED_IN_5_DAYS',
	ACCOUNT_WILL_BE_DELETED_IN_1_DAY: 'ACCOUNT_WILL_BE_DELETED_IN_1_DAY',
	ACCOUNT_WAS_DELETED: 'ACCOUNT_WAS_DELETED',
}

//subscriptions
export const SUBSCRIPTION_AUTO_RENEWAL_SWITCH_NOTIFICATIONS_TYPES = {
	SUBSCRIPTION_AUTO_RENEWAL_ENABLED: 'SUBSCRIPTION_AUTO_RENEWAL_ENABLED',
	SUBSCRIPTION_AUTO_RENEWAL_CANCELED: 'SUBSCRIPTION_AUTO_RENEWAL_CANCELED',
}

export const SUBSCRIPTION_AUTORENEWAL_ON_NOTIFICATION_TYPES = {
	SUBSCRIPTION_WILL_BE_RENEWED_IN_1_DAY: 'SUBSCRIPTION_WILL_BE_RENEWED_IN_1_DAY',
	SUBSCRIPTION_RENEWED: 'SUBSCRIPTION_RENEWED',
}

export const SUBSCRIPTION_AUTORENEWAL_OFF_NOTIFICATION_TYPES = {
	ACCOUNT_SUBSCRIPTION_EXPIRES_IN_5_DAYS: 'ACCOUNT_SUBSCRIPTION_EXPIRES_IN_5_DAYS',
	ACCOUNT_SUBSCRIPTION_EXPIRES_IN_1_DAY: 'ACCOUNT_SUBSCRIPTION_EXPIRES_IN_1_DAY',
	ACCOUNT_SUBSCRIPTION_EXPIRED: 'ACCOUNT_SUBSCRIPTION_EXPIRED',
}

export const SUBSCRIPTION_PAYMENTS_NOTIFICATION_TYPES = {
	PAYMENT_UNSUCCESSFUL: 'PAYMENT_UNSUCCESSFUL',
	SUBSCRIPTION_PAYMENT_SUCCESSFUL: 'SUBSCRIPTION_PAYMENT_SUCCESSFUL',
}

export const SUBSCRIPTION_NOTIFICATION_TYPES = {
	ACCOUNT_SUBSCRIPTION_WILL_BE_DELETED_IN_30_DAYS:
		'ACCOUNT_SUBSCRIPTION_WILL_BE_DELETED_IN_30_DAYS',
	ACCOUNT_SUBSCRIPTION_WILL_BE_DELETED_IN_5_DAYS: 'ACCOUNT_SUBSCRIPTION_WILL_BE_DELETED_IN_5_DAYS',
	ACCOUNT_SUBSCRIPTION_WILL_BE_DELETED_IN_1_DAY: 'ACCOUNT_SUBSCRIPTION_WILL_BE_DELETED_IN_1_DAY',
	ACCOUNT_SUBSCRIPTION_WAS_DELETED: 'ACCOUNT_SUBSCRIPTION_WAS_DELETED',
	BILLING_CYCLE_CHANGED: 'BILLING_CYCLE_CHANGED',
	...SUBSCRIPTION_AUTO_RENEWAL_SWITCH_NOTIFICATIONS_TYPES,
	...SUBSCRIPTION_PAYMENTS_NOTIFICATION_TYPES,
	...SUBSCRIPTION_AUTORENEWAL_ON_NOTIFICATION_TYPES,
	...SUBSCRIPTION_AUTORENEWAL_OFF_NOTIFICATION_TYPES,
}

// invitations

export const INVITATIONS_NOTIFICATION_TYPES = {
	INVITATION_CREATED: 'INVITATION_CREATED',
	INVITATION_CANCELED: 'INVITATION_CANCELED',
	INVITATION_EXPIRED: 'INVITATION_EXPIRED',
	USER_ACCEPT_INVITATION: 'USER_ACCEPT_INVITATION',
	USER_REJECT_INVITATION: 'USER_REJECT_INVITATION',
}

export const NOTIFICATION_DASHBOARD_STATUS = {
	NEW: 'NEW',
	SEEN: 'SEEN',
}

export const USER_ACTIONS = {
	MAKE_ADMIN: 'MAKE_ADMIN',
	MAKE_USER: 'MAKE_USER',
	REMOVE: 'REMOVE',
	LEAVE: 'LEAVE',
}

export const NOTIFICATIONS_PAGE_SIZE = 5

export const INTERNAL_USER_EMAIL_ENDING = '@groupsolver.com'
export const INTERNAL_TEST_USER_EMAIL_ENDING = '@test-groupsolver.com'

export const displayNameMinLength = 2
export const displayNameMaxLength = 50

export const fullNameMinLength = 3
export const fullNameMaxLength = 50

export const DEFAULT_USER_IMAGE_IDS = [
	'1bbbbbbb-bbbb-bbbb-bbbb-bbbbbbbbbbbb',
	'2bbbbbbb-bbbb-bbbb-bbbb-bbbbbbbbbbbb',
	'3bbbbbbb-bbbb-bbbb-bbbb-bbbbbbbbbbbb',
	'4bbbbbbb-bbbb-bbbb-bbbb-bbbbbbbbbbbb',
	'5bbbbbbb-bbbb-bbbb-bbbb-bbbbbbbbbbbb',
]
