export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Fortfahren',
	allocation_option: 'Zuweisungsoption',
	choice_option: 'Auswahloption',
	submit: 'Absenden',
	freetext_date_placeholder: 'Geben Sie ein Datum ein',
	freetext_date_inputHint: 'z.B. mm/tt/jahr',
	freetext_phone_placeholder: 'Geben Sie eine Telefonnummer ein',
	freetext_prhone_inputHint: 'z.B. 000-123-4567',
	freetext_email_placeholder: 'Geben Sie Ihre E-Mail ein',
	freetext_email_inputHint: 'z.B. mein@mail.com',
	freetext_zip_placeholder: 'Geben Sie Ihre Postleitzahl ein',
	freetext_zip_inputHint: 'z.B. 12345',
	freetext_answer_placeholder: 'Geben Sie Ihre Antwort ein',
	freetext_integer_inputHint: 'z.B. 123',
	freetext_number_inputHint: 'z.B. 1,5',
	freetext_amount_placeholder: 'Betrag eingeben',
	freetext_amount_inputHint: 'z.B. 1000,00',
	matrix_question: 'Matrix-Frage',
	matrix_loop_question:
		'Stellen Sie sicher, dass [[item]] im Fragetext enthalten ist, um eine einzigartige Frage für jedes Listenelement zu stellen',
	maxdiff_option: 'Element',
	maxdiff_best_question: 'Welche dieser Attribute sind Ihrer Meinung nach die **wichtigsten**?',
	maxdiff_worst_question: 'Welche dieser Attribute sind Ihrer Meinung nach die **unwichtigsten**?',
	new_message: 'Neue Nachricht',
	ranking_option: 'Rangfolge-Option',
	question_default: 'Geben Sie Ihre Frage ein',
	none_of_these: 'Keine von diesen',
	study_languages_language_choice_message: 'Was ist Ihre bevorzugte Sprache?',
	ar: 'Arabisch',
	zh: 'Chinesisch',
	da: 'Dänisch',
	nl: 'Niederländisch',
	en: 'Englisch',
	fr: 'Französisch',
	de: 'Deutsch',
	ha: 'Haussa',
	hi: 'Hindi',
	ig: 'Igbo',
	id: 'Indonesisch',
	it: 'Italienisch',
	ja: 'Japanisch',
	ko: 'Koreanisch',
	pl: 'Polnisch',
	pt: 'Portugiesisch',
	ro: 'Rumänisch',
	ru: 'Russisch',
	sk: 'Slowakisch',
	es: 'Spanisch',
	sv: 'Schwedisch',
	tl: 'Tagalog',
	th: 'Thailändisch',
	tr: 'Türkisch',
	yo: 'Yoruba',
}
