const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	type: 'object',
	$id: 'http://groupsolver.com/v1/MESSAGE.json',
	properties: {
		media: {
			$id: '/properties/media',
			oneOf: [{$ref: 'http://groupsolver.com/v1/MEDIA_OBJECT.json'}, {type: 'null'}],
		},
		text: {
			$id: '/properties/text',
			type: 'object',
			additionalProperties: false,
			patternProperties: {
				'^[a-z]{2}$': {type: 'string'},
			},
		},
		timeout: {
			default: 1000,
			$id: '/properties/timeout',
			minimum: 500,
			exclusiveMinimum: 0,
			type: 'integer',
		},
	},
	required: ['timeout', 'text', 'media'],
}

export default schema
