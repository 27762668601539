import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import classes from './InteractiveBackground.module.scss'

const InteractiveBackground = ({ backgroundClassName = null, children }) => {
	return (
		<div
			className={classnames(classes.holder, {
				[backgroundClassName]: backgroundClassName !== null,
			})}
		>
			{children}
		</div>
	)
}

InteractiveBackground.propTypes = {
	backgroundClassName: PropTypes.string,
	children: PropTypes.object.isRequired,
}
export default InteractiveBackground
