const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	$id: 'http://groupsolver.com/v1/MEDIA_OBJECT.json',
	properties: {
		render: {
			oneOf: [
				{
					additionalProperties: false,
					$id: '/properties/render',
					properties: {
						blurBefore: {
							default: false,
							$id: '/properties/render/properties/blurBefore',
							type: 'boolean',
						},
						forceFullscreen: {
							default: false,
							$id: '/properties/render/properties/forceFullscreen',
							type: 'boolean',
						},
						hideAfter: {
							default: false,
							$id: '/properties/render/properties/hideAfter',
							type: 'boolean',
						},
						imageFullscreenZoom: {
							oneOf: [
								{
									type: 'object',
									$$id: '/properties/imageFullscreenZoom',
									properties: {
										width: {
											$id: '/properties/render/properties/imageFullscreenZoom/properties/width',
											type: 'integer',
											minimum: 1,
										},
										height: {
											$id: '/properties/render/properties/imageFullscreenZoom/properties/height',
											type: 'integer',
											minimum: 1,
										},
									},
									additionalProperties: false,
									required: ['width', 'height'],
								},
								{type: 'null'},
							],
						},
						videoShowControls: {
							default: true,
							$id: '/properties/render/properties/videoShowControls',
							type: 'boolean',
						},
						videoAutoPlay: {
							default: false,
							$id: '/properties/render/properties/videoAutoPlay',
							type: 'boolean',
						},
					},
					required: [
						'forceFullscreen',
						'blurBefore',
						'hideAfter',
						'imageFullscreenZoom',
						'videoShowControls',
						'videoAutoPlay',
					],
					type: 'object',
				},
				{type: 'null'},
			],
		},
		url: {
			$id: '/properties/url',
			type: 'object',
			additionalProperties: false,
			patternProperties: {
				'^[a-z]{2}$': {
					pattern: '^$|^http://.+|^https://.+|{{.+}}|^/assets/.+.jpg$',
					type: 'string',
				},
			},
		},
		actionButton: {
			$id: '/properties/actionButton',
			oneOf: [{$ref: 'http://groupsolver.com/v1/ACTION_BUTTON.json'}, {type: 'null'}],
		},
		alt: {
			$id: '/properties/alt',
			type: 'object',
			additionalProperties: false,
			patternProperties: {
				'^[a-z]{2}$': {type: 'string'},
			},
		},
		thumbnailUrl: {
			$id: '/properties/thumbnailUrl',
			type: 'object',
			additionalProperties: false,
			patternProperties: {
				'^[a-z]{2}$': {
					pattern: '^$|^http://.+|^https://.+|{{.+}}|^/assets/.+.jpg$',
					type: 'string',
				},
			},
		},
		type: {
			$id: '/properties/type',
			type: 'string',
			enum: ['A_VIDEO', 'A_IMAGE'],
		},
		id: {
			$id: '/properties/id',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			type: 'string',
		},
	},
	required: ['render', 'url', 'actionButton', 'alt', 'thumbnailUrl', 'type', 'id'],
	type: 'object',
}

export default schema
