import PropTypes from 'prop-types'

export const optionShape = PropTypes.shape({
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.number]).isRequired,
})

export const optionsShape = PropTypes.arrayOf(optionShape)

export const SELECT_MENU_POSITION = {
	TOP: 'TOP',
	TOP_CENTERED: 'TOP_CENTERED',
	TOP_LEFT: 'TOP_LEFT',
	LEFT: 'LEFT',
	RIGHT: 'RIGHT',
	BOTTOM_LEFT: 'BOTTOM_LEFT',
	BOTTOM_CENTER: 'BOTTOM_CENTER',
	BOTTOM_RIGHT: 'BOTTOM_RIGHT',
	CENTER: 'CENTER',
	LEFT_CENTERED: 'LEFT_CENTERED',
}

export const SELECT_MENU_TYPE = {
	DEFAULT: 'DEFAULT',
	WIDE: 'WIDE',
}

export const customSelectOptionShape = PropTypes.shape({
	className: PropTypes.string,
	iconClassName: PropTypes.string,
	iconName: PropTypes.string,
	isNegative: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isGoBack: PropTypes.bool,
	isHighlighted: PropTypes.bool,
	isInfo: PropTypes.bool,
	isSecondary: PropTypes.bool,
	label: PropTypes.node.isRequired,
	labelClassName: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.func,
		PropTypes.number,
		PropTypes.object,
		PropTypes.array,
		PropTypes.string,
	]).isRequired,
	secondaryLabel: PropTypes.node,
	shouldCloseSelect: PropTypes.bool,
	tooltipProps: PropTypes.shape({
		className: PropTypes.string,
		html: PropTypes.bool,
	}),
	tooltipText: PropTypes.string,
	tooltipTextOnDisabled: PropTypes.string,
})

export const customSelectSectionShape = PropTypes.shape({
	className: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.arrayOf(customSelectOptionShape).isRequired,
})
