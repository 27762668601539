export const messages = {
	/**
	 * FLOW MODULE DEFAULTS
	 */
	proceed: 'Pokračovať',
	allocation_option: 'Možnosť prerozdelenia',
	choice_option: 'Možnosť výberu',
	submit: 'Odoslať',
	freetext_date_placeholder: 'Zadajte dátum',
	freetext_date_inputHint: 'napr. mm/dd/rok',
	freetext_phone_placeholder: 'Zadajte telefónne číslo',
	freetext_prhone_inputHint: 'napr. 000-123-4567',
	freetext_email_placeholder: 'Zadajte svoj e-mail',
	freetext_email_inputHint: 'napr. my@mail.com',
	freetext_zip_placeholder: 'Zadajte svoje PSČ',
	freetext_zip_inputHint: 'napr. 12345',
	freetext_answer_placeholder: 'Zadajte svoju odpoveď',
	freetext_integer_inputHint: 'napr. 123',
	freetext_number_inputHint: 'napr. 1.5',
	freetext_amount_placeholder: 'Zadajte sumu',
	freetext_amount_inputHint: 'napr. 1000,00',
	matrix_question: 'Matrica otázka',
	matrix_loop_question:
		'Uistite sa, že do textu otázky zahrniete [[item]], aby ste predstavili jedinečnú otázku pre každý položku zoznamu',
	maxdiff_option: 'Položka',
	maxdiff_best_question: 'Podľa vášho názoru, ktoré z týchto sú **najdôležitejšie** atribúty?',
	maxdiff_worst_question: 'Podľa vášho názoru, ktoré z týchto sú **najmenej** dôležité atribúty?',
	new_message: 'Nová správa',
	ranking_option: 'Možnosť hodnotenia',
	question_default: 'Zadajte svoju otázku',
	none_of_these: 'Žiadna z týchto možností',
	study_languages_language_choice_message: 'Aký jazyk preferujete?',
	ar: 'Arabčina',
	zh: 'Čínština',
	da: 'Dánčina',
	nl: 'Holandčina',
	en: 'Angličtina',
	fr: 'Francúzština',
	de: 'Nemčina',
	ha: 'Hausa',
	hi: 'Hindčina',
	ig: 'Igbo',
	id: 'Indonézština',
	it: 'Taliančina',
	ja: 'Japončina',
	ko: 'Kórejčina',
	pl: 'Poľština',
	pt: 'Portugalčina',
	ro: 'Rumunčina',
	ru: 'Ruština',
	sk: 'Slovenčina',
	es: 'Španielčina',
	sv: 'Švédčina',
	tl: 'Tagalog',
	th: 'Thajčina',
	tr: 'Turečtina',
	yo: 'Joruba',
}
