import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import { studyLanguagesShape } from 'constants/languages/studyLanguagesShape'

import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'
import Tooltip from 'components/_scaffolding/Tooltip'

import ListFileUploader from './_components/ListFileUploader'
import ListItemsManager from './_components/ListItemsManager'

const getUniqueColumns = columns => columns.filter(column => column.isUnique === true)

const ListFileSettings = props => {
	const intl = useIntl()
	const [isItemsManagerVisible, setIsItemsManagerVisible] = useState(false)

	const openItemsManager = () => setIsItemsManagerVisible(true)

	const closeItemsManager = () => setIsItemsManagerVisible(false)

	const handleFileUpload = (columns, identifier, items, filename) => {
		props.setValues({
			...props.values,
			columns,
			items,
			identifier,
			optionLabelIdentifiers: props.languages.reduce(
				(acc, { language }) => ({
					...acc,
					[language]: identifier,
				}),
				{},
			),
			filename,
		})
	}

	const identifierOptions = getUniqueColumns(props.values.columns).map(column => ({
		label: column.key,
		value: column.key,
	}))

	const canChangeIdentifier = props.isStudyEditable === true || props.isUnsaved === true

	return (
		<div>
			<ListFileUploader
				disabled={props.disabled}
				filename={props.values.filename}
				handleFileUpload={handleFileUpload}
				isEmpty={props.values.items.length === 0}
				isStudyEditable={props.isStudyEditable}
				isUnsaved={props.isUnsaved}
				openItemsManager={openItemsManager}
				values={props.values}
			/>
			{props.values.items.length === 0 && (
				<div className="title-error">{intl.formatMessage({ id: 'list.detail.empty_error' })}</div>
			)}
			{props.values.items.length > 0 && (
				<Fragment>
					<Label label={intl.formatMessage({ id: 'list.detail.identifier' })} />
					<div
						data-for="identifier-tooltip"
						data-tip={intl.formatMessage({ id: 'list.detail.identifier.disabled' })}
					>
						<Field
							component={Select}
							disabled={props.disabled === true || canChangeIdentifier === false}
							name={'identifier'}
							options={identifierOptions}
						/>
					</div>
					{canChangeIdentifier === false && <Tooltip id="identifier-tooltip" />}
					<div className="title-info">
						{intl.formatMessage({ id: 'list.detail.identifier.change.info' })}
					</div>
					<Label label={intl.formatMessage({ id: 'list.detail.option_label_identifier' })} />
					<div
						data-for="option-label-identifier-tooltip"
						data-tip={intl.formatMessage({ id: 'list.detail.option_label_identifier.disabled' })}
					>
						<Field
							component={Select}
							disabled={props.disabled === true || canChangeIdentifier === false}
							name={`optionLabelIdentifiers.${props.activeLanguage}`}
							options={identifierOptions}
						/>
					</div>
					{props.values.optionLabelIdentifiers[props.activeLanguage] === '' && (
						<div className="title-error">
							{intl.formatMessage({ id: 'list.detail.option_label_identifier.missing' })}
						</div>
					)}
					{canChangeIdentifier === false && <Tooltip id="option-label-identifier-tooltip" />}
					<div className="title-info">
						{intl.formatMessage({ id: 'list.detail.option_label_identifier.change.info' })}
					</div>
					{isItemsManagerVisible === true && (
						<ListItemsManager
							closeItemsManager={closeItemsManager}
							disabled={props.disabled}
							values={props.values}
							setValues={props.setValues}
							setFieldValue={props.setFieldValue}
						/>
					)}
				</Fragment>
			)}
		</div>
	)
}

ListFileSettings.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	isStudyEditable: PropTypes.bool.isRequired,
	isUnsaved: PropTypes.bool,
	languages: studyLanguagesShape.isRequired,
	setValues: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
}

export default ListFileSettings
