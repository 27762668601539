import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'

import Label from 'components/_formik/_base/Label'

import classes from './LanguageChoiceOptions.module.scss'

const LanguageChoiceOptions = ({ activeLanguage, options }) => {
	const intl = useIntl()

	return (
		<div className={classes.wrapper}>
			<Label label={intl.formatMessage({ id: 'options' })} />
			<div className={classes.row}>
				<Label label={intl.formatMessage({ id: 'choice.option.label' })} />
				<Label
					className={classes.row__code}
					label={intl.formatMessage({ id: 'choice.option.code' })}
				/>
				{options.map(option => (
					<Fragment key={option.id}>
						<div className={classes.row__option_label}>{option.label[activeLanguage]}</div>
						<div className={classes.row__code}>{option.code}</div>
					</Fragment>
				))}
			</div>
			<div className="title-info">
				{intl.formatMessage({ id: 'study_languages_language_choice_option_info' })}
			</div>
		</div>
	)
}

LanguageChoiceOptions.propTypes = {
	options: PropTypes.array.isRequired,
}

export default LanguageChoiceOptions
