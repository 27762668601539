import { VISUAL_FLOW_INVALID_MODULE_TYPES, ALLOCATION_LIMIT_TYPES } from 'constants/studyDesign'
import { filterModulesForAllocation } from 'helpers/visualFlowModules/filterModulesForAllocation'
import { getVisibleOptions } from 'helpers/visualFlowModules/getVisibleOptions'
import getUpperModuleIds from 'routes/_study/StudyDesign/_store/helpers/getUpperModuleIds'

import validateDatasetMessages from './_validateDatasetMessages'
import validateRelatedMessage from './_validateRelatedMessage'
import {
	validateChoiceOptions,
	hasInvalidListSettings,
	hasInvalidListSelected,
	hasInvalidListColumnSelected,
} from './validateChoice'
import isOptionsFilterValid from './_isOptionsFilterValid'

export const isAllocationLimitValueValid = module => {
	const { limit } = module.definition

	if (limit.value.type !== ALLOCATION_LIMIT_TYPES.VALUE) {
		return true
	}

	return limit.value.value !== 0
}

export const isAllocationLimitInputValid = module => {
	const { limit } = module.definition

	return limit.value.value !== ''
}

export const isAllocationLimitStudyObjectValid = (module, modules, orderModule, flatOrder) => {
	const { limit } = module.definition

	if (limit.value.type !== ALLOCATION_LIMIT_TYPES.FREE_TEXT_ANSWER) {
		return true
	}

	const idsAvailableModules = getUpperModuleIds(orderModule.path, flatOrder, modules)

	if (idsAvailableModules.includes(limit.value.value) === false) {
		return false
	}

	const modulesArray = idsAvailableModules.map(id => modules[id])

	return filterModulesForAllocation(modulesArray).some(
		module => module.definition.id === limit.value.value,
	)
}

export const isAllocationLimitValid = (module, modules, orderModule, flatOrder) => {
	const { limit } = module.definition

	if (limit.isEnabled === false) {
		return true
	}

	if (isAllocationLimitValueValid(module) === false) {
		return false
	}

	if (isAllocationLimitInputValid(module) === false) {
		return false
	}

	return isAllocationLimitStudyObjectValid(module, modules, orderModule, flatOrder)
}

export const isAllocationRangeMinValid = module => {
	const { range } = module.definition

	return range.min !== '' && range.min >= 0
}

export const isAllocationRangeValid = module => {
	const { range } = module.definition

	if (range.max === '') {
		return true
	}

	return range.max > range.min
}

export const areAllocationLimitAndMinRangeValid = module => {
	const { limit, range } = module.definition

	const options = getVisibleOptions(module.definition.options)

	if (limit.isEnabled === false) {
		return true
	}

	if (limit.value.type !== ALLOCATION_LIMIT_TYPES.VALUE) {
		return true
	}

	return range.min < limit.value.value && options.length * range.min < limit.value.value
}

export const areAllocationLimitAndMaxRangeValid = module => {
	const { limit, range } = module.definition

	if (limit.isEnabled === false) {
		return true
	}

	if (limit.value.type !== ALLOCATION_LIMIT_TYPES.VALUE) {
		return true
	}

	if (limit.useLimitAsMin === false) {
		return true
	}

	if (range.max === '') {
		return true
	}

	const options = getVisibleOptions(module.definition.options)

	const numberOfRegularOptions = options.filter(option => option.isNoneOfThese === false).length
	const rangeMaxSum = numberOfRegularOptions * range.max

	return rangeMaxSum >= limit.value.value
}

export const isAllocationOptionDefaultValid = option => option.defaultValue !== ''

export const isAllocationOptionDefaultInRange = (option, range) => {
	if (option.isNoneOfThese === true) {
		return true
	}

	const minRangeResult = option.defaultValue >= range.min

	if (range.max === '') {
		return minRangeResult
	}

	return minRangeResult === true && option.defaultValue <= range.max
}

export const getHasInvalidInstruction = (module, languages) => {
	const { instructionSettings } = module.definition

	if (instructionSettings.isVisible === false) {
		return false
	}

	if (instructionSettings.isCustom === false) {
		return false
	}

	return languages.some(language => instructionSettings.text[language.language].trim().length === 0)
}

const validateAllocation = (module, modules, orderModule, flatOrder, studyTags, languages) => {
	const validationResult = []

	validationResult.push(...validateDatasetMessages(module, studyTags, languages))
	validationResult.push(...validateRelatedMessage(module, modules, studyTags, languages))

	if (isAllocationLimitValid(module, modules, orderModule, flatOrder) === false) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.allocation_invalid_limit,
		})
	}

	if (isAllocationRangeMinValid(module) === false) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.allocation_invalid_range_min,
		})
	}

	if (isAllocationRangeValid(module) === false) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.allocation_invalid_range,
		})
	}

	if (areAllocationLimitAndMinRangeValid(module) === false) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.allocation_invalid_range_limit,
		})
	}

	if (areAllocationLimitAndMaxRangeValid(module) === false) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.allocation_invalid_range_limit,
		})
	}

	if (getHasInvalidInstruction(module, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.allocation_invalid_instruction,
		})
	}

	const { range } = module.definition

	const options = getVisibleOptions(module.definition.options)

	if (options.every(option => isAllocationOptionDefaultValid(option) === true) === false) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.allocation_option_default_invalid,
		})
	}

	if (options.every(option => isAllocationOptionDefaultInRange(option, range) === true) === false) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.allocation_option_default_out_of_range,
		})
	}

	if (isOptionsFilterValid(module) === false) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.allocation_invalid_filter,
		})
	}

	if (hasInvalidListSettings(module, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_incomplete_dynamic_options_settings,
		})

		return validationResult
	}

	if (hasInvalidListSelected(module, modules, orderModule, flatOrder) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_invalid_list_selected,
		})

		return validationResult
	}

	if (hasInvalidListColumnSelected(module, modules, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_invalid_list_column_selected,
		})

		return validationResult
	}

	validationResult.push(...validateChoiceOptions(module, languages))

	return validationResult
}

export default validateAllocation
