import { enTranslator as intl } from 'intl.js'

import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import Heatmap from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Heatmap'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { GET_QUESTION_MESSAGE } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/MESSAGE'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'

export default {
	type: VISUAL_FLOW_MODULE_TYPES.HEATMAP,
	style: 'heatmap',
	title: () => 'heatmap.title',
	description: () => 'heatmap.description',
	component: Heatmap,
	generator: (modules, additionalProperties) => ({
		idRelatedMessage: null,
		mandatory: true,
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'heatmap.title' }),
			VISUAL_FLOW_MODULE_TYPES.HEATMAP,
		),
		messages: [
			GET_QUESTION_MESSAGE({ ...additionalProperties, idTextTranslation: 'question_default' }),
		],
		blurBefore: false,
		blurAfter: false,
		imageUrl: getLanguagesLabelsObject(additionalProperties.languages, ''),
		thumbnailUrl: getLanguagesLabelsObject(additionalProperties.languages, ''),
		isRanking: false,
		maxAnswers: 1,
		maxTimer: null,
		minAnswers: 1,
	}),
}
