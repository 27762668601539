import _ from 'lodash'
import { enTranslator as intl } from 'intl.js'

import {
	RESPONDENT_DATES,
	CONDITION_RESPONDENT_SOURCE,
	CONDITION_LANGUAGE,
} from 'constants/conditionBuilder'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import { getFreetextSettings } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getFreetextSettings'

import { filterModulesForCondition } from 'helpers/visualFlowModules/filterModulesForCondition'
import { getEnabledLanguages } from 'helpers/languages/getEnabledLanguages'

const getModulesFromFlow = (visualFlow, flatOrder) =>
	filterModulesForCondition(flatOrder.map(({ id }) => visualFlow.modules[id]))

export const getImportedModules = importedModules =>
	importedModules.map(module => ({
		isCommunityModule: module.isCommunity,
		isImportedModule: module.isCommunity === false,
		type: module.type,
		definition: {
			...(module.type === VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT
				? getFreetextSettings(module.definition.freetextType)
				: {}),
			id: module.id,
			shortName: module.simpleName,
			options: module.definition.options,
		},
	}))

const getDateModules = intl => [
	{
		isImportedModule: false,
		type: RESPONDENT_DATES.START_DATE,
		definition: {
			id: RESPONDENT_DATES.START_DATE,
			shortName: intl.formatMessage({
				id: 'start_date',
			}),
		},
	},
]

const getModulesFromProFlow = proFlow => {
	const loopedModulesIterations = []
	const idsLoopedModulesObject = {}

	filterModulesForCondition(
		proFlow.filter(proFlowModule => proFlowModule.listSettings.length > 0),
	).forEach(proFlowModule => {
		idsLoopedModulesObject[proFlowModule.listSettings[0].idOriginalModule] = null

		loopedModulesIterations.push({
			isLoopedModuleIteration: true,
			isCommunityModule: false,
			isImportedModule: false,
			type: proFlowModule.type,
			definition: {
				id: proFlowModule.id,
				shortName: proFlowModule.simpleName,
				...proFlowModule.definition,
			},
		})
	})

	return {
		loopedModulesIterations,
		idsLoopedModulesObject,
	}
}

const processSemgnetationTypingTools = segmentationTypingTools =>
	segmentationTypingTools.map(typingTool => ({
		isSegmentationTypingTool: true,
		type: VISUAL_FLOW_MODULE_TYPES.A_CHOICE,
		definition: {
			id: typingTool.idSegmentationTypingTool,
			shortName: typingTool.name,
			minSelection: 1,
			maxSelection: 1,
			options: typingTool.segments.map(segment => ({
				id: segment.idSegmentationTypingToolSegment,
				label: segment.name,
			})),
		},
	}))

const processRespondentSources = respondentSources =>
	respondentSources.map(respondentSource => ({
		isRespondentSource: true,
		type: CONDITION_RESPONDENT_SOURCE,
		definition: {
			id: respondentSource.idRespondentSource,
			shortName: respondentSource.label,
		},
	}))

export const getLanguageModule = languages => {
	const enabledLanguages = getEnabledLanguages(languages)

	return {
		isLanguage: true,
		type: CONDITION_LANGUAGE,
		definition: {
			id: CONDITION_LANGUAGE,
			shortName: intl.formatMessage({ id: 'language' }),
			options: enabledLanguages.map(language => ({
				id: language.language,
				label: languages.reduce(
					(acc, languageForLabel) => ({
						...acc,
						[languageForLabel.language]: intl.formatMessage({ id: language.language }),
					}),
					{},
				),
			})),
		},
	}
}

export const getConditionBuilderModules = ({
	intl,
	importedModules,
	visualFlow,
	flatOrder,
	proFlow,
	segmentationTypingTools,
	respondentSources,
	includeDateModules,
	studyLanguages,
}) => {
	const dateModules = includeDateModules === false ? [] : getDateModules(intl)

	const languageModule = getLanguageModule(studyLanguages)

	if (_.get(visualFlow, 'order', []).length === 0) {
		return [
			...getImportedModules(importedModules),
			...dateModules,
			...getModulesFromProFlow(proFlow).loopedModulesIterations,
			...processSemgnetationTypingTools(segmentationTypingTools),
			...processRespondentSources(respondentSources),
			languageModule,
		]
	}

	const { loopedModulesIterations, idsLoopedModulesObject } = getModulesFromProFlow(proFlow)

	return [
		...getModulesFromFlow(visualFlow, flatOrder).filter(
			module => idsLoopedModulesObject[module.definition.id] === undefined,
		),
		...getImportedModules(importedModules),
		...dateModules,
		...loopedModulesIterations,
		...processSemgnetationTypingTools(segmentationTypingTools),
		...processRespondentSources(respondentSources),
		languageModule,
	]
}
