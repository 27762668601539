import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'

import { matchUrl } from 'components/_formik/validation'

export const hasInvalidRedirect = module =>
	module.definition.overrideRedirect === true && matchUrl(module.definition.redirectUrl) === true

const hasInvalidRedirectLabel = (module, languages) =>
	module.definition.enableRedirectButton === true &&
	languages.some(
		language =>
			module.definition.redirectLabel[language.language].length === 0 ||
			module.definition.redirectLabel[language.language].length > 50,
	)

const validateEndstudy = (module, languages) => {
	const validationResult = []

	if (hasInvalidRedirect(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.endStudy_emptyUrl,
		})
	}

	if (hasInvalidRedirectLabel(module, languages) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.endStudy_invalid_redirect_label,
		})
	}

	return validationResult
}

export default validateEndstudy
