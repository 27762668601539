import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Textarea from 'components/_formik/_base/Textarea'
import MediaOpener from 'components/_scaffolding/_input/MediaOpener'
import SmallButton from 'components/_scaffolding/Buttons/SmallButton'

import classes from './ImageTextArea.module.scss'

const ImageTextArea = props => {
	const intl = useIntl()
	const [imageUploaderKey, setImageUploaderKey] = useState(0)

	if (_.isNil(props.textareaName) === true || props.textareaName.length === 0) {
		throw new Error('textareaName has to be defined')
	}

	if (_.isNil(props.imageUploaderName) === true || props.imageUploaderName.length === 0) {
		throw new Error('imageUploaderName has to be defined')
	}

	const forceUpdate = () => {
		setImageUploaderKey(imageUploaderKey + 1)
	}

	const getResetImage = setFieldValue => () => {
		setFieldValue(`${props.imageUploaderName}.url.${props.activeLanguage}`, '')
		setFieldValue(`${props.imageUploaderName}.thumbnailUrl.${props.activeLanguage}`, '')
		forceUpdate()
	}

	const { values, setFieldValue } = props.formProps
	const imageUrl = _.get(values, `${props.imageUploaderName}.url.${props.activeLanguage}`)
	const isImageUploaded = imageUrl !== ''

	return (
		<Fragment>
			<div
				className={classnames(classes.wrapper, {
					[classes['wrapper--with-image']]: isImageUploaded === true,
					[classes['wrapper--without-image']]: isImageUploaded === false,
				})}
			>
				<div
					className={classnames('', {
						[classes['text-area-wrapper']]: isImageUploaded === true,
					})}
				>
					<Field
						component={Textarea}
						className={classnames(classes['text-area'], {
							[classes['text-area--without-image']]: isImageUploaded === false,
						})}
						disabled={props.disabled}
						name={props.textareaName}
						placeholder={props.placeholder}
						isAutoResize
						withBorder={false}
						withPadding={false}
						isTextareaBig={props.isTextareaBig}
						type="text"
						onFocus={() => {
							if (_.get(values, props.textareaName) === props.defaultText) {
								setFieldValue(props.textareaName, '')
							}
						}}
						onChange={props.textareaOnChangeOverride}
					/>
				</div>

				{isImageUploaded === true && <div className={classes.line} />}

				<div
					className={classnames('', {
						[classes['image-controls']]: isImageUploaded === true,
					})}
				>
					<Field
						activeLanguage={props.activeLanguage}
						bucketName={props.bucketName}
						className={classnames('', {
							[classes['image-controls__uploader']]: isImageUploaded === true,
						})}
						component={MediaOpener}
						disabled={props.disabled}
						name={props.imageUploaderName}
						placeholder={intl.formatMessage({ id: 'media' })}
						openMediaManager={props.openMediaManager}
						canSubmitVideo
						placeholderSize={18}
						placeholderClassName={classes['image-placeholder']}
					/>
					{isImageUploaded === true && props.disabled !== true && (
						<SmallButton
							title={intl.formatMessage({ id: 'remove' })}
							preset={SmallButton.PRESETS.alert}
							onClick={getResetImage(setFieldValue)}
						/>
					)}
				</div>
			</div>
			{_.get(values, props.textareaName, '').length > 1000 && (
				<span className="title-error">{intl.formatMessage({ id: 'message.text_too_long' })}</span>
			)}
		</Fragment>
	)
}

ImageTextArea.defaultProps = {
	isTextareaBig: true,
}

ImageTextArea.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	bucketName: PropTypes.string.isRequired,
	defaultText: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	formProps: PropTypes.shape({
		setFieldValue: PropTypes.func.isRequired,
		values: PropTypes.object.isRequired,
	}).isRequired,
	imageUploaderName: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	textareaName: PropTypes.string.isRequired,
	openMediaManager: PropTypes.func.isRequired,
	isTextareaBig: PropTypes.bool.isRequired,
	textareaOnChangeOverride: PropTypes.func,
}

export default ImageTextArea
