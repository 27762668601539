import React, { lazy } from 'react'

// app scaffolding
import AuthRoute from './_scaffolding/Auth'
import NotFound from './_scaffolding/NotFound'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

// modules
import AccountIndex from './_account'
import Admin from './_admin'
import Dashboard from './Dashboard'
import Home from './_home'
import Inbox from './Inbox'
import Libraries from './Libraries'
import Organization from './Organization'
import PlanPaymentStatusIndex from './_planPaymentStatus'
import Presentation from './_presentation'
import PresentationView from './_presentationView'
import Presentations from './Presentations'
import PrivacyPolicy from './_privacyPolicy'
import ReportExport from './_reportExport'
import SharePresentation from './_sharePresentation'
import StudyIndex from './_study'
import TermsAndConditions from './_termsAndConditions'
import UpgradeIndex from './_upgrade'

/**
 * NOTE: We lazily import layouts on demand as needed.
 * This way, we keep the initial bundle small.
 */
const CoreLayout = lazy(() => import('layouts/CoreLayout'))
const AuthBaseLayout = lazy(() => import('layouts/AuthBaseLayout'))
const PresentationLayout = lazy(() => import('layouts/PresentationLayout'))
const ReportExportLayout = lazy(() => import('layouts/ReportExportLayout'))
const SplitLayout = lazy(() => import('layouts/SplitLayout'))

const WrappedCoreLayout = errorWrapper(CoreLayout)

export const createRoutes = store => ({
	path: '/',
	// NOTE: We wrap `CoreLayout` in react error boundary, so we never get
	// straight up empty white screen when error is thrown. Without this,
	// there'd be slim chance for some errors to be entirely uncaught and
	// crash the entire page.
	element: <WrappedCoreLayout />,
	children: [
		{
			element: <SplitLayout />,
			children: [
				Admin(),
				Dashboard(),
				Inbox(),
				Libraries(),
				Presentations(),
				StudyIndex(store),
				Home(),
				Organization(),
				Presentation(),
				AccountIndex(),
				UpgradeIndex(),
				PlanPaymentStatusIndex(),
			],
		},
		{
			element: <PresentationLayout />,
			children: [PresentationView()],
		},
		{
			element: <ReportExportLayout />,
			children: [ReportExport()],
		},
		SharePresentation(),
		{
			element: <AuthBaseLayout />,
			children: [AuthRoute()],
		},
		PrivacyPolicy(),
		TermsAndConditions(),
		NotFound(),
	],
})

export default createRoutes
